import { useMemo } from "react";

import { AttributeResponse, QueryDataResponse } from "src/shared/types/services";
import { getData } from "src/shared/utils/service";

import { useQuery } from "@tanstack/react-query";

const useAttributes = (): AttributeResponse[] => {
  const { data: dataAttributes } = useQuery<QueryDataResponse<AttributeResponse, false>>({
    staleTime: Infinity,
    cacheTime: 0,
    queryKey: ["attributes"],
    queryFn: () =>
      getData({
        path: "/attributes",
        config: {
          params: {
            limit: -1,
            offset: 0,
          },
        },
      }),
  });

  return useMemo(() => dataAttributes?.items ?? [], [dataAttributes?.items]);
};

export default useAttributes;
