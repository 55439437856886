import { Key } from "react";

import { DataNode } from "antd/es/tree";
import { DUMB_LEFT_MENU, KEY_MENU_TREE_NODE_SEPARATOR } from "src/shared/constants/menu";
import { MenuMutate, SubMenuItem } from "src/shared/types/views";

export const transformMenuItemsToTreeData = (menu: SubMenuItem[]): DataNode[] => {
  return menu.map((subMenu) => ({
    title: subMenu.name,
    key: subMenu.path.concat(subMenu.collection_id).join(KEY_MENU_TREE_NODE_SEPARATOR),
    disabled: subMenu.collection_id === DUMB_LEFT_MENU.collection_id,
    ...(subMenu.sub_menus ? { children: transformMenuItemsToTreeData(subMenu.sub_menus) } : {}),
  }));
};

export const removeNodeFromTree = (data: DataNode[], removedKey: Key) => {
  return data.filter((node) => {
    if (node.key === removedKey) {
      return false;
    } else if (node.children) {
      node.children = removeNodeFromTree(node.children, removedKey);
      return true;
    } else {
      return true;
    }
  });
};

export const transformTreeDataToMutateData = (data: DataNode[], parentIds: string[] = []) => {
  const flattenedMenu: MenuMutate[] = [];

  data.forEach((item, indexItem) => {
    const keySplit = item.key.toString().split(KEY_MENU_TREE_NODE_SEPARATOR);
    const currentCollectionId = keySplit[keySplit.length - 1];

    const currentIds = [...parentIds, currentCollectionId];
    flattenedMenu.push({
      name: item.title?.toString() || "Something went wrong with this menu item's name",
      collection_id: currentCollectionId,
      path: currentIds,
      order_number: indexItem,
    });

    if (item.children) {
      flattenedMenu.push(
        ...transformTreeDataToMutateData(
          item.children,
          currentIds.map((parentId) =>
            parentId.split(KEY_MENU_TREE_NODE_SEPARATOR).length > 1
              ? parentId.split(KEY_MENU_TREE_NODE_SEPARATOR)[1]
              : parentId.split(KEY_MENU_TREE_NODE_SEPARATOR)[0]
          )
        )
      );
    }
  });

  return flattenedMenu;
};

export const transformFlatToNestedData = (menuItems: SubMenuItem[]) => {
  const itemMap = new Map();

  for (const item of menuItems) {
    const newItem = { ...item, sub_menus: [] };
    itemMap.set(item.collection_id, newItem);

    if (item.path.length > 1) {
      const parentId = item.path[item.path.length - 2];
      const parent = itemMap.get(parentId);
      if (parent) {
        parent.sub_menus.push(newItem);
      }
    }
  }

  const rootItem = itemMap.get("left-menu");

  return rootItem;
};
