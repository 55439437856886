import { useMemo } from "react";

import { Pagination } from "src/shared/types/paging";
import { CollectionResponse } from "src/shared/types/services";
import { reduceUrlImage } from "src/shared/utils/common";

import { Box, Button } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import ChipBase from "src/components/common/chips/ChipBase";
import CustomDataGrid from "src/components/common/CustomDataGrid";

const columns: GridColDef[] = [
  {
    field: "imageUrl",
    headerName: "Ảnh",
    renderCell: ({ value }) => (
      <Box className="table-cell--image">
        <img
          className="box-image"
          src={reduceUrlImage({ originUrl: value, quality: 10, width: 100 })}
          alt={value}
        />
      </Box>
    ),
    flex: 1,
  },
  {
    field: "title",
    headerName: "Nhóm sản phẩm",
    renderCell: (params) => (
      <Button href={params.row.link} sx={{ padding: 0 }}>
        {params.row.title}
      </Button>
    ),
    flex: 1,
  },

  {
    field: "tag",
    headerName: "Thể loại",
    renderCell: (params) => <ChipBase label={params.row.tag} size="small" />,
    flex: 1,
  },
  {
    field: "isVisible",
    headerName: "Trạng thái",
    flex: 1,
  },
];

interface CollectionTableProps {
  collections?: CollectionResponse[];
  isLoading?: boolean;
  pagination: Pagination;
}

const CollectionsTable = ({ collections = [], isLoading, pagination }: CollectionTableProps) => {
  const collectionRows = useMemo(
    () =>
      collections?.map(({ id, image, title, tag, is_visible }) => ({
        id,
        tag,
        title,
        link: `/collections/${id}/edit`,
        imageUrl: image?.url,
        isVisible: is_visible ? "Hiện" : "Ẩn",
      })),
    [collections]
  );

  return (
    <CustomDataGrid
      rows={collectionRows}
      columns={columns}
      loading={isLoading}
      customPaginationProps={pagination}
    />
  );
};

export default CollectionsTable;
