import { getTotalProductsPrice, getVoucherPrice, isFreeShipping } from "src/shared/helpers/order";
import { OrderForm } from "src/shared/types/forms";
import { CreateOrderRequest } from "src/shared/types/services";

export const generateOrderItems = (orderItems: OrderForm["orderItems"]) => {
  const order_items: CreateOrderRequest["order_items"] = orderItems.map(
    ({ variant_id, quantity }) => {
      return {
        variant_id,
        quantity,
        discount_id: "",
      };
    }
  );

  return order_items;
};

export const generateBillItems = ({
  orderItems,
  voucherField,
  shipment,
  extraFeeField,
}: Pick<OrderForm, "orderItems" | "voucherField" | "shipment" | "extraFeeField">) => {
  const billItems: CreateOrderRequest["bill_items"] = [];

  if (orderItems) {
    const transformBillItems: CreateOrderRequest["bill_items"] = orderItems.map((orderItem) => {
      return {
        variant_id: orderItem.variant_id,
        discount_id: "",
        type: "PRODUCT_BILL",
        discount_price: 0,
        final_price: orderItem.product_price * orderItem.quantity,
      };
    });
    billItems.push(...transformBillItems);
  }

  const totalProductsPrice = getTotalProductsPrice(orderItems);
  const totalVoucherPrice = getVoucherPrice({ totalProductsPrice, voucher: voucherField });
  if (voucherField) {
    billItems.push({
      variant_id: "",
      discount_id: "",
      voucher_id: voucherField.id,
      type: "VOUCHER_BILL",
      discount_price: 0,
      final_price: totalVoucherPrice,
    });
  }

  const extraFeePrice = extraFeeField;
  if (extraFeeField) {
    billItems.push({
      variant_id: "",
      discount_id: "",
      type: "EXTRA_FEE_BILL",
      discount_price: 0,
      final_price: extraFeePrice,
    });
  }

  const tempFinalPrices = totalProductsPrice + extraFeePrice - totalVoucherPrice;
  const shouldApplyShipmentPrice = !isFreeShipping(tempFinalPrices);
  if (shipment) {
    billItems.push({
      variant_id: "",
      discount_id: "",
      type: "SHIPPING_BILL",
      discount_price: 0,
      final_price: shouldApplyShipmentPrice ? shipment.shipmentPrices : 0,
    });
  }

  return billItems;
};

const generateShipment = (shipment: OrderForm["shipment"]): CreateOrderRequest["shipment"] => {
  if (!shipment) return;

  const { province_id, district_id, ward_id, address } = shipment;
  if (!province_id || !district_id || !ward_id || !address) return;

  return {
    province_id,
    district_id,
    ward_id,
    address,
  };
};

export const transformOrder = (formValues: OrderForm): CreateOrderRequest => {
  const { customer, orderItems, voucherField, extraFeeField, payment_method, shipment, note } =
    formValues;

  const order_items = generateOrderItems(orderItems);
  const bill_items = generateBillItems({ orderItems, voucherField, shipment, extraFeeField });
  const shipmentReq = generateShipment(shipment);
  const orderReq: CreateOrderRequest = {
    customer,
    order_items,
    bill_items,
    payment_method,
    shipment: shipmentReq,
    note,
  };
  return orderReq;
};
