import { BrowserRouter, Route, Routes } from "react-router-dom";

import OverlayLoading from "./components/OverlayLoading";
import ActionSnackbar from "src/components/ActionSnackbar";

import useDocumentTitle from "./hooks/useDocumentTittle";
import SignUpPage from "./pages/Account/SignUpPage";
import CollectionsPage from "./pages/Collections";
import CreateCollectionPage from "./pages/Collections/CreateCollectionPage";
import EditCollectionPage from "./pages/Collections/EditCollectionPage";
import MenuPage from "./pages/Menus";
import OrdersPage from "./pages/Orders";
import CreateOrderPage from "./pages/Orders/CreateOrderPage";
import OrderDetailPage from "./pages/Orders/OrderDetailPage";
import PostsPage from "./pages/Posts";
import ProductsPage from "./pages/Products";
import EditProductPage from "./pages/Products/EditProductPage";
import CreateProductVariantPage from "./pages/ProductVariant/CreateProductVariantPage";
import EditProductVariantPage from "./pages/ProductVariant/EditProductVariantPage";
import { RoutePage } from "./shared/constants/route";

import "./App.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import MLayout from "src/layouts/MLayout";
import LoginPage from "src/pages/Account/LoginPage";
import HomePage from "src/pages/HomePage";
import UpsertHomePage from "src/pages/HomePage/UpsertHomePage";
import NotFoundPage from "src/pages/NotFoundPage";
import CreatePostPage from "src/pages/Posts/CreatePostPage";
import EditPostPage from "src/pages/Posts/EditPostPage";
import CreateProductPage from "src/pages/Products/CreateProductPage";
import CreateVoucherPage from "src/pages/Vouchers/CreateVoucherPage";
import EditVoucherPage from "src/pages/Vouchers/EditVoucherPage";
import VoucherDetailPage from "src/pages/Vouchers/VoucherDetailPage";
import VouchersPage from "src/pages/Vouchers/VouchersPage";
import GlobalProvider from "src/shared/Providers/GlobalProvider";

const queryClient = new QueryClient();

const RoutesChild = () => {
  useDocumentTitle();

  return (
    <GlobalProvider>
      <Routes>
        <Route path="/" element={<MLayout />}>
          <Route index element={<HomePage />} />
          <Route path={`${RoutePage.HomePage}/upsert`} element={<UpsertHomePage />} />

          <Route path={`/${RoutePage.Order}`} element={<OrdersPage />} />
          <Route path={`/${RoutePage.Order}/create`} element={<CreateOrderPage />} />
          <Route path={`/${RoutePage.Order}/:id`} element={<OrderDetailPage />} />

          <Route path={`/${RoutePage.Product}`} element={<ProductsPage />} />
          <Route path={`/${RoutePage.Product}/create`} element={<CreateProductPage />} />
          <Route path={`/${RoutePage.Product}/:id/edit`} element={<EditProductPage />} />

          <Route
            path={`/${RoutePage.Product}/:id/variants/create`}
            element={<CreateProductVariantPage />}
          />
          <Route
            path={`/${RoutePage.Product}/:id/variants/:variantId/edit`}
            element={<EditProductVariantPage />}
          />

          <Route path={`/${RoutePage.Voucher}`} element={<VouchersPage />} />
          <Route path={`/${RoutePage.Voucher}/create`} element={<CreateVoucherPage />} />
          <Route path={`/${RoutePage.Voucher}/:id`} element={<VoucherDetailPage />} />
          <Route path={`/${RoutePage.Voucher}/:id/edit`} element={<EditVoucherPage />} />

          <Route path={`/${RoutePage.Collection}`} element={<CollectionsPage />} />
          <Route path={`/${RoutePage.Collection}/create`} element={<CreateCollectionPage />} />
          <Route path={`/${RoutePage.Collection}/:id/edit`} element={<EditCollectionPage />} />

          <Route path={`/${RoutePage.Menu}/edit`} element={<MenuPage />} />

          <Route path={`/${RoutePage.Post}`} element={<PostsPage />} />
          <Route path={`/${RoutePage.Post}/create`} element={<CreatePostPage />} />
          <Route path={`/${RoutePage.Post}/:id/edit`} element={<EditPostPage />} />

          <Route path="*" element={<NotFoundPage />} />
        </Route>

        <Route path={`/${RoutePage.Login}`} element={<LoginPage />} />
        <Route path={`/${RoutePage.SignUp}`} element={<SignUpPage />} />
      </Routes>
      <ActionSnackbar />
      <OverlayLoading />
    </GlobalProvider>
  );
};

const App = () => (
  <QueryClientProvider client={queryClient}>
    <BrowserRouter>
      <RoutesChild />
    </BrowserRouter>
  </QueryClientProvider>
);

export default App;
