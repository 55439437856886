import { useNavigate } from "react-router-dom";
import { HomePageForm } from "src/shared/types/forms";
import { postData } from "src/shared/utils/service";

import { useMutation } from "@tanstack/react-query";
import useShowOverlayLoading from "src/hooks/useShowOverlayLoading";
import useShowSnackbar from "src/hooks/useShowSnackbar";

const useUpsertHomePage = () => {
  const navigate = useNavigate();
  const showLoading = useShowOverlayLoading();
  const showSnackbar = useShowSnackbar();

  return useMutation<unknown, unknown, HomePageForm>({
    mutationKey: ["upsert-homepage"],
    mutationFn: (data) => {
      return postData("/home-page/with-admin", data);
    },
    onSuccess: () => {
      showSnackbar("Cập nhật trang chủ thành công!", "success");
      showLoading(false);
      navigate("/");
    },
    onError: () => {
      showLoading(false);
      showSnackbar("Cập nhật trang chủ thất bại!", "error");
    },
  });
};

export default useUpsertHomePage;
