import { Pagination } from "src/shared/types/paging";
import { CollectionResponse } from "src/shared/types/services";

import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import CloseIcon from "@mui/icons-material/Close";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";

interface CollectionSelectionDialogProps {
  open: boolean;
  onClose: () => void;
  onSelect: (selectedCollection: CollectionResponse) => void;
  collections?: CollectionResponse[];
  onSearch: (keyword: string) => void;
  pagination: Pagination;
  selectedCollection: string[];
}

const CollectionSelectionDialog = ({
  open,
  collections,
  pagination: { onPageChange },
  onSearch,
  onClose,
  onSelect,
  selectedCollection,
}: CollectionSelectionDialogProps) => {
  const handleSearch = (value: string) => {
    onSearch(value);
  };

  const handleCloseDialog = () => {
    onSearch("");
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={() => handleCloseDialog()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle>
        <Box display={"flex"} justifyContent={"space-between"}>
          Chọn nhóm sản phẩm
          <IconButton edge="start" color="inherit" onClick={handleCloseDialog} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <TextField
          onChange={(event) => handleSearch(event.target.value)}
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchOutlinedIcon fontSize="small" />
              </InputAdornment>
            ),
          }}
          sx={{ marginBottom: 1 }}
          variant="outlined"
        />
        <Box
          sx={{
            height: "460px",
            width: "550px",
            overflowY: "auto",
            flexWrap: "nowrap",
            paddingX: 2,
          }}>
          {collections?.length ? (
            collections.map((collection) => (
              <Box
                key={collection.id}
                py={0.5}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
                height={"45px"}
                sx={{
                  "& .MuiFormControlLabel-label": {
                    fontSize: "14px",
                  },
                  borderBottom: "1px solid #e0e0e0",
                }}>
                <Typography variant="body2">{collection.title}</Typography>
                {selectedCollection.includes(collection.id) ? (
                  <Typography variant="body2" fontStyle={"italic"}>
                    Đã được chọn
                  </Typography>
                ) : (
                  <Button
                    variant="contained"
                    onClick={() => {
                      onSelect(collection);
                    }}>
                    Chọn
                  </Button>
                )}
              </Box>
            ))
          ) : (
            <Box display={"flex"} textAlign={"center"} justifyContent={"center"}>
              <Typography variant="caption" fontStyle={"italic"} py={2} alignSelf={"center"}>
                Không có dữ liệu phù hợp
              </Typography>
            </Box>
          )}
        </Box>
        <Box textAlign={"right"} px={2} pb={0}>
          <Button
            aria-label="previous"
            color="primary"
            variant="outlined"
            onClick={(e) => onPageChange(e, -2)}>
            <ArrowBackIosNewOutlinedIcon fontSize="inherit" />
          </Button>
          <Button
            aria-label="next"
            color="primary"
            variant="outlined"
            sx={{ marginLeft: 1 }}
            onClick={(e) => onPageChange(e, -1)}>
            <ArrowForwardIosOutlinedIcon fontSize="inherit" />
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default CollectionSelectionDialog;
