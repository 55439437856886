import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import { RoutePage } from "src/shared/constants/route";
import { ReturnPaging } from "src/shared/types/paging";

import usePaging from "src/hooks/usePaging";

export enum SearchKeyOrder {
  KEYWORD = "keyword",
  ORDER_STATUSES = "order_statuses",
  //Disabled shipment
  // SHIPMENT_STATUSES = "shipment_statuses",
}

interface SearchOrder {
  [SearchKeyOrder.KEYWORD]: string;
  [SearchKeyOrder.ORDER_STATUSES]: string;
  // [SearchKeyOrder.SHIPMENT_STATUSES]: string;
}

export interface UseSearchURLOrdersReturn {
  filter: Partial<SearchOrder>;
  paging: ReturnPaging;
  searchOrders: (params: Partial<SearchOrder>) => void;
  resetFilter: ReturnType<typeof useSearchParams>[1];
}
export const useSearchURLOrders = (): UseSearchURLOrdersReturn => {
  const { changePage, page, pageCount, onRowsPerPageChange, onPageChange } = usePaging();

  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const paging: UseSearchURLOrdersReturn["paging"] = {
    page,
    pageCount,
    onRowsPerPageChange,
    onPageChange,
  };

  const filter: UseSearchURLOrdersReturn["filter"] = {
    keyword: searchParams.get(SearchKeyOrder.KEYWORD)?.trim() ?? "",
    order_statuses: searchParams.get(SearchKeyOrder.ORDER_STATUSES) ?? JSON.stringify([]),
    // shipment_statuses: searchParams.get(SearchKeyOrder.SHIPMENT_STATUSES) ?? JSON.stringify([]),
  };

  const searchOrders: UseSearchURLOrdersReturn["searchOrders"] = (params) => {
    changePage(0);

    const searchParamsObj: Partial<Record<SearchKeyOrder, string>> = {
      ...filter,
      ...params,
    };
    navigate({
      pathname: `/${RoutePage.Order}`,
      search: `?${createSearchParams(searchParamsObj)}`,
    });
  };

  return {
    searchOrders,
    resetFilter: setSearchParams,
    filter,
    paging,
  };
};
