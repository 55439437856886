import { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";
import { RoutePage } from "src/shared/constants/route";
import { VoucherForm } from "src/shared/types/forms";
import { postData } from "src/shared/utils/service";

import { useMutation } from "@tanstack/react-query";
import useShowOverlayLoading from "src/hooks/useShowOverlayLoading";
import useShowSnackbar from "src/hooks/useShowSnackbar";

export const useCreateVoucher = () => {
  const navigate = useNavigate();
  const showLoading = useShowOverlayLoading();
  const showSnackbar = useShowSnackbar();

  return useMutation<unknown, AxiosError<{ msg: string }>, VoucherForm>({
    mutationKey: ["create-voucher"],
    mutationFn: (data) => postData("/vouchers/with-admin", data),
    onSuccess: () => {
      showSnackbar("Tạo voucher mới thành công!", "success");
      showLoading(false);
      navigate(`/${RoutePage.Voucher}`);
    },
    onError: (error) => {
      if (error?.response?.data && error.response.data.msg === "error when code existed") {
        showSnackbar("Mã voucher đã tồn tại, vui lòng cập nhật mã voucher mới", "error");
      } else {
        showSnackbar("Tạo voucher mới thất bại!", "error");
      }
      showLoading(false);
    },
  });
};
