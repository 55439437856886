import { PostDetailResponse } from "src/shared/types/services";
import { getData } from "src/shared/utils/service";

import { useQuery } from "@tanstack/react-query";

type UsePostDetail = {
  postId: string;
};
const usePostDetail = ({ postId }: UsePostDetail) => {
  return useQuery<unknown, unknown, PostDetailResponse>({
    queryKey: ["post-detail", { postId }],
    queryFn: () => getData({ path: "/posts", slug: postId }),
    enabled: Boolean(postId),
    staleTime: Infinity,
    cacheTime: 0,
  });
};

export default usePostDetail;
