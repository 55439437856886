import axios from "axios";

import useShowSnackbar from "./useShowSnackbar";

import { useMutation } from "@tanstack/react-query";
import useShowOverlayLoading from "src/hooks/useShowOverlayLoading";

const useUploadImage = () => {
  const showSnackbar = useShowSnackbar();
  const showLoading = useShowOverlayLoading();

  return useMutation({
    mutationKey: ["upload-image"],
    mutationFn: (image: File) =>
      axios.post(
        `${process.env.REACT_APP_CLOUDINARY_API_END_POINT}/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/image/upload`,
        {
          file: image,
          upload_preset: "ml_default",
          cloud_name: process.env.REACT_APP_CLOUDINARY_CLOUD_NAME,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      ),
    onError() {
      showSnackbar("Cập nhật hình ảnh thất bại!", "error");
      showLoading(false);
    },
  });
};

export default useUploadImage;
