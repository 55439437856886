import { useCallback } from "react";

import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import { CollectionTagEnum } from "src/shared/constants/common";
import {
  CollectionSearch,
  CollectionSearchKey,
  CollectionSortBy,
  CollectionVisibility,
} from "src/shared/types/collection-filter";
import { ReturnPaging } from "src/shared/types/paging";

import usePaging from "src/hooks/usePaging";

export interface UseSearchURLCollectionsReturn {
  searchCollections: (params: Partial<CollectionSearch>) => void;
  filter: CollectionSearch;
  paging: ReturnPaging;
}

const useSearchURLCollections = (): UseSearchURLCollectionsReturn => {
  const { changePage, page, pageCount, onRowsPerPageChange, onPageChange } = usePaging();

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const paging: UseSearchURLCollectionsReturn["paging"] = {
    page,
    pageCount,
    onRowsPerPageChange,
    onPageChange,
  };

  const filter: UseSearchURLCollectionsReturn["filter"] = {
    search: searchParams.get(CollectionSearchKey.SEARCH) || "",
    sortBy:
      (searchParams.get(CollectionSearchKey.SORT_BY) as CollectionSortBy) ||
      CollectionSortBy.LATEST,
    visible:
      (searchParams.get(CollectionSearchKey.VISIBLE) as CollectionVisibility) ||
      CollectionVisibility.ALL,
    tag: (searchParams.get(CollectionSearchKey.TAG) as CollectionTagEnum) || CollectionTagEnum.ALL,
  };

  const searchCollections: UseSearchURLCollectionsReturn["searchCollections"] = useCallback(
    (params) => {
      const searchParamsObj: Record<CollectionSearchKey, string> = {
        search: searchParams.get(CollectionSearchKey.SEARCH)?.trim() || "",
        sortBy: searchParams.get(CollectionSearchKey.SORT_BY) || CollectionSortBy.LATEST,
        visible: searchParams.get(CollectionSearchKey.VISIBLE) || CollectionVisibility.ALL,
        tag: searchParams.get(CollectionSearchKey.TAG) || CollectionTagEnum.ALL,
        ...params,
      };

      changePage(0);

      navigate({
        pathname: "/collections",
        search: `?${createSearchParams(searchParamsObj)}`,
      });
    },
    [navigate, searchParams, changePage]
  );

  return {
    searchCollections,
    filter,
    paging,
  };
};

export default useSearchURLCollections;
