import { PropsWithChildren } from "react";

import { Box, Stack, Typography } from "@mui/material";

interface PageWrapperProps {
  title: string;
  actionArea?: JSX.Element;
}

const PageWrapper = ({ children, title, actionArea }: PropsWithChildren<PageWrapperProps>) => (
  <Stack width="100%" mb={3}>
    <Box display="flex" justifyContent="space-between" py={2}>
      <Box ml={2}>
        <Typography variant="h5">{title}</Typography>
      </Box>

      {actionArea ? (
        <Stack direction="row" spacing={1.5}>
          {actionArea}
        </Stack>
      ) : null}
    </Box>
    {children}
  </Stack>
);

export default PageWrapper;
