import { useEffect, useState } from "react";

import { useSearchParams } from "react-router-dom";
import { PostType } from "src/shared/constants/common";
import { RoutePage } from "src/shared/constants/route";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { Box, Tab, Tabs } from "@mui/material";
import ButtonLink from "src/components/ButtonLink";
import PageWrapper from "src/components/PageWrapper";
import { PaperCustom } from "src/components/PaperCustom";
import PostTable from "src/components/tables/PostTable";

import usePosts from "src/hooks/usePosts";

export const PostsPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [postType, setPostType] = useState<PostType>(
    (searchParams.get("type") as PostType) || PostType.DAILY_UPDATE
  );

  const { posts, pagination, isLoading: isLoadingPosts, refetch } = usePosts(postType);

  const handleChange = (_event: React.SyntheticEvent, newValue: PostType) => {
    setPostType(newValue);
    setSearchParams({ type: newValue });
  };

  useEffect(() => {
    refetch();
  }, [postType, refetch]);

  return (
    <PageWrapper
      title="Danh sách bài viết"
      actionArea={
        <ButtonLink to={`/${RoutePage.Post}/create`} startIcon={<AddCircleOutlineIcon />}>
          Tạo bài viết
        </ButtonLink>
      }>
      <PaperCustom>
        <Box pb={2}>
          <Box sx={{ width: "100%" }}>
            <Tabs value={postType} onChange={handleChange}>
              <Tab value={PostType.DAILY_UPDATE} label={PostType.DAILY_UPDATE} />
              <Tab value={PostType.EDITORIAL} label={PostType.EDITORIAL} />
              <Tab value={PostType.MIX_MATCH} label={PostType.MIX_MATCH} />
            </Tabs>
          </Box>
        </Box>
        <PostTable posts={posts} isLoading={isLoadingPosts} pagination={pagination} />
      </PaperCustom>
    </PageWrapper>
  );
};

export default PostsPage;
