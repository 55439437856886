import { ProductStockStatusEnum } from "src/shared/constants/common";

export enum ProductVisibility {
  VISIBLE = "1",
  INVISIBLE = "0",
  ALL = "-1",
}

export enum ProductPriceSort {
  ASC = "ASC",
  DESC = "DESC",
}

export enum ProductSearchKey {
  STATUS = "status",
  PRICE_SORT = "priceSort",
  SEARCH = "search",
  VISIBLE = "visible",
  // COLLECTION = "collection",
}

export interface ProductSearch {
  [ProductSearchKey.STATUS]: ProductStockStatusEnum;
  [ProductSearchKey.PRICE_SORT]: ProductPriceSort;
  [ProductSearchKey.SEARCH]: string;
  [ProductSearchKey.VISIBLE]: ProductVisibility;
  // [ProductSearchKey.COLLECTION]: string;
}
