import { forwardRef, useContext } from "react";

import { Snackbar } from "@mui/material";
import MuiAlert, { AlertProps } from "@mui/material/Alert";

import { GlobalContext } from "src/shared/Providers/GlobalProvider";

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ActionSnackbar = () => {
  const { show, hideSnackbar, message, severity } = useContext(GlobalContext);

  return (
    <Snackbar open={show["snackbar"]} autoHideDuration={4000} onClose={hideSnackbar}>
      <Alert
        onClose={() => hideSnackbar(null, "escapeKeyDown")}
        severity={severity}
        sx={{ width: "100%" }}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default ActionSnackbar;
