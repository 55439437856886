import { CollectionForm } from "src/shared/types/forms";
import { putData } from "src/shared/utils/service";

import { useMutation } from "@tanstack/react-query";
import useShowSnackbar from "src/hooks/useShowSnackbar";

const useUpdateCollection = ({
  collectionId,
  onSuccess,
}: {
  collectionId: string;
  onSuccess: (data: any) => void;
}) => {
  const showSnackbar = useShowSnackbar();

  return useMutation({
    mutationKey: ["update-collection"],
    mutationFn: (data: CollectionForm) =>
      putData<CollectionForm>("/collections/with-admin", data, collectionId),
    onSuccess(data) {
      showSnackbar("Cập nhật nhóm sản phẩm thành công!", "success");
      onSuccess(data.data);
    },
    onError() {
      showSnackbar("Cập nhật nhóm sản phẩm thất bại!", "error");
    },
  });
};

export default useUpdateCollection;
