import { postData } from "src/shared/utils/service";

import { useMutation } from "@tanstack/react-query";
import useShowSnackbar from "src/hooks/useShowSnackbar";

interface UseOrderNoteHistoryRequest {
  order_id: string;
  note: string;
}

interface UseUpdateOrderNoteHistoryProps {
  onSuccess?: () => void;
}

const useUpdateOrderNoteHistory = ({ onSuccess }: UseUpdateOrderNoteHistoryProps) => {
  const showSnackbar = useShowSnackbar();

  return useMutation<unknown, unknown, UseOrderNoteHistoryRequest>({
    mutationKey: ["update-order-note-history"],
    mutationFn: (data) => {
      return postData(`/order-note-history/with-admin`, data);
    },
    onSuccess: () => {
      showSnackbar("Cập nhật ghi chú thành công!", "success");
      onSuccess?.();
    },
    onError: () => {
      showSnackbar("Cập nhật ghi chú thất bại!", "error");
    },
  });
};

export default useUpdateOrderNoteHistory;
