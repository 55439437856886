import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ButtonLink from "src/components/ButtonLink";
import OrderFilter from "src/components/OrderFilter";
import PageWrapper from "src/components/PageWrapper";
import { PaperCustom } from "src/components/PaperCustom";
import { OrderTable } from "src/components/tables/OrderTable";

import useOrders from "src/hooks/useOrders";
import { useSearchURLOrders } from "src/hooks/useSearchURLOrders";

const OrdersPage = () => {
  const { filter, paging, searchOrders, resetFilter } = useSearchURLOrders();

  const { orders, isLoading, pagination } = useOrders({ filter, paging });

  return (
    <PageWrapper
      title="Danh sách đơn hàng"
      actionArea={
        <ButtonLink to={"/orders/create"} startIcon={<AddCircleOutlineIcon />}>
          Tạo đơn hàng
        </ButtonLink>
      }>
      <PaperCustom>
        <OrderFilter filter={filter} searchOrders={searchOrders} resetFilter={resetFilter} />

        <OrderTable orders={orders} isLoading={isLoading} pagination={pagination} />
      </PaperCustom>
    </PageWrapper>
  );
};

export default OrdersPage;
