import { CollectionSearchKey } from "src/shared/types/collection-filter";

import { Box } from "@mui/material";
import ClearableInputFilter from "src/components/ClearableInputFilter";
import CollectionFilterPopover from "src/components/CollectionFilterPopover";

import { UseSearchURLCollectionsReturn } from "src/hooks/useSearchURLCollections";

interface CollectionFilterContainerProps extends Omit<UseSearchURLCollectionsReturn, "paging"> {}

const CollectionFilterContainer = ({
  filter,
  searchCollections,
}: CollectionFilterContainerProps) => (
  <Box display="flex" width="100%" mb={3} pt={1}>
    <CollectionFilterPopover filter={filter} searchCollections={searchCollections} />
    <ClearableInputFilter
      initialValue={filter[CollectionSearchKey.SEARCH]}
      onEnter={(keyword: string) => searchCollections({ search: keyword })}
    />
  </Box>
);
export default CollectionFilterContainer;
