import { CollectionSearchKey } from "src/shared/types/collection-filter";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ButtonLink from "src/components/ButtonLink";
import CollectionFilterContainer from "src/components/CollectionFilterContainer";
import CollectionTable from "src/components/Collections/CollectionsTable";
import PageWrapper from "src/components/PageWrapper";
import { PaperCustom } from "src/components/PaperCustom";

import useCollections from "src/hooks/useCollections";
import useSearchURLCollections from "src/hooks/useSearchURLCollections";

const CollectionsPage = () => {
  const { filter, searchCollections, paging } = useSearchURLCollections();

  const { collections, isLoading, pagination } = useCollections({
    paging,
    sortBy: filter[CollectionSearchKey.SORT_BY],
    search: filter[CollectionSearchKey.SEARCH],
    visible: filter[CollectionSearchKey.VISIBLE],
    tag: filter[CollectionSearchKey.TAG],
  });

  return (
    <PageWrapper
      title="Danh sách nhóm sản phẩm"
      actionArea={
        <ButtonLink to="/collections/create" startIcon={<AddCircleOutlineIcon />}>
          Tạo nhóm sản phẩm
        </ButtonLink>
      }>
      <PaperCustom>
        <CollectionFilterContainer filter={filter} searchCollections={searchCollections} />
        <CollectionTable collections={collections} isLoading={isLoading} pagination={pagination} />
      </PaperCustom>
    </PageWrapper>
  );
};

export default CollectionsPage;
