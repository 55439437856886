import { ReactNode } from "react";

import { Image } from "antd";
import { Link } from "react-router-dom";
import { UIMode } from "src/shared/constants/common";
import { getAttributeValues } from "src/shared/helpers/order";
import { OrderForm, OrderItems } from "src/shared/types/forms";
import { arrayHasItem } from "src/shared/utils/array";
import { numberToCurrency, overflowSubString, reduceUrlImage } from "src/shared/utils/common";

import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { Box, IconButton, Typography } from "@mui/material";
import Stack from "@mui/material/Stack/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBodySkeleton from "src/components/TableBodySkeleton";

import { FieldArrayWithId } from "react-hook-form/dist/types/fieldArray";

interface OrderItemsTableProps {
  orderItems: OrderItems | Array<FieldArrayWithId<OrderForm, "orderItems", "id">>;
  mode?: UIMode;
  isLoading?: boolean;
  isLink?: boolean;
  fallbackTitle?: string;
  handleDelete?: (index: number) => void;
  renderQuantity?: (index: number) => ReactNode;
}

export const OrderItemsTable = ({
  orderItems,
  mode = UIMode.VIEW_ONLY,
  isLoading = false,
  isLink = false,
  fallbackTitle = "Không có dữ liệu phù hợp",
  handleDelete,
  renderQuantity,
}: OrderItemsTableProps) => {
  return orderItems && arrayHasItem(orderItems) ? (
    <>
      <TableContainer>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell width={"45%"} align="center">
                Biến thể
              </TableCell>
              <TableCell width={"20%"} align="center">
                Số lượng
              </TableCell>
              <TableCell width={"15%"} align="center">
                Giá
              </TableCell>
              <TableCell width={"15%"} align="center">
                Thành tiền
              </TableCell>
              <TableCell width={"5%"} align="center"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orderItems.map((orderItem, index) => {
              const { variant_id, product_name, attribute_values, image, quantity, product_price } =
                orderItem;
              return (
                <TableRow key={"id" in orderItem && orderItem.id ? orderItem.id : variant_id}>
                  <TableCell component="th" scope="row">
                    <Box display={"flex"} alignItems={"center"}>
                      <Image
                        width={"100%"}
                        height={"100%"}
                        style={{ objectFit: "cover" }}
                        wrapperStyle={{
                          objectFit: "cover",
                          width: 80,
                          height: 50,
                          flex: 1,
                        }}
                        src={reduceUrlImage({ originUrl: image?.url, width: 100, quality: 10 })}
                        alt={image ? image.alt : ""}
                        fallback="/images/nothing-image.png"
                      />
                      <Stack spacing={1} flex={2} ml={1}>
                        <Typography variant="body2">
                          {isLink ? (
                            <Link
                              to={""}
                              style={{
                                color: "#2962ff",
                                textDecoration: "none",
                              }}>
                              {overflowSubString(product_name)}
                            </Link>
                          ) : (
                            overflowSubString(product_name)
                          )}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          {getAttributeValues(attribute_values)}
                        </Typography>
                      </Stack>
                    </Box>
                  </TableCell>
                  <TableCell align="center">
                    {mode === UIMode.EDIT && renderQuantity ? renderQuantity(index) : quantity}
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body2">{numberToCurrency(product_price)}</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body2">
                      {numberToCurrency(product_price * quantity)}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    {mode === UIMode.EDIT ? (
                      <IconButton
                        aria-label="delete"
                        size="medium"
                        onClick={() => handleDelete?.(index)}>
                        <DeleteOutlineOutlinedIcon />
                      </IconButton>
                    ) : null}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  ) : (
    <>
      <TableContainer>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell width={"45%"} align="center">
                Biến thể
              </TableCell>
              <TableCell width={"20%"} align="center">
                Số lượng
              </TableCell>
              <TableCell width={"15%"}>Giá</TableCell>
              <TableCell width={"15%"}>Thành tiền</TableCell>
              <TableCell width={"5%"}></TableCell>
            </TableRow>
          </TableHead>
          {isLoading ? (
            <TableBody>
              <TableBodySkeleton numberColumn={4} />
            </TableBody>
          ) : null}
        </Table>
      </TableContainer>
      {!isLoading ? (
        <Box display={"flex"} textAlign={"center"} justifyContent={"center"}>
          <Typography variant="caption" fontStyle={"italic"} py={2} alignSelf={"center"}>
            {fallbackTitle}
          </Typography>
        </Box>
      ) : null}
    </>
  );
};
