import { useState } from "react";

import { UploadFile } from "antd";
import axios from "axios";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { CollectionTagEnum } from "src/shared/constants/common";
import { mutateProductValidateSchema } from "src/shared/types/form-validations";
import { UpdateProductForm } from "src/shared/types/forms";

import { Box, Button, Checkbox, FormControlLabel, Grid } from "@mui/material";
import ActionButtonSection from "src/components/ActionButtonSection";
import ConfirmedDialog from "src/components/dialogs/ConfirmedDialog";
import InputLabel from "src/components/InputLabel";
import PageWrapper from "src/components/PageWrapper";
import { PaperContentSection } from "src/components/PaperContentSection";
import PictureWallInput from "src/components/PictureWallInput";
import ProductVariantEditableSection from "src/components/ProductVariantEditableSection";
import AutocompleteHF from "src/components/rhf/AutocompleteHF";
import TextFieldHF from "src/components/rhf/TextFieldHF";

import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { joiResolver } from "@hookform/resolvers/joi";
import useCategories from "src/hooks/useCategories";
import useCollections from "src/hooks/useCollections";
import useDeleteProduct from "src/hooks/useDeleteProduct";
import useProductDetail from "src/hooks/useProductDetail";
import useShowOverlayLoading from "src/hooks/useShowOverlayLoading";
import useUpdateProduct from "src/hooks/useUpdateProduct";
import useUploadImage from "src/hooks/useUploadImage";
import useVendors from "src/hooks/useVendors";

require("@ckeditor/ckeditor5-build-classic/build/translations/vi");

const EditProductPage = () => {
  const navigate = useNavigate();
  const { id: productId } = useParams();
  const showLoading = useShowOverlayLoading();

  const categoriesData = useCategories();
  const vendorsData = useVendors();
  const { collections } = useCollections({
    paging: { page: -1, pageCount: -1, onPageChange: () => {}, onRowsPerPageChange: () => {} },
    tag: CollectionTagEnum.ALL,
  });

  const { productDetail } = useProductDetail({
    id: productId,
    onSuccess: ({
      name,
      category_id,
      collection_ids,
      description,
      is_visible,
      vendor_id,
      quote,
      images,
    }) => {
      reset({
        name,
        category_id,
        collection_ids,
        description,
        is_visible,
        vendor_id,
        quote,
      });

      if (images?.length) {
        setProductImageList(
          images.map(({ id, file_name, url }) => ({
            uid: id,
            name: file_name,
            status: "done",
            url,
          }))
        );
      }
    },
  });

  const { mutateAsync: onAsyncUploadImage } = useUploadImage();

  const { mutate: onUpdate } = useUpdateProduct({
    productId: productId || "",
    onSuccess: () => {
      showLoading(false);

      navigate("/products");
    },
  });

  const { handleSubmit, control, setValue, reset } = useForm<UpdateProductForm>({
    defaultValues: {
      name: "",
      description: "",
      is_visible: true,
      is_update_images: false,
      collection_ids: [],
      images: [],
      quote: "",
      type: 0,
      category_id: "",
      vendor_id: "",
    },
    resolver: joiResolver(mutateProductValidateSchema),
  });

  const [productImageList, setProductImageList] = useState<UploadFile[]>([]);

  const handleSaveProduct = async (data: UpdateProductForm) => {
    showLoading(true);
    const dataSave: UpdateProductForm = {
      ...data,
      is_update_images: productImageList.some(({ status }) => status !== "done"),
    };

    if (productImageList.length) {
      const productImagesUploader: Promise<{ url: string }>[] = productImageList.map((file) =>
        file.status === "done"
          ? Promise.resolve({ url: file.url })
          : onAsyncUploadImage(file.originFileObj!).then((res) => ({
              url: res.data.secure_url,
            }))
      );

      await axios.all(productImagesUploader).then((dataResponses) => {
        const imagesPayload = dataResponses.map((data) => ({
          url: data.url,
        }));

        onUpdate({ ...dataSave, images: [...imagesPayload] });
      });
    } else {
      onUpdate({
        ...dataSave,
        images: [...productImageList.map((image) => ({ url: image.url || "" }))],
      });
    }
  };

  const { mutate: onDeleteProduct } = useDeleteProduct();

  const [isShowConfirmedDialog, setIsShowConfirmedDialog] = useState<boolean>(false);
  const handleCollectionDeleteConfirmedDialog = (isAccept: boolean) => {
    setIsShowConfirmedDialog(false);

    if (isAccept) {
      onDeleteProduct && onDeleteProduct(productId ?? "");
    }
  };

  return (
    <PageWrapper
      title="Cập nhật sản phẩm"
      actionArea={
        <>
          <Button variant="contained" color="error" onClick={() => setIsShowConfirmedDialog(true)}>
            Xóa
          </Button>
          <ActionButtonSection actionOnClick={handleSubmit(handleSaveProduct)} backTo="/products" />
        </>
      }>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={8}>
          <PaperContentSection title="Thông tin chung" topSection>
            <Grid container spacing={2} p={2}>
              <Grid item xs={12}>
                <TextFieldHF
                  control={control}
                  name="name"
                  labelOverride="Tên sản phẩm"
                  placeholder="Tên sản phẩm"
                  required
                />
              </Grid>
              {vendorsData && vendorsData.length ? (
                <Grid item xs={6}>
                  <AutocompleteHF
                    formControlProps={{
                      control,
                      name: "vendor_id",
                    }}
                    required
                    labelOverride="Nhà cung cấp"
                    placeholder="Chọn nhà cung cấp"
                    options={vendorsData.map((vendor) => ({
                      label: vendor.name,
                      value: vendor.id,
                    }))}
                  />
                </Grid>
              ) : null}
              <Grid item xs={6}>
                <AutocompleteHF
                  formControlProps={{
                    control,
                    name: "category_id",
                  }}
                  required
                  labelOverride="Loại sản phẩm"
                  placeholder="Chọn loại sản phẩm"
                  options={categoriesData.map((category) => ({
                    label: category.name,
                    value: category.id,
                  }))}
                />
              </Grid>
              <Grid item xs={12}>
                <InputLabel>Mô tả sản phẩm</InputLabel>

                <CKEditor
                  config={{
                    language: "vi",
                  }}
                  data={productDetail?.description}
                  editor={ClassicEditor}
                  onChange={(_, editor) => {
                    const data = editor.getData();
                    setValue("description", data);
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextFieldHF
                  control={control}
                  name="quote"
                  placeholder="Trích dẫn sản phẩm"
                  labelOverride="Trích dẫn"
                  fullWidth
                />
              </Grid>
            </Grid>
          </PaperContentSection>
          <PaperContentSection
            title="Hình Ảnh Sản Phẩm"
            titleTooltip="Ảnh định dạng jpg, jpeg, png, gif tỉ lệ 1:1 (ảnh vuông) và độ phân giải 2048px x 2048px để chất lượng hình ảnh tốt nhất">
            <Grid container spacing={2} p={2}>
              <Grid item xs={12}>
                <Box display={"flex"}>
                  <PictureWallInput
                    fileList={productImageList}
                    updateFileList={(images) => setProductImageList(images)}
                    multiple
                  />
                </Box>
              </Grid>
            </Grid>
          </PaperContentSection>
          <ProductVariantEditableSection
            productId={productId}
            variants={productDetail?.variants || []}
          />
        </Grid>
        <Grid item xs={12} md={12} lg={4}>
          <PaperContentSection title="Nhóm Sản Phẩm" topSection>
            <Grid container spacing={2} p={2}>
              <Grid item xs={12}>
                <AutocompleteHF<UpdateProductForm, true>
                  formControlProps={{
                    control,
                    name: "collection_ids",
                  }}
                  multiple
                  placeholder="Chọn nhóm sản phẩm"
                  options={collections.map((collection) => ({
                    label: collection.title,
                    value: collection.id,
                  }))}
                />
              </Grid>
            </Grid>
          </PaperContentSection>

          <PaperContentSection title="Trạng thái">
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Controller
                    name={"is_visible"}
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        {...field}
                        checked={Boolean(field.value)}
                        onChange={(e) => field.onChange(e.target.checked)}
                      />
                    )}
                  />
                }
                label="Hiển thị sản phẩm"
              />
            </Grid>
          </PaperContentSection>
        </Grid>
      </Grid>
      <ConfirmedDialog
        show={isShowConfirmedDialog}
        onClose={handleCollectionDeleteConfirmedDialog}
      />
    </PageWrapper>
  );
};

export default EditProductPage;
