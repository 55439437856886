export const locales: Intl.LocalesArgument = "vi-VN";

export enum FormMode {
  CREATE = "create",
  UPDATE = "update",
}

export enum UIMode {
  VIEW_ONLY = "view_only",
  EDIT = "edit",
}

export enum OrderStatusEnum {
  PENDING = "PENDING",
  SUBMITTED = "SUBMITTED",
  CANCELLED = "CANCELLED",
  INVOICED = "INVOICED",
}

export enum ProductStockStatusEnum {
  IN_STOCK = "IN_STOCK",
  OUT_OF_STOCK = "OUT_OF_STOCK",
}

export enum CollectionTagEnum {
  SIGNATURE = "SIGNATURE",
  SPOTLIGHT = " SPOTLIGHT",
  NORMAL = "NORMAL",
  ALL = "ALL",
}

export enum DiscountAmountEnum {
  FIXED_AMOUNT = "FIXED_AMOUNT",
  PERCENTAGE = "PERCENTAGE",
}

export enum VoucherAmountEnum {
  FIXED_AMOUNT = "FIXED_AMOUNT",
  PERCENTAGE = "PERCENTAGE",
}

export enum PostType {
  MIX_MATCH = "MIX_MATCH",
  DAILY_UPDATE = "DAILY_UPDATE",
  EDITORIAL = "EDITORIAL",
}

export enum BillItemTypeEnum {
  PRODUCT_BILL = "PRODUCT_BILL",
  VOUCHER_BILL = "VOUCHER_BILL",
  DISCOUNT_BILL = "DISCOUNT_BILL",
  ADJUSTMENT_BILL = "ADJUSTMENT_BILL",
  SHIPPING_BILL = "SHIPPING_BILL",
  EXTRA_FEE_BILL = "EXTRA_FEE_BILL",
}

export enum BillItemStatusEnum {
  PENDING = "PENDING",
  BILLED = "BILLED",
  SHIPPING = "SHIPPING",
  INVOICED = "INVOICED",
  CANCELLED = "CANCELLED",
}

export enum PaymentMethodEnum {
  CASH = "CASH",
  CREDIT_AND_DEBIT = "CREDIT_AND_DEBIT",
  MOBILE_WALLET = "MOBILE_WALLET",
  BANK_TRANSFER = "BANK_TRANSFER",
  CASH_ON_DELIVERY = "CASH_ON_DELIVERY",
}

export enum ShipmentStatusEnum {
  NONE = "NONE",
  PENDING = "PENDING",
  DELIVERING = "DELIVERING",
  DELIVERED = "DELIVERED",
  RETURNED = "RETURNED",
}
