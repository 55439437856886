import { useCallback } from "react";

import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { signUpValidateSchema } from "src/shared/types/form-validations";
import { SignUpForm } from "src/shared/types/forms";

import AuthSection from "src/components/AuthSection";
import TextFieldHF from "src/components/rhf/TextFieldHF";

import { joiResolver } from "@hookform/resolvers/joi";
import useMutationSignUp from "src/hooks/useMutationSignUp";

const SignUpPage = () => {
  const { handleSubmit, control } = useForm<SignUpForm>({
    defaultValues: {
      email: "",
      name: "",
      password: "",
      confirm_password: "",
    },
    resolver: joiResolver(signUpValidateSchema),
  });

  const navigate = useNavigate();
  const { mutate: onSignUp } = useMutationSignUp({
    onSuccess: () => {
      navigate("/login");
    },
  });

  const onSubmit = useCallback(
    (data: SignUpForm) => {
      onSignUp(data);
    },
    [onSignUp]
  );

  return (
    <AuthSection
      title="Đăng Ký"
      actionButton={{
        label: "Đăng Ký",
        onClick: handleSubmit(onSubmit),
      }}
      link={{
        textDisplay: "Đã có tài khoản? Đăng nhập",
        to: "/login",
      }}>
      <TextFieldHF control={control} required name="name" margin="normal" label="Tên" />
      <TextFieldHF
        control={control}
        required
        name="email"
        margin="normal"
        type="email"
        label="Email"
      />
      <TextFieldHF
        control={control}
        required
        name="password"
        margin="normal"
        type="password"
        label="Mật khẩu"
      />
      <TextFieldHF
        control={control}
        required
        name="confirm_password"
        margin="normal"
        type="password"
        label="Nhập lại mật khẩu"
      />
    </AuthSection>
  );
};

export default SignUpPage;
