import { FormProvider, useForm } from "react-hook-form";
import { RoutePage } from "src/shared/constants/route";
import { OrderForm } from "src/shared/types/forms";
import { transformOrder } from "src/shared/utils/transform";

import { Grid } from "@mui/material";
import ActionButtonSection from "src/components/ActionButtonSection";
import BillItemSection from "src/components/Orders/BillItemSection";
import CustomerSection from "src/components/Orders/CustomerSection";
import PaymentMethodSection from "src/components/Orders/PaymentMethodSection";
import ProductListSection from "src/components/Orders/ProductListSection";
import ShippingMethodSection from "src/components/Orders/ShippingMethodSection";
import PageWrapper from "src/components/PageWrapper";
import { PaperContentSection } from "src/components/PaperContentSection";
import TextFieldHF from "src/components/rhf/TextFieldHF";

import useCreateOrder from "src/hooks/useCreateOrder";
import useShowOverlayLoading from "src/hooks/useShowOverlayLoading";

const CreateOrderPage = () => {
  const orderFormMethod = useForm<OrderForm>({
    defaultValues: {
      customer: {
        last_name: "",
        first_name: "",
        phone_number: "",
        address: "",
      },
      orderItems: [],
      shipment: undefined,
      payment_method: undefined,
      note: "",
      voucherField: null,
      extraFeeField: 0,
    },
  });

  const { control, handleSubmit } = orderFormMethod;

  const { mutate: onCreateOrder } = useCreateOrder();

  const showLoading = useShowOverlayLoading();

  return (
    <PageWrapper
      title={"Tạo đơn hàng"}
      actionArea={
        <ActionButtonSection
          actionOnClick={handleSubmit((data) => {
            showLoading(false);

            const order = transformOrder(data);
            onCreateOrder(order);
          })}
          backTo={`/${RoutePage.Order}`}
        />
      }>
      <FormProvider {...orderFormMethod}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={7.5}>
            <ProductListSection />

            <PaymentMethodSection />

            <ShippingMethodSection />

            <PaperContentSection title="Ghi chú">
              <Grid container spacing={2} p={1}>
                <Grid item xs={12}>
                  <TextFieldHF
                    control={control}
                    name="note"
                    placeholder="Ghi chú"
                    multiline
                    minRows={3}
                  />
                </Grid>
              </Grid>
            </PaperContentSection>
          </Grid>

          <Grid item xs={12} md={12} lg={4.5}>
            <BillItemSection />

            <CustomerSection />
          </Grid>
        </Grid>
      </FormProvider>
    </PageWrapper>
  );
};

export default CreateOrderPage;
