import { getData } from "src/shared/utils/service";

import { useQuery } from "@tanstack/react-query";

const useMenus = ({ onSuccess }: { onSuccess?: (data: any) => void }) => {
  return useQuery({
    staleTime: Infinity,
    cacheTime: 0,
    queryKey: ["menus"],
    queryFn: () => getData({ path: "/menus" }),
    onSuccess: onSuccess,
  });
};

export default useMenus;
