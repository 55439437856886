import { useMemo } from "react";

import { Image } from "antd";
import { Link } from "react-router-dom";
import { RoutePage } from "src/shared/constants/route";
import { Pagination } from "src/shared/types/paging";
import { PostListResponse } from "src/shared/types/services";
import { convertToDate, overflowSubString, reduceUrlImage } from "src/shared/utils/common";

import { Box, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import CustomDataGrid from "src/components/common/CustomDataGrid";

const columns: GridColDef[] = [
  {
    field: "id",
    headerName: "Bài viết",
    renderCell: (params) => {
      const { topic_image, id, title } = params.row;
      return (
        <Box display={"flex"} alignItems={"center"}>
          <Image
            width={"100%"}
            height={"100%"}
            style={{ objectFit: "cover" }}
            wrapperStyle={{
              objectFit: "cover",
              width: 80,
              height: 50,
              flex: 1,
            }}
            src={reduceUrlImage({ originUrl: topic_image?.url, width: 90, quality: 10 })}
            alt={topic_image ? topic_image.alt : ""}
            fallback="/images/nothing-image.png"
          />

          <Typography variant="body2" flex={2} ml={1}>
            <Link
              to={`/${RoutePage.Post}/${id}/edit`}
              style={{
                color: "#2962ff",
                textDecoration: "none",
              }}>
              {title}
            </Link>
          </Typography>
        </Box>
      );
    },
    flex: 0.4,
  },
  {
    field: "description",
    headerName: "Miêu tả bài viết",
    valueGetter: (value) => {
      const description = value;
      return overflowSubString(description);
    },
    flex: 0.4,
  },

  {
    field: "created_at",
    headerName: "Ngày tạo",
    valueGetter: (value) => {
      const created_at = value;
      return convertToDate(created_at);
    },
    flex: 0.1,
  },
  {
    field: "is_visible",
    headerName: "Trạng thái",
    valueGetter: (value) => {
      const is_visible = value;
      return is_visible ? "Hiển thị" : "Ẩn";
    },
    flex: 0.1,
  },
];

interface PostTableProps {
  posts?: Array<PostListResponse>;
  isLoading?: boolean;
  pagination: Pagination;
}
const PostTable = ({ posts = [], isLoading, pagination }: PostTableProps) => {
  const postRows = useMemo(
    () =>
      (posts ?? []).map(({ id, title, topic_image, description, created_at, is_visible }) => ({
        id,
        title,
        topic_image,
        description,
        created_at,
        is_visible,
      })),
    [posts]
  );

  return (
    <CustomDataGrid
      rows={postRows}
      columns={columns}
      loading={isLoading}
      customPaginationProps={pagination}
    />
  );
};

export default PostTable;
