import { useNavigate } from "react-router-dom";
import { RoutePage } from "src/shared/constants/route";
import { deleteData } from "src/shared/utils/service";

import { useMutation } from "@tanstack/react-query";
import useShowSnackbar from "src/hooks/useShowSnackbar";

const useDeleteProduct = () => {
  const navigate = useNavigate();
  const showSnackbar = useShowSnackbar();

  return useMutation({
    mutationKey: ["product-delete"],
    mutationFn: (productId: string) => deleteData("/products/with-admin", productId),
    onSuccess() {
      showSnackbar("Xóa sản phẩm thành công!", "success");
      navigate(`/${RoutePage.Product}`);
    },
    onError() {
      showSnackbar("Xóa sản phẩm thất bại!", "error");
    },
  });
};

export default useDeleteProduct;
