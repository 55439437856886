import { OrderStatus } from "src/shared/types/helpers";
import { patchData } from "src/shared/utils/service";

import { useMutation } from "@tanstack/react-query";
import useShowSnackbar from "src/hooks/useShowSnackbar";

interface OrderStatusRequest {
  order_status: OrderStatus;
}

type UsePatchOrderStatus = {
  orderId: string;
  onSuccess?: () => void;
};

const usePatchOrderStatus = ({ orderId, onSuccess }: UsePatchOrderStatus) => {
  const showSnackbar = useShowSnackbar();

  return useMutation<unknown, unknown, OrderStatusRequest>({
    mutationKey: ["patch-order-status"],
    mutationFn: (data) => {
      return patchData(`/orders/with-admin/${orderId}/order-status`, data);
    },
    onSuccess: () => {
      showSnackbar("Cập nhật trạng thái đơn hàng thành công!", "success");
      onSuccess?.();
    },
    onError: () => {
      showSnackbar("Cập nhật trạng thái đơn hàng thất bại!", "error");
    },
  });
};

export default usePatchOrderStatus;
