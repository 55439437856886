import { useEffect } from "react";

import { useLocation } from "react-router-dom";
import { RoutePage } from "src/shared/constants/route";

const useDocumentTitle = () => {
  const location = useLocation();

  const mappingPathToTitle = (path: string) => {
    if (path === "/") {
      return "Tổng Quan";
    } else if (path.includes(RoutePage.Order)) {
      return "Đơn Hàng";
    } else if (path.includes(RoutePage.Product)) {
      return "Sản Phẩm";
    } else if (path.includes(RoutePage.Collection)) {
      return "Nhóm Sản Phẩm";
    } else if (path.includes(RoutePage.Menu)) {
      return "Danh Mục";
    } else if (path.includes(RoutePage.Discount)) {
      return "Khuyến Mãi";
    } else if (path.includes(RoutePage.Voucher)) {
      return "Voucher";
    } else if (path.includes(RoutePage.Post)) {
      return "Bài Viết";
    } else if (path.includes(RoutePage.Login)) {
      return "Đăng Nhập";
    } else if (path.includes(RoutePage.SignUp)) {
      return "Đăng Ký";
    } else {
      return "404 - Không Tìm Thấy Trang";
    }
  };

  useEffect(() => {
    const prevTitle = document.title;
    document.title = mappingPathToTitle(location.pathname);
    return () => {
      document.title = prevTitle;
    };
  }, [location]);
};

export default useDocumentTitle;
