import { OrderResponse, QueryDataResponse } from "src/shared/types/services";
import { getData } from "src/shared/utils/service";

import { useQuery } from "@tanstack/react-query";
import { UseSearchURLOrdersReturn } from "src/hooks/useSearchURLOrders";

interface UseOrdersProps extends Pick<UseSearchURLOrdersReturn, "paging" | "filter"> {}

const useOrders = ({ filter, paging }: UseOrdersProps) => {
  const {
    keyword,
    order_statuses,
    //shipment_statuses
  } = filter;
  const { page, pageCount, ...restPaging } = paging;

  const { data: dataOrders, ...rest } = useQuery<QueryDataResponse<OrderResponse>>({
    staleTime: Infinity,
    cacheTime: 0,
    queryKey: [
      "orders",
      {
        page,
        pageCount,
        keyword,
        order_statuses, //shipment_statuses
      },
    ],
    queryFn: () =>
      getData({
        path: "/orders/with-admin",
        config: {
          params: {
            limit: pageCount,
            offset: page * pageCount,
            sort: "created_at",
            sort_dir: "desc",
            keyword,
            order_statuses,
            // shipment_statuses,
          },
        },
        requiredAuth: true,
      }),
  });

  return {
    orders: dataOrders?.items || [],
    pagination: {
      count: dataOrders?.paging.total ?? 0,
      rowsPerPage: pageCount,
      page,
      ...restPaging,
    },
    ...rest,
  };
};

export default useOrders;
