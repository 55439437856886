import { createSearchParams, To, URLSearchParamsInit } from "react-router-dom";
import { locales } from "src/shared/constants/common";
import { CouponAmountType } from "src/shared/types/helpers";

export const urlImageWithEmpty = (url?: string | null) => {
  return url || "/images/nothing-image.png";
};

export const numberToCurrency = (price: number) => {
  return price.toLocaleString(locales, {
    style: "currency",
    currency: "VND",
  });
};

export const convertToDate = (dateString: string) => {
  //  Convert a "dd/MM/yyyy" string into a Date object
  let date = new Date(dateString);
  return date.toLocaleDateString(locales);
};

export const getFormattedCoupon = ({
  amountType,
  amountValue,
}: {
  amountType: CouponAmountType;
  amountValue: string | number;
}) => {
  const transformByType = {
    PERCENTAGE: {
      typeLabel: "Tỷ lệ phần trăm",
      options: {
        style: "percent",
      },
      value: Number(amountValue) / 100,
    },
    FIXED_AMOUNT: {
      typeLabel: "Số tiền cố định",
      options: {
        style: "currency",
        currency: "VND",
      },
      value: Number(amountValue),
    },
    NONE: {
      typeLabel: "",
      value: 0,
    },
  } as Record<
    CouponAmountType,
    {
      typeLabel: string;
      options?: Intl.NumberFormatOptions;
      value: number;
    }
  >;
  const { typeLabel, options, value } = transformByType[amountType ?? "NONE"];

  return {
    typeLabel,
    expectedValue: value.toLocaleString(locales, options),
  };
};

export const overflowSubString = (str: string, char: number = 80) => {
  if (!str || str.length <= char) return str;

  return `${str.substring(0, char)} ...`;
};

export const navigateToWithParams = (url: string, params: URLSearchParamsInit): To => {
  const searchParams = createSearchParams(params).toString();
  return {
    pathname: url,
    search: searchParams,
  };
};

export const isString = (value: string | File | undefined): value is string =>
  typeof value === "string";

export const reduceUrlImage = ({
  originUrl,
  width = 1920,
  quality = 45,
}: {
  originUrl?: string | null;
  width?: number;
  quality?: number;
}) => {
  if (!originUrl) {
    return "/images/nothing-image.png";
  }

  const [hostUrl, pathUrl] = originUrl.split("upload/");

  return hostUrl + `upload/w_${width}/q_${quality}/` + pathUrl;
};
