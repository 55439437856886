import { PropsWithChildren } from "react";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import "dayjs/locale/en-gb";

const MuiPickersUtilsProvider = ({ children }: PropsWithChildren) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"en-gb"}>
      {children}
    </LocalizationProvider>
  );
};

export default MuiPickersUtilsProvider;
