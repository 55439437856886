import { useRef, useState } from "react";

import { ProductStockStatusEnum } from "src/shared/constants/common";
import { PAGE_COUNT } from "src/shared/constants/pagination";
import { ProductsServiceResponse } from "src/shared/types/services";
import { getData } from "src/shared/utils/service";

import { useQuery } from "@tanstack/react-query";

type UseVariantsByProductList = {
  name: string;
  onSuccess?: (data: ProductsServiceResponse["items"]) => void;
};
export const useVariantsByProductList = ({ name, onSuccess }: UseVariantsByProductList) => {
  const initialPage: number = 1;
  const [page, setPage] = useState(initialPage);
  const handleChangePage = (_event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const limit: number = PAGE_COUNT[0];
  const offset: number = (page - 1) * limit;

  const totalRef = useRef(0);
  const count: number = Math.ceil(totalRef.current / limit) || 1;

  const { data: productVariantListWithPagination, ...rest } = useQuery<ProductsServiceResponse>({
    staleTime: Infinity,
    cacheTime: 0,
    queryKey: [
      "products-variants",
      {
        page,
        search: name,
      },
    ],
    queryFn: () =>
      getData({
        path: "/products",
        config: {
          params: {
            limit,
            offset,
            name,
            collection_ids: [],
            is_visible: true,
            stock_status: ProductStockStatusEnum.IN_STOCK,
          },
        },
      }),

    onSuccess: (data) => {
      if (!data) return;

      if (totalRef.current !== data.paging.total) {
        totalRef.current = data.paging.total;
      }

      onSuccess?.(data.items);
    },
  });

  return {
    productVariantList: productVariantListWithPagination?.items || [],
    paging: {
      count,
      page,
      onChange: handleChangePage,
    },
    ...rest,
  };
};
