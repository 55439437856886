import { ProductResponse } from "src/shared/types/services";
import { getData } from "src/shared/utils/service";

import { useQuery } from "@tanstack/react-query";

const useProductDetail = ({
  id,
  variantId,
  onSuccess,
}: {
  id?: string;
  variantId?: string;
  onSuccess?: ((data: ProductResponse) => void) | undefined;
}) => {
  const { data: productDetail } = useQuery<ProductResponse>({
    refetchOnWindowFocus: false,
    queryKey: ["product-detail", { id, variantId }],
    queryFn: () => getData({ path: "/products", slug: id }),
    enabled: Boolean(id),
    onSuccess,
  });

  return {
    productDetail,
  };
};

export default useProductDetail;
