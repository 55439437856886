import { MouseEvent, useEffect, useState } from "react";

import { CollectionTagEnum } from "src/shared/constants/common";
import {
  CollectionSearchKey,
  CollectionSortBy,
  CollectionVisibility,
} from "src/shared/types/collection-filter";

import FilterListOutlined from "@mui/icons-material/FilterListOutlined";
import {
  Box,
  Button,
  FormControl,
  Grid,
  MenuItem,
  Popover,
  Select,
  Typography,
} from "@mui/material";
import { PaperCustom } from "src/components/PaperCustom";

import { UseSearchURLCollectionsReturn } from "src/hooks/useSearchURLCollections";

interface CollectionFilterPopoverProps extends Omit<UseSearchURLCollectionsReturn, "paging"> {}

const CollectionFilterPopover = ({ filter, searchCollections }: CollectionFilterPopoverProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const [sortBy, setSortBy] = useState<CollectionSortBy>(filter[CollectionSearchKey.SORT_BY]);
  const [tag, setTag] = useState<CollectionTagEnum | undefined>(filter[CollectionSearchKey.TAG]);
  const [visibility, setVisibility] = useState<CollectionVisibility>(filter.visible);

  const handleApplyFilter = () => {
    searchCollections({
      sortBy,
      tag,
      visible: visibility,
    });
    handleClose();
  };

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (anchorEl) {
      setSortBy(filter[CollectionSearchKey.SORT_BY]);
      setTag(filter[CollectionSearchKey.TAG]);
      setVisibility(filter[CollectionSearchKey.VISIBLE]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [anchorEl]);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <Button
        aria-describedby={id}
        variant="outlined"
        onClick={handleClick}
        startIcon={<FilterListOutlined />}>
        Điều kiện lọc
      </Button>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        sx={{ width: "100%" }}>
        <PaperCustom
          sx={{
            width: "650px",
          }}>
          <Typography variant="body1">Hiển thị nhóm sản phẩm theo:</Typography>
          <Grid container rowSpacing={1} px={1.5} py={1}>
            <Grid container item spacing={1}>
              <Grid item lg={4}>
                <Box display={"flex"} alignItems={"center"} height={"100%"}>
                  <Typography variant="body2">Sắp xếp</Typography>
                </Box>
              </Grid>
              <Grid item lg={8}>
                <FormControl size="small" fullWidth>
                  <Select
                    value={sortBy}
                    onChange={(event) => setSortBy(event.target.value as CollectionSortBy)}>
                    <MenuItem value={CollectionSortBy.LATEST}>Mới nhất</MenuItem>
                    <MenuItem value={CollectionSortBy.OLDEST}>Cũ nhất</MenuItem>
                    <MenuItem value={CollectionSortBy.TITLE_ASC}>Tiêu đề A - Z</MenuItem>
                    <MenuItem value={CollectionSortBy.TITLE_DESC}>Tiêu đề Z - A</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container item spacing={1}>
              <Grid item lg={4}>
                <Box display="flex" alignItems="center" height="100%">
                  <Typography variant="body2">Thẻ</Typography>
                </Box>
              </Grid>
              <Grid item lg={8}>
                <FormControl size="small" fullWidth>
                  <Select
                    value={tag}
                    onChange={(event) => setTag(event.target.value as CollectionTagEnum)}>
                    <MenuItem value={CollectionTagEnum.ALL}>Tất cả</MenuItem>
                    <MenuItem value={CollectionTagEnum.NORMAL}>Bình thường</MenuItem>
                    <MenuItem value={CollectionTagEnum.SIGNATURE}>Signature</MenuItem>
                    <MenuItem value={CollectionTagEnum.SPOTLIGHT}>Spotlight</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container item spacing={1}>
              <Grid item lg={6}>
                <Box display={"flex"} alignItems={"center"} height={"100%"}>
                  <Typography variant="body2">Hiển thị sản phẩm:</Typography>
                </Box>
              </Grid>
              <Grid item lg={6}>
                <FormControl size="small" fullWidth>
                  <Select
                    value={visibility}
                    onChange={(event) => setVisibility(event.target.value as CollectionVisibility)}>
                    <MenuItem value={CollectionVisibility.INVISIBLE}>Ẩn</MenuItem>
                    <MenuItem value={CollectionVisibility.VISIBLE}>Hiện</MenuItem>
                    <MenuItem value={CollectionVisibility.ALL}>Tất cả</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>

          <Box display={"flex"} justifyContent={"space-between"}>
            <Button variant="contained" color="secondary" onClick={handleClose}>
              Hủy
            </Button>
            <Button variant="contained" onClick={handleApplyFilter}>
              Cập nhật
            </Button>
          </Box>
        </PaperCustom>
      </Popover>
    </>
  );
};

export default CollectionFilterPopover;
