import axios from "axios";

import { PluginConstructor } from "@ckeditor/ckeditor5-core";
import { UploadAdapter } from "@ckeditor/ckeditor5-upload";
import { FileLoader } from "@ckeditor/ckeditor5-upload/src/filerepository";

class CustomUploadAdapter {
  private loader;
  private abortController: AbortController;
  constructor(loader: FileLoader) {
    // The file loader instance to use during the upload.
    this.loader = loader;
    this.abortController = new AbortController();
  }

  // Starts the upload process.
  async upload(): ReturnType<UploadAdapter["upload"]> {
    // Return a promise that will be resolved when the file is uploaded.
    return this.loader.file.then((file) => {
      return this._handleUpload(file);
    });
  }

  _handleUpload(file: File | null): ReturnType<UploadAdapter["upload"]> {
    return new Promise((resolve, reject) => {
      if (!file) return;

      const genericError: string = `Couldn't upload file: ${file.name}.`;

      return axios
        .post(
          `${process.env.REACT_APP_CLOUDINARY_API_END_POINT}/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/image/upload`,
          {
            file,
            upload_preset: "ml_default",
            cloud_name: process.env.REACT_APP_CLOUDINARY_CLOUD_NAME,
          },
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            onUploadProgress: ({ total, loaded }) => {
              this.loader.uploadTotal = total ?? null;
              this.loader.uploaded = loaded;

              const percentageCompleted = Math.round((loaded * 100) / total!);
              console.log("Upload Progress: ", percentageCompleted + "%");
            },
            signal: this.abortController.signal,
          }
        )
        .then(({ data }) => {
          if (!data) throw new Error(genericError);
          return resolve({ default: data.secure_url });
        })
        .catch(({ error }) => reject(error?.message ?? genericError));
    });
  }

  // // Aborts the upload process.
  abort() {
    this.abortController.abort();
  }
}

export const ImagesUploadAdapterPlugin: PluginConstructor = function (editor) {
  editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
    return new CustomUploadAdapter(loader);
  };
};
