import { PropsWithChildren, ReactNode } from "react";

import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import { Box, BoxProps, Divider, IconButton, Tooltip, Typography } from "@mui/material";

import { PaperCustom } from "./PaperCustom";

type PaperContentSectionProps = {
  title?: string;
  titleTooltip?: string;
  topSection?: boolean;
  action?: {
    onAction: () => void;
    title: string;
    icon: JSX.Element;
    disabled?: boolean;
  };
  customElement?: ReactNode;
} & Pick<BoxProps, "sx" | "ref">;

export const PaperContentSection = ({
  children,
  title,
  titleTooltip,
  topSection = false,
  sx,
  action,
  customElement,
  ref,
}: PropsWithChildren<PaperContentSectionProps>) => {
  return (
    <PaperCustom sx={sx} mt={topSection ? 0 : 3} ref={ref as any}>
      {title ? (
        <>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            textAlign={"center"}
            alignItems={"center"}>
            <Typography variant="body1" fontWeight={"bold"} display="flex" alignItems="center">
              {title}
              {titleTooltip ? (
                <Tooltip title={titleTooltip}>
                  <HelpOutlineOutlinedIcon fontSize={"inherit"} color="primary" />
                </Tooltip>
              ) : null}
            </Typography>
            {action ? (
              <Tooltip title={action.title} placement="top">
                <span>
                  <IconButton
                    color="primary"
                    sx={{ padding: 0 }}
                    onClick={action.onAction}
                    disabled={action.disabled}>
                    {action.icon}
                  </IconButton>
                </span>
              </Tooltip>
            ) : null}
            {customElement ?? null}
          </Box>
          <Divider
            sx={{
              margin: "8px 0",
            }}
          />
        </>
      ) : null}
      {children}
    </PaperCustom>
  );
};
