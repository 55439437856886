import { Controller, ControllerProps, FieldPath, FieldValues } from "react-hook-form";

import { Box, FormControl, FormHelperText, RadioGroup, RadioGroupProps } from "@mui/material";
import InputLabel from "src/components/InputLabel";
import RadioHF, { RadioHFProps } from "src/components/rhf/RadioHF";

export interface RadioGroupHFProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
> extends Omit<RadioGroupProps, "name">,
    Pick<ControllerProps<TFieldValues, TName>, "control" | "name" | "rules"> {
  options: Array<Pick<RadioHFProps, "value" | "label" | "description" | "name" | "disabled">>;
  size?: RadioHFProps["size"];
  sxRadio?: RadioHFProps["sx"];
  disabled?: boolean;
  labelOverride?: string;
  required?: boolean;
}

const RadioGroupHF = <T extends FieldValues, TName extends FieldPath<T> = FieldPath<T>>({
  control,
  name,
  rules,
  onChange: handleOnChange,
  defaultValue = false,
  options,
  disabled = false,
  size = "small",
  sxRadio,
  labelOverride,
  required = true,
  ...rest
}: RadioGroupHFProps<T, TName>) => {
  return (
    <>
      {labelOverride ? (
        <InputLabel>
          {labelOverride}
          {required ? "*" : null}
        </InputLabel>
      ) : null}
      <FormControl variant="outlined" fullWidth size={size} disabled={disabled}>
        <Controller
          control={control}
          name={name}
          rules={rules}
          defaultValue={defaultValue}
          render={({ field: { onChange, value }, fieldState: { error: fieldError } }) => (
            <Box>
              <RadioGroup
                onChange={(event, selected) => {
                  onChange(event);
                  handleOnChange && handleOnChange(event, selected);
                }}
                row
                defaultValue={`${defaultValue}`}
                value={value}
                {...rest}>
                {options.map((option, index) => (
                  <RadioHF key={index} {...option} />
                ))}
              </RadioGroup>
              {fieldError && fieldError.message ? (
                <FormHelperText variant="standard" error={true}>
                  {fieldError.message}
                </FormHelperText>
              ) : null}
            </Box>
          )}
        />
      </FormControl>
    </>
  );
};

export default RadioGroupHF;
