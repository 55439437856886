import { useState } from "react";

import { Image, UploadFile } from "antd";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ProductVariantForm } from "src/shared/types/forms";
import { reduceUrlImage } from "src/shared/utils/common";

import { Box, Grid, Stack, Typography } from "@mui/material";
import ActionButtonSection from "src/components/ActionButtonSection";
import PageWrapper from "src/components/PageWrapper";
import { PaperContentSection } from "src/components/PaperContentSection";
import { PaperCustom } from "src/components/PaperCustom";
import PictureWallInput from "src/components/PictureWallInput";
import TextFieldHF from "src/components/rhf/TextFieldHF";

import useCreateProductVariant from "src/hooks/useCreateProductVariant";
import useProductDetail from "src/hooks/useProductDetail";
import useShowOverlayLoading from "src/hooks/useShowOverlayLoading";
import useUploadImage from "src/hooks/useUploadImage";

const CreateProductVariantPage = () => {
  const { id: productId } = useParams();
  const navigate = useNavigate();
  const showLoading = useShowOverlayLoading();

  const { mutate: onCreate } = useCreateProductVariant({
    productId: productId,
    onSuccess: () => {
      showLoading(false);
      navigate(`/products/${productId}/edit`);
    },
  });
  const { mutateAsync: onAsyncUploadImage } = useUploadImage();

  const { productDetail } = useProductDetail({
    id: productId,
    onSuccess: ({ variants }) => {
      const variantTemplateForCreateForm = variants.at(0);

      if (variantTemplateForCreateForm) {
        const { attributes } = variantTemplateForCreateForm;

        setValue(
          "attribute_values",
          attributes.map(({ id }) => ({
            attribute_id: id,
            value: "",
          }))
        );
      }
    },
  });

  const { control, setValue, handleSubmit } = useForm<ProductVariantForm>({
    defaultValues: {
      attribute_values: [],
      barcode: "",
      sku: "",
      cost_price: 0,
      retail_price: 0,
      stock_quantity: 0,
    },
  });

  const [productImageList, setProductImageList] = useState<UploadFile[]>([]);

  const templateVariant = productDetail?.variants.at(0);

  const onSave = async (data: ProductVariantForm) => {
    showLoading(true);
    if (productImageList.length) {
      if (!productImageList[0].originFileObj) return;

      onAsyncUploadImage(productImageList[0].originFileObj).then((res) => {
        const newImage = { url: res.data.secure_url };

        onCreate({ ...data, image: newImage });
      });
    } else {
      onCreate({ ...data });
    }
  };

  return (
    <PageWrapper
      title={"Tạo biến thể"}
      actionArea={
        <ActionButtonSection
          actionOnClick={handleSubmit(onSave)}
          backTo={`/products/${productId}/edit`}
        />
      }>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6} lg={4}>
          <PaperCustom flexDirection={"row"} alignItems={"center"}>
            <Image
              width={100}
              wrapperStyle={{ marginRight: 10 }}
              src={
                productDetail?.images !== null && productDetail?.images[0]
                  ? reduceUrlImage({
                      originUrl: productDetail?.images[0]?.url,
                      quality: 10,
                      width: 100,
                    })
                  : "/images/nothing-image.png"
              }
            />
            <Stack justifyItems={"center"}>
              <Typography variant="body2" fontWeight={"bold"}>
                {productDetail?.name}
              </Typography>
              <Typography variant="caption" fontStyle={"italic"}>
                {productDetail?.variants.length} biến thể
              </Typography>
              <Link
                to={`/products/${productId}/edit`}
                style={{
                  color: "#2962ff",
                  textDecoration: "none",
                }}>
                <Typography variant="caption">Quay về chi tiết sản phẩm</Typography>
              </Link>
            </Stack>
          </PaperCustom>
          {productDetail && productDetail?.variants.length > 0 ? (
            <Box
              bgcolor={"white"}
              boxShadow={"0 2px 4px rgb(0 0 0 / 10%)"}
              borderRadius={"4px"}
              mt={3}>
              <Box p={1} borderBottom={(theme) => `1px solid ${theme.palette.divider}`}>
                <Typography variant="body2" fontWeight={"bold"}>
                  Biến thể khác
                </Typography>
              </Box>

              <Stack maxHeight={350} overflow={"auto"}>
                {productDetail?.variants.map((otherProductVariant) => {
                  return (
                    <Box
                      key={otherProductVariant.id}
                      display={"flex"}
                      p={1}
                      borderBottom={(theme) => `1px solid ${theme.palette.divider}`}>
                      <Image
                        width={70}
                        wrapperStyle={{ marginRight: 10 }}
                        src={reduceUrlImage({
                          originUrl: otherProductVariant.image?.url,
                          quality: 10,
                          width: 70,
                        })}
                      />
                      <Stack justifyItems={"center"}>
                        <Typography variant="body2" fontWeight={"bold"}>
                          {otherProductVariant.attributes.map((opa) => opa.value).join(" / ")}
                        </Typography>
                        <Typography variant="caption">
                          {otherProductVariant.stock_quantity}
                        </Typography>
                        <Link
                          to={`/products/${productId}/variants/${otherProductVariant.id}/edit`}
                          style={{
                            color: "#2962ff",
                            textDecoration: "none",
                          }}>
                          <Typography variant="caption">Tới Chi tiết biến thể</Typography>
                        </Link>
                      </Stack>
                    </Box>
                  );
                })}
              </Stack>
            </Box>
          ) : null}
        </Grid>
        <Grid item xs={12} md={6} lg={8}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={6}>
              <PaperContentSection
                title="Thông tin thuộc tính biến thể"
                sx={{ height: "100%" }}
                topSection>
                <Grid container spacing={2} p={2}>
                  {templateVariant?.attributes.map((variantAttribute, index) => {
                    return (
                      <Grid item xs={12} key={variantAttribute.id}>
                        <TextFieldHF
                          control={control}
                          fullWidth
                          name={`attribute_values.${index}.value`}
                          labelOverride={variantAttribute.name}
                          placeholder={variantAttribute.name}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              </PaperContentSection>
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <PaperContentSection title="Hình ảnh biến thể" sx={{ height: "100%" }} topSection>
                <Grid container spacing={2} p={2}>
                  <Grid item xs={12}>
                    <PictureWallInput
                      fileList={productImageList}
                      updateFileList={(files) => setProductImageList(files)}
                      limitFile={1}
                    />
                  </Grid>
                </Grid>
              </PaperContentSection>
            </Grid>
          </Grid>

          <PaperContentSection title="Chi tiết biến thể">
            <Grid container spacing={2} p={2}>
              <Grid item xs={6}>
                <TextFieldHF
                  control={control}
                  fullWidth
                  name={"retail_price"}
                  labelOverride={"Giá bán"}
                  type="number"
                  inputProps={{ min: 0 }}
                  placeholder={"Giá bán"}
                />
              </Grid>
              <Grid item xs={6}>
                <TextFieldHF
                  control={control}
                  fullWidth
                  name={"cost_price"}
                  labelOverride={"Giá trần"}
                  type="number"
                  inputProps={{ min: 0 }}
                  placeholder={"Giá trần"}
                />
              </Grid>
              <Grid item xs={4}>
                <TextFieldHF
                  control={control}
                  fullWidth
                  name={"barcode"}
                  labelOverride={"Barcode"}
                  placeholder={"Barcode"}
                />
              </Grid>
              <Grid item xs={4}>
                <TextFieldHF
                  control={control}
                  fullWidth
                  name={"sku"}
                  labelOverride={"SKU"}
                  placeholder={"SKU"}
                />
              </Grid>
              <Grid item xs={4}>
                <TextFieldHF<ProductVariantForm>
                  control={control}
                  fullWidth
                  name={"stock_quantity"}
                  type="number"
                  labelOverride={"Số lượng"}
                  placeholder={"Số lượng"}
                  inputProps={{ min: 0 }}
                />
              </Grid>
            </Grid>
          </PaperContentSection>
        </Grid>
      </Grid>
    </PageWrapper>
  );
};

export default CreateProductVariantPage;
