import { Fragment, useState } from "react";

import dayjs from "dayjs";
import { DefaultValues, useForm, UseFormReturn, useWatch } from "react-hook-form";
import { NumberFormatValues } from "react-number-format";
import { useParams } from "react-router-dom";
import { FormMode } from "src/shared/constants/common";
import { RoutePage } from "src/shared/constants/route";
import { VoucherForm } from "src/shared/types/forms";

import { Checkbox, Grid, InputAdornment, Stack, Typography } from "@mui/material";
import ActionButtonSection from "src/components/ActionButtonSection";
import NumericFormatInput from "src/components/common/NumericFormatInput";
import PageWrapper from "src/components/PageWrapper";
import { PaperContentSection } from "src/components/PaperContentSection";
import AutocompleteHF from "src/components/rhf/AutocompleteHF";
import DatePickerHF from "src/components/rhf/DatePickerHF";
import TextFieldHF from "src/components/rhf/TextFieldHF";

import { UseMutationResult } from "@tanstack/react-query";
import MuiPickersUtilsProvider from "src/contexts/MuiPickersUtilsProvider";
import useShowOverlayLoading from "src/hooks/useShowOverlayLoading";

interface VoucherUpsertFormProps {
  mode?: FormMode;
  defaultValues?: DefaultValues<VoucherForm>;
  onSave: UseMutationResult<unknown, unknown, VoucherForm>["mutate"];
}

const VoucherTimeRangePicker = ({ control, resetField, getValues }: UseFormReturn<VoucherForm>) => {
  const [checkedAvailableDateUsage, setCheckedAvailableDateUsage] = useState<boolean>(
    Boolean(getValues("start_date")) && Boolean(getValues("end_date"))
  );

  const handleChangeAvailableDateUsage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckedAvailableDateUsage(event.target.checked);
    resetField("start_date", { defaultValue: null });
    resetField("end_date", { defaultValue: null });
  };

  const startDate = useWatch({ name: "start_date", control });

  return (
    <Fragment>
      <Grid item xs={12}>
        <Stack direction="row" alignItems="center">
          <Typography variant="body2" fontWeight={700}>
            Chọn thời gian voucher có hiệu lực, nếu bỏ trống voucher sẽ có hiệu lực vĩnh viễn
          </Typography>
          <Checkbox
            checked={checkedAvailableDateUsage}
            onChange={handleChangeAvailableDateUsage}
            inputProps={{ "aria-label": "controlled" }}
          />
        </Stack>
      </Grid>
      {checkedAvailableDateUsage ? (
        <MuiPickersUtilsProvider>
          <Grid item xs={6}>
            <DatePickerHF<VoucherForm, "start_date">
              control={control}
              name="start_date"
              rules={{
                required: {
                  value: true,
                  message: "Ngày áp dụng voucher không được để trống",
                },
                validate: {
                  muiValidate: (value) => {
                    if (value === null || typeof value === "string") return;

                    if (!value.isValid()) return "Ngày bạn chọn không hợp lệ";

                    if (value.isBefore(dayjs(), "day")) return "Ngày bạn chọn ở quá khứ";
                  },
                },
              }}
              labelOverride="Ngày áp dụng voucher"
              required
              minDate={dayjs()}
            />
          </Grid>
          <Grid item xs={6}>
            <DatePickerHF<VoucherForm, "end_date">
              control={control}
              name="end_date"
              rules={{
                required: {
                  value: true,
                  message: "Ngày kết thúc voucher không được để trống",
                },
                validate: {
                  muiValidate: (value) => {
                    if (value === null || typeof value === "string") return;

                    if (!value.isValid()) return "Ngày bạn chọn không hợp lệ";

                    if (value.isBefore(dayjs(), "day")) return "Ngày bạn chọn ở quá khứ";
                  },
                  checkValidEndDate: (endDate, { start_date: startDate }) => {
                    if (endDate === null || startDate === null) return;

                    if (!endDate.isValid() || !startDate.isValid()) return;

                    return (
                      endDate.isAfter(startDate, "day") ||
                      "Ngày kết thúc phải lớn hơn ngày áp dụng voucher"
                    );
                  },
                },
              }}
              referenceDate={startDate?.add(1, "day")}
              minDate={dayjs()}
              labelOverride="Ngày kết thúc voucher"
              required
            />
          </Grid>
        </MuiPickersUtilsProvider>
      ) : null}
    </Fragment>
  );
};

const VoucherUpsertForm = ({
  mode = FormMode.CREATE,
  defaultValues,
  onSave,
}: VoucherUpsertFormProps) => {
  const voucherDefaultValues: VoucherForm = {
    name: "",
    code: "",
    voucher_amount_type: "",
    voucher_amount_value: 0,
    description: "",
    is_active: true,
    start_date: null,
    end_date: null,
    max_uses: 1,
    min_order_final_price: null,
    max_uses_per_user: null,
  };

  const disabledFieldsUpdate: boolean = mode === FormMode.UPDATE;

  const voucherId = useParams<string>().id ?? "";

  const voucherFormMethod = useForm<VoucherForm>({
    defaultValues: defaultValues ?? voucherDefaultValues,
  });
  const { handleSubmit, control, getValues, resetField } = voucherFormMethod;

  const isPercentage = getValues("voucher_amount_type") === "PERCENTAGE";

  const showLoading = useShowOverlayLoading();

  return (
    <PageWrapper
      title={mode === "update" ? "Cập nhật voucher" : "Tạo voucher"}
      actionArea={
        <ActionButtonSection
          actionOnClick={handleSubmit((voucher) => {
            showLoading(true);

            if (voucher.start_date && voucher.end_date) {
              onSave({
                ...voucher,
                start_date: voucher.start_date.toISOString() as any,
                end_date: voucher.end_date.toISOString() as any,
              });
            } else {
              onSave(voucher);
            }
          })}
          backTo={
            mode === "update" ? `/${RoutePage.Voucher}/${voucherId}` : `/${RoutePage.Voucher}`
          }
        />
      }>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <PaperContentSection title="Thông tin chung" topSection>
            <Grid container spacing={2} p={2}>
              <Grid item xs={12}>
                <TextFieldHF<VoucherForm>
                  control={control}
                  name="name"
                  rules={{
                    required: {
                      value: true,
                      message: "Tên voucher không được để trống",
                    },
                  }}
                  labelOverride="Tên voucher"
                  placeholder="Ví dụ: Giảm giá Black Friday 2024"
                  required
                />
              </Grid>

              <Grid item xs={12}>
                <TextFieldHF<VoucherForm>
                  control={control}
                  name="code"
                  rules={{
                    required: {
                      value: true,
                      message: "Mã voucher không được để trống",
                    },
                  }}
                  labelOverride="Mã voucher"
                  placeholder="Ví dụ: VAC100"
                  disabled={disabledFieldsUpdate}
                  required
                />
              </Grid>

              <Grid item xs={12}>
                <AutocompleteHF<VoucherForm>
                  formControlProps={{
                    control,
                    name: "voucher_amount_type",
                    rules: {
                      required: {
                        value: true,
                        message: "Loại voucher không được để trống",
                      },
                    },
                  }}
                  labelOverride="Loại voucher"
                  placeholder="Loại voucher"
                  options={Array.of<{
                    label: string;
                    value: VoucherForm["voucher_amount_type"];
                  }>(
                    {
                      label: "Tỷ lệ phần trăm",
                      value: "PERCENTAGE",
                    },
                    {
                      label: "Số tiền cố định",
                      value: "FIXED_AMOUNT",
                    }
                  )}
                  getOptionDisabled={({ value }) => value === getValues("voucher_amount_type")}
                  onChangeAdditional={() => resetField("voucher_amount_value")}
                  disabled={disabledFieldsUpdate}
                  required
                />
              </Grid>

              <Grid item xs={12}>
                <TextFieldHF<VoucherForm, "voucher_amount_value">
                  control={control}
                  name="voucher_amount_value"
                  rules={{
                    required: {
                      value: true,
                      message: "Giá trị voucher không được để trống",
                    },
                  }}
                  InputProps={{
                    inputComponent: NumericFormatInput as any,
                    endAdornment: (
                      <InputAdornment position="end">
                        {isPercentage ? "%" : <span>&#8363;</span>}
                      </InputAdornment>
                    ),
                  }}
                  inputProps={{
                    isAllowed: (values: NumberFormatValues) => {
                      const maxPercentage = 100;
                      return !isPercentage || Number(values.floatValue) <= maxPercentage;
                    },
                  }}
                  typeFormattedOverride="number"
                  labelOverride="Giá trị voucher"
                  placeholder="Giá trị voucher"
                  disabled={disabledFieldsUpdate || getValues("voucher_amount_type") === ""}
                  required
                />
              </Grid>

              <Grid item xs={12}>
                <TextFieldHF<VoucherForm>
                  control={control}
                  name="max_uses"
                  rules={{
                    required: {
                      value: true,
                      message: "Số lần sử dụng voucher không được để trống",
                    },
                  }}
                  InputProps={{
                    inputComponent: NumericFormatInput as any,
                  }}
                  typeFormattedOverride="number"
                  labelOverride="Số lần sử dụng voucher"
                  required
                />
              </Grid>
              <VoucherTimeRangePicker {...voucherFormMethod} />
            </Grid>
          </PaperContentSection>
        </Grid>
      </Grid>
    </PageWrapper>
  );
};

export default VoucherUpsertForm;
