import { useNavigate } from "react-router-dom";
import { RoutePage } from "src/shared/constants/route";
import { VoucherForm } from "src/shared/types/forms";
import { putData } from "src/shared/utils/service";

import { useMutation } from "@tanstack/react-query";
import useShowOverlayLoading from "src/hooks/useShowOverlayLoading";
import useShowSnackbar from "src/hooks/useShowSnackbar";

type UseUpdateVoucherProps = {
  voucherId: string;
};
const useUpdateVoucher = ({ voucherId }: UseUpdateVoucherProps) => {
  const navigate = useNavigate();
  const showLoading = useShowOverlayLoading();
  const showSnackbar = useShowSnackbar();

  return useMutation<unknown, unknown, VoucherForm>({
    mutationKey: ["update-voucher"],
    mutationFn: (data) => putData("/vouchers/with-admin", data, voucherId),
    onSuccess: () => {
      showSnackbar("Cập nhật voucher thành công!", "success");
      showLoading(false);
      navigate(`/${RoutePage.Voucher}/${voucherId}`);
    },
    onError: () => {
      showLoading(false);
      showSnackbar("Cập nhật voucher thất bại!", "error");
    },
  });
};

export default useUpdateVoucher;
