import { useParams } from "react-router-dom";
import { FormMode } from "src/shared/constants/common";

import PostUpsertForm from "src/components/PostUpsertForm";

import { useDeletePost } from "src/hooks/useDeletePost";
import usePostDetail from "src/hooks/usePostDetail";
import useUpdatePost from "src/hooks/useUpdatePost";

const EditPostPage = () => {
  const postId = useParams().id ?? "";

  const { data: postDetail, isFetching } = usePostDetail({ postId });

  const { mutate: onUpdatePost } = useUpdatePost({ postId });

  const { mutate: onDelete } = useDeletePost();

  if (isFetching || !postDetail) return <></>;

  const { title, topic_image, description, content, author, seq_number, is_visible, type } =
    postDetail;

  return (
    <PostUpsertForm
      mode={FormMode.UPDATE}
      defaultValues={{
        title,
        description,
        content,
        author,
        seq_number,
        topic_image_url: topic_image?.url,
        is_visible,
        type,
      }}
      onSave={onUpdatePost}
      onDelete={() => onDelete(postId)}
    />
  );
};

export default EditPostPage;
