import { useState } from "react";

import { DefaultValues, useController, useForm } from "react-hook-form";
import { FormMode, PostType } from "src/shared/constants/common";
import { RoutePage } from "src/shared/constants/route";
import { mutatePostValidateSchema } from "src/shared/types/form-validations";
import { PostForm } from "src/shared/types/forms";
import { isString } from "src/shared/utils/common";

import { Button, FormControl, FormHelperText, Grid, Stack } from "@mui/material";
import { Box } from "@mui/system";
import ActionButtonSection from "src/components/ActionButtonSection";
import ConfirmedDialog from "src/components/dialogs/ConfirmedDialog";
import ImageInputSection from "src/components/ImageInput";
import InputLabel from "src/components/InputLabel";
import PageWrapper from "src/components/PageWrapper";
import { PaperContentSection } from "src/components/PaperContentSection";
import CheckBoxHF from "src/components/rhf/CheckboxHF";
import RadioGroupHF from "src/components/rhf/RadioGroupHF";
import TextFieldHF from "src/components/rhf/TextFieldHF";

import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { joiResolver } from "@hookform/resolvers/joi";
import { UseMutationResult } from "@tanstack/react-query";
import useShowOverlayLoading from "src/hooks/useShowOverlayLoading";
import useUploadImage from "src/hooks/useUploadImage";
import { ImagesUploadAdapterPlugin } from "src/plugins/cloudinary-upload/cloudinaryUpload";

interface PostUpsertFormProps {
  mode?: FormMode;
  defaultValues?: DefaultValues<PostForm>;
  onSave: UseMutationResult<unknown, unknown, PostForm>["mutate"];
  onDelete?: () => void;
}

const PostUpsertForm = ({
  mode = FormMode.CREATE,
  defaultValues,
  onSave,
  onDelete,
}: PostUpsertFormProps) => {
  const defaultPostValues: DefaultValues<PostForm> = {
    title: "",
    topic_image_url: "",
    description: "",
    content: "",
    author: "",
    seq_number: 0,
    is_visible: true,
    type: PostType.DAILY_UPDATE,
  };

  const {
    control,
    formState: { errors },
    setValue,
    resetField,
    handleSubmit,
  } = useForm<PostForm>({
    defaultValues: defaultValues ?? defaultPostValues,
    resolver: joiResolver(mutatePostValidateSchema),
  });

  const { mutateAsync: onAsyncUploadImage } = useUploadImage();

  const showLoading = useShowOverlayLoading();

  const {
    field: { onChange: onChangeImageFile },
  } = useController({ name: "topic_image_url", control });

  const postOptions: Array<{ value: PostType; label: string }> = [
    { value: PostType.DAILY_UPDATE, label: PostType.DAILY_UPDATE },
    { value: PostType.EDITORIAL, label: PostType.EDITORIAL },
    { value: PostType.MIX_MATCH, label: PostType.MIX_MATCH },
  ];

  const [isShowConfirmedDialog, setIsShowConfirmedDialog] = useState<boolean>(false);
  const handleCollectionDeleteConfirmedDialog = (isAccept: boolean) => {
    setIsShowConfirmedDialog(false);

    if (isAccept) {
      onDelete && onDelete();
    }
  };

  return (
    <PageWrapper
      title={mode === FormMode.UPDATE ? "Cập nhật bài viết" : "Tạo bài viết"}
      actionArea={
        <ActionButtonSection
          actionOnClick={handleSubmit(async (post) => {
            showLoading(true);

            const topicImage = await onAsyncUploadImage(post.topic_image_url as File);
            onSave({ ...post, topic_image_url: topicImage.data.secure_url });
          })}
          backTo={{ pathname: `/${RoutePage.Post}` }}
        />
      }>
      <Grid container spacing={3}>
        <Grid item md={12} lg={8}>
          <PaperContentSection title="Thông tin chung" topSection>
            <Grid container spacing={2} p={2}>
              <Grid item xs={12}>
                <InputLabel>Loại bài viết*</InputLabel>
                <RadioGroupHF
                  control={control}
                  name="type"
                  defaultValue={PostType.DAILY_UPDATE}
                  options={postOptions}
                  onChange={() => {
                    resetField("content");
                  }}
                  disabled={mode === FormMode.UPDATE}
                />
              </Grid>
              <Grid item xs={12}>
                <TextFieldHF<PostForm>
                  control={control}
                  name="title"
                  labelOverride="Tiêu đề"
                  placeholder="Tiêu đề"
                  required
                />
              </Grid>

              <Grid item xs={12}>
                <TextFieldHF<PostForm>
                  control={control}
                  name="description"
                  labelOverride="Miêu tả"
                  placeholder="Miêu tả"
                  required
                />
              </Grid>

              <Grid item xs={12} width={"100%"}>
                <InputLabel>Nội dung*</InputLabel>
                <>
                  <CKEditor
                    config={{
                      language: "vi",
                      extraPlugins: [ImagesUploadAdapterPlugin],
                    }}
                    data={defaultValues?.content}
                    editor={ClassicEditor}
                    onChange={(_, editor) => {
                      const data = editor.getData();
                      setValue("content", data, { shouldValidate: true });
                    }}
                  />
                  <FormControl error={Boolean(errors.content)} variant="outlined">
                    {errors.content?.message ? (
                      <FormHelperText id="image-error-text">
                        {errors.content.message}
                      </FormHelperText>
                    ) : null}
                  </FormControl>
                </>
              </Grid>

              <Grid item xs={12}>
                <TextFieldHF<PostForm>
                  control={control}
                  name="author"
                  labelOverride="Tác giả"
                  placeholder="Tác giả"
                  required
                />
              </Grid>
            </Grid>
          </PaperContentSection>
        </Grid>
        <Grid item md={12} lg={4} width={"100%"}>
          <Stack spacing={2}>
            {mode === FormMode.UPDATE ? (
              <PaperContentSection title="Trạng thái" topSection>
                <Grid item xs={6}>
                  <CheckBoxHF<PostForm>
                    control={control}
                    name="is_visible"
                    label="Hiển thị bài viết"
                  />
                </Grid>
              </PaperContentSection>
            ) : null}
            <PaperContentSection title="Ảnh chủ đề" topSection>
              <FormControl error={Boolean(errors.topic_image_url)} variant="outlined">
                <ImageInputSection
                  setImage={onChangeImageFile}
                  defaultSrc={
                    isString(defaultValues?.topic_image_url)
                      ? defaultValues?.topic_image_url
                      : undefined
                  }
                  alt="topic_image_url"
                  id="topic_image_url"
                />
                {errors.topic_image_url?.message ? (
                  <FormHelperText id="image-error-text">
                    {errors.topic_image_url.message}
                  </FormHelperText>
                ) : null}
              </FormControl>
            </PaperContentSection>
            {mode === FormMode.UPDATE ? (
              <Box width={"100%"} textAlign={"right"}>
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => setIsShowConfirmedDialog(true)}>
                  Xóa bài viết
                </Button>
              </Box>
            ) : null}
          </Stack>
        </Grid>
      </Grid>

      <ConfirmedDialog
        show={isShowConfirmedDialog}
        onClose={handleCollectionDeleteConfirmedDialog}
      />
    </PageWrapper>
  );
};

export default PostUpsertForm;
