import { useMemo } from "react";

import { Link } from "react-router-dom";
import { RoutePage } from "src/shared/constants/route";
import { getCustomerName } from "src/shared/helpers/customer";
import { getOrderPrefix, getOrderTotalPrice } from "src/shared/helpers/order";
import { Pagination } from "src/shared/types/paging";
import { OrderResponse } from "src/shared/types/services";
import { convertToDate } from "src/shared/utils/common";

import { Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import OrderStatusChip from "src/components/common/chips/OrderStatusChip";
import CustomDataGrid from "src/components/common/CustomDataGrid";

const columns: GridColDef[] = [
  {
    field: "id",
    headerName: "Mã đơn hàng",
    renderCell: (params) => {
      const { id: order_id, sequence_number } = params.row;

      return (
        <Typography variant="body2">
          <Link
            to={`/${RoutePage.Order}/${order_id}`}
            style={{
              color: "#2962ff",
              textDecoration: "none",
            }}>
            {getOrderPrefix(sequence_number)}
          </Link>
        </Typography>
      );
    },
    flex: 1,
  },
  {
    field: "customer",
    headerName: "Khách hàng",
    valueGetter: (value) => {
      const customer = value;
      return getCustomerName(customer);
    },
    flex: 1,
  },
  {
    field: "status",
    headerName: "Trạng thái đơn hàng",
    renderCell: (params) => {
      const { status } = params.row;
      return <OrderStatusChip orderStatus={status} />;
    },
    flex: 1,
  },
  {
    field: "bill_items",
    headerName: "Tổng tiền",
    valueGetter: (value) => {
      const bill_items = value;
      return getOrderTotalPrice(bill_items || []);
    },
    flex: 1,
  },
  {
    field: "created_at",
    headerName: "Ngày tạo",
    valueGetter: (value) => {
      const created_at = value;
      return convertToDate(created_at);
    },
    flex: 1,
  },
];

interface OrderTableProps {
  orders?: Array<OrderResponse>;
  isLoading: boolean;
  pagination: Pagination;
}

export const OrderTable = ({ orders, isLoading, pagination }: OrderTableProps) => {
  const orderRows = useMemo(
    () =>
      (orders ?? []).map(
        ({ order_id, sequence_number, customer, status, bill_items, created_at }) => ({
          id: order_id,
          sequence_number,
          customer,
          status,
          bill_items,
          created_at,
        })
      ),
    [orders]
  );

  return (
    <CustomDataGrid
      rows={orderRows}
      columns={columns}
      loading={isLoading}
      customPaginationProps={pagination}
    />
  );
};
