import { CollectionForm } from "src/shared/types/forms";
import { postData } from "src/shared/utils/service";

import { useMutation } from "@tanstack/react-query";
import useShowSnackbar from "src/hooks/useShowSnackbar";

const useCreateCollection = ({ onSuccess }: { onSuccess: (data: any) => void }) => {
  const showSnackbar = useShowSnackbar();

  return useMutation({
    mutationKey: ["create-collection"],
    mutationFn: (data: CollectionForm) => postData<CollectionForm>("/collections/with-admin", data),
    onSuccess(data) {
      showSnackbar("Tạo nhóm sản phẩm mới thành công!", "success");
      onSuccess(data.data);
    },
    onError() {
      showSnackbar("Tạo nhóm sản phẩm mới thất bại!", "error");
    },
  });
};

export default useCreateCollection;
