import PostUpsertForm from "src/components/PostUpsertForm";

import useCreatePost from "src/hooks/useCreatePost";

const CreatePostPage = () => {
  const { mutate: onCreatePost } = useCreatePost();

  return <PostUpsertForm onSave={onCreatePost} />;
};

export default CreatePostPage;
