import { Fragment, ReactNode } from "react";

import { Link } from "react-router-dom";
import { RoutePage } from "src/shared/constants/route";
import { BillItemType } from "src/shared/types/helpers";
import { OrderResponse } from "src/shared/types/services";
import { numberToCurrency } from "src/shared/utils/common";
import { ArrayElement, GetKeyByValueType } from "src/shared/utils/types";

import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Typography, { TypographyProps } from "@mui/material/Typography";
import Box from "@mui/system/Box/Box";
import Stack from "@mui/system/Stack/Stack";
import { PaperContentSection } from "src/components/PaperContentSection";

const renderBillItemInfo = ({
  title,
  value,
  valueProps,
}: {
  title: string;
  value: ReactNode;
  valueProps?: TypographyProps;
}) => {
  return (
    <Grid item xs={12} display="flex" justifyContent="space-between">
      <Typography variant="body2">{title}</Typography>
      <Typography variant="body2" fontWeight="bold" {...valueProps}>
        {value}
      </Typography>
    </Grid>
  );
};

const getTotal =
  <T extends ArrayElement<BillItemsSectionProps["billItems"]>>(key: GetKeyByValueType<T>) =>
  (acc: number, billItem: T) =>
    (acc += Number(billItem[key]));

export const filterBillItemByType =
  (billItems: BillItemsSectionProps["billItems"]) => (type: BillItemType) =>
    billItems.filter((billItem) => billItem.type === type);

interface BillItemsSectionProps {
  billItems: OrderResponse["bill_items"];
  status: OrderResponse["status"];
}

export const BillItemsSection = ({ billItems, status }: BillItemsSectionProps) => {
  const withBillItemType = filterBillItemByType(billItems);

  const productBillItems = withBillItemType("PRODUCT_BILL");
  const productsQuantity: number = productBillItems.reduce(getTotal("quantity"), 0);
  const productsPrice: number = productBillItems.reduce(getTotal("final_price"), 0);

  const voucherBillItems = withBillItemType("VOUCHER_BILL");
  const vouchersPrice: number = voucherBillItems.reduce(getTotal("final_price"), 0);
  const voucherInfo = voucherBillItems.at(0);

  const shippingBillItems = withBillItemType("SHIPPING_BILL");
  const shippingPrice: number = shippingBillItems.reduce(getTotal("final_price"), 0);

  const extraFeeBillItems = withBillItemType("EXTRA_FEE_BILL");
  const extraFeePrice: number = extraFeeBillItems.reduce(getTotal("final_price"), 0);

  const totalOrderPrice: number = productsPrice + shippingPrice + extraFeePrice - vouchersPrice;

  const billItemInfos: Array<Parameters<typeof renderBillItemInfo>[0]> = [
    { title: "Số lượng sản phẩm", value: productsQuantity },
    { title: "Tổng tiền sản phẩm", value: numberToCurrency(productsPrice) },
    { title: "Giảm giá", value: `- ${numberToCurrency(vouchersPrice)}` },
    { title: "Phí vận chuyển", value: numberToCurrency(shippingPrice) },
    { title: "Phụ thu (etc: VAT, ...)", value: numberToCurrency(extraFeePrice) },
    {
      title: "Tổng giá trị đơn hàng",
      value: numberToCurrency(totalOrderPrice),
      valueProps: { color: "red" },
    },
    {
      title: "Đã thanh toán",
      value: status === "INVOICED" ? numberToCurrency(totalOrderPrice) : 0,
    },
  ];
  return (
    <PaperContentSection title="Hoá đơn thanh toán">
      <Stack spacing={2} m={1}>
        {voucherInfo ? (
          <>
            <Box display="flex" alignItems="center">
              <Typography variant="body2" fontWeight="bold">
                Voucher áp dụng:
              </Typography>
              <Typography variant="body2" ml={1}>
                <Link
                  to={`/${RoutePage.Voucher}/${voucherInfo.voucher_id}`}
                  style={{
                    color: "#2962ff",
                    textDecoration: "none",
                  }}>
                  {voucherInfo.voucher_name}
                </Link>
              </Typography>
            </Box>
            <Divider />
          </>
        ) : null}
        <Grid display="flex" columnGap={2}>
          <Grid item xs={6}></Grid>
          <Grid container item xs={6} rowGap={2}>
            {billItemInfos.map((billItem, index) => (
              <Fragment key={index}>{renderBillItemInfo(billItem)}</Fragment>
            ))}
          </Grid>
        </Grid>
      </Stack>
    </PaperContentSection>
  );
};
