import { Chip, chipClasses, ChipProps, ChipTypeMap } from "@mui/material";
import { blue, green, grey, lightBlue, orange, purple, red } from "@mui/material/colors";
import { DefaultComponentProps, OverridableComponent } from "@mui/material/OverridableComponent";

export interface MChipBaseProps extends DefaultComponentProps<ChipTypeMap<ChipProps, "div">> {}

const styleForBackgroundAvatar = (color: MChipBaseProps["color"]) => ({
  [`& .${chipClasses.avatar}`]: {
    color: "#fff",
    ...((!color || color === "default") && {
      color: grey[700],
    }),
    ...(color === "error" && {
      backgroundColor: red[800],
    }),
    ...(color === "info" && {
      backgroundColor: lightBlue[900],
    }),
    ...(color === "success" && {
      backgroundColor: green[900],
    }),
    ...(color === "warning" && {
      backgroundColor: orange[900],
    }),
    ...(color === "primary" && {
      backgroundColor: blue[800],
    }),
    ...(color === "secondary" && {
      backgroundColor: purple[700],
    }),
  },
});

const ChipBase: OverridableComponent<ChipTypeMap<MChipBaseProps, "div">> = (
  props: MChipBaseProps
) => {
  const { color, sx } = props;
  const styleOverrides = [styleForBackgroundAvatar(color), ...(Array.isArray(sx) ? sx : [sx])];

  return <Chip {...props} sx={styleOverrides} />;
};

export default ChipBase;
