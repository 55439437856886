import { HTMLInputTypeAttribute } from "react";

import { Controller, ControllerProps, FieldPath, FieldValues } from "react-hook-form";

import { TextField, TextFieldProps } from "@mui/material";

import InputLabel from "../InputLabel";

interface TextFieldHFProps<TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>>
  extends Pick<ControllerProps<TFieldValues, TName>, "control" | "name" | "rules"> {
  labelOverride?: string;
  typeFormattedOverride?: HTMLInputTypeAttribute;
  onChangeOverride?: (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    onChange: (...event: any[]) => void
  ) => void;
}

const TextFieldHF = <T extends FieldValues, TName extends FieldPath<T> = FieldPath<T>>({
  control,
  name,
  rules,
  required,
  labelOverride,
  type,
  typeFormattedOverride,
  onChangeOverride,
  ...restTextFieldProps
}: TextFieldHFProps<T, TName> & TextFieldProps) => {
  return (
    <>
      {labelOverride ? (
        <InputLabel>
          {labelOverride}
          {required ? "*" : null}
        </InputLabel>
      ) : null}
      <Controller<T, TName>
        control={control}
        name={name}
        rules={rules}
        render={({ field: { onChange, ...restField }, fieldState: { error } }) => {
          return (
            <TextField
              required={required ? true : undefined}
              fullWidth
              size="small"
              autoComplete={name}
              onChange={(e) => {
                if (onChangeOverride) {
                  return onChangeOverride?.(e, onChange);
                }

                if (type === "number" || typeFormattedOverride === "number") {
                  onChange(parseInt(e.target.value || "0"));
                } else {
                  onChange(e);
                }
              }}
              error={Boolean(error)}
              helperText={error?.message}
              type={type}
              {...restField}
              {...restTextFieldProps}
            />
          );
        }}
      />
    </>
  );
};

export default TextFieldHF;
