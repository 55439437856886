import { ProductVariantForm } from "src/shared/types/forms";
import { postData } from "src/shared/utils/service";

import { useMutation } from "@tanstack/react-query";
import useShowSnackbar from "src/hooks/useShowSnackbar";

const useCreateProductVariant = ({
  productId,
  onSuccess,
}: {
  productId?: string;
  variantId?: string;
  onSuccess: (data: any) => void;
}) => {
  const showSnackbar = useShowSnackbar();

  return useMutation({
    mutationKey: ["create-product-variant"],
    mutationFn: (data: ProductVariantForm) =>
      postData<ProductVariantForm>(`/products/${productId}/variants/with-admin`, data),
    onSuccess(data) {
      showSnackbar("Tạo mới biến thể cho sản phẩm thành công!", "success");
      onSuccess(data.data);
    },
    onError() {
      showSnackbar("Tạo mới biến thể cho sản phẩm thất bại!", "error");
    },
  });
};

export default useCreateProductVariant;
