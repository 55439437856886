import { useCallback } from "react";

import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import { ProductStockStatusEnum } from "src/shared/constants/common";
import { ReturnPaging } from "src/shared/types/paging";
import {
  ProductPriceSort,
  ProductSearch,
  ProductSearchKey,
  ProductVisibility,
} from "src/shared/types/product-filter";

import usePaging from "src/hooks/usePaging";

export interface UseSearchURLProductsReturn {
  searchProducts: (params: Partial<ProductSearch>) => void;
  filter: ProductSearch;
  paging: ReturnPaging;
}

const useSearchURLProducts = (): UseSearchURLProductsReturn => {
  const { changePage, page, pageCount, onRowsPerPageChange, onPageChange } = usePaging();

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const paging: UseSearchURLProductsReturn["paging"] = {
    page,
    pageCount,
    onRowsPerPageChange,
    onPageChange,
  };

  const filter: UseSearchURLProductsReturn["filter"] = {
    search: searchParams.get(ProductSearchKey.SEARCH) || "",
    priceSort:
      (searchParams.get(ProductSearchKey.PRICE_SORT) as ProductPriceSort) || ProductPriceSort.ASC,
    status:
      (searchParams.get(ProductSearchKey.STATUS) as ProductStockStatusEnum) ||
      ProductStockStatusEnum.IN_STOCK,
    visible:
      (searchParams.get(ProductSearchKey.VISIBLE) as ProductVisibility) || ProductVisibility.ALL,
  };

  const searchProducts: UseSearchURLProductsReturn["searchProducts"] = useCallback(
    (params) => {
      // const isCollectionChanged = "collection" in params;

      // const newSearchParamsObj: Record<ProductSearchKey, string> = {
      //     collection: params.collection || "",
      //     search: "",
      //     priceSort: ProductPriceSort.ASC,
      //     status: ProductStatus.AVAILABLE,
      // };

      const searchParamsObj: Record<ProductSearchKey, string> = {
        // collection: searchParams.get(ProductSearchKey.COLLECTION) || "",
        search: searchParams.get(ProductSearchKey.SEARCH)?.trim() || "",
        priceSort: searchParams.get(ProductSearchKey.PRICE_SORT) || ProductPriceSort.ASC,
        status: searchParams.get(ProductSearchKey.STATUS) || ProductStockStatusEnum.IN_STOCK,
        visible: searchParams.get(ProductSearchKey.VISIBLE) || ProductVisibility.ALL,
        ...params,
      };

      changePage(0);

      navigate({
        pathname: "/products",
        search: `?${createSearchParams(searchParamsObj)}`,
      });
    },
    [navigate, searchParams, changePage]
  );

  return {
    searchProducts,
    filter,
    paging,
  };
};

export default useSearchURLProducts;
