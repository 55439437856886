import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ButtonLink from "src/components/ButtonLink";
import PageWrapper from "src/components/PageWrapper";
import { PaperCustom } from "src/components/PaperCustom";
import ProductFilterContainer from "src/components/ProductFilterContainer";
import ProductTable from "src/components/tables/ProductTable";

import useProducts from "src/hooks/useProducts";
import useSearchURLProducts from "src/hooks/useSearchURLProducts";

const ProductsPage = () => {
  const {
    filter,
    searchProducts,
    paging: { page, pageCount, onPageChange, onRowsPerPageChange },
  } = useSearchURLProducts();

  const { products, isLoading, total } = useProducts({
    ...filter,
    page: page,
    pageCount: pageCount,
  });

  return (
    <PageWrapper
      title="Danh sách sản phẩm"
      actionArea={
        <>
          <ButtonLink to={"/collections/create"} startIcon={<AddCircleOutlineIcon />}>
            Tạo nhóm sản phẩm
          </ButtonLink>
          <ButtonLink to={"/products/create"} startIcon={<AddCircleOutlineIcon />}>
            Tạo sản phẩm
          </ButtonLink>
        </>
      }>
      <PaperCustom>
        <ProductFilterContainer filter={filter} searchProducts={searchProducts} />
        <ProductTable
          products={products}
          isLoading={isLoading}
          pagination={{
            count: total,
            rowsPerPage: pageCount,
            page,
            onPageChange,
            onRowsPerPageChange,
          }}
        />
      </PaperCustom>
    </PageWrapper>
  );
};

export default ProductsPage;
