import { ComponentProps } from "react";

import { reduceUrlImage } from "src/shared/utils/common";
import { styled } from "styled-components";
import { Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import { Box } from "@mui/material";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

const Image = styled.img`
  width: 100%;
  height: auto;
  display: block;
  object-fit: cover;
`;

export const FullWithImage = (props: ComponentProps<"img">) => <Image {...props} loading="lazy" />;

const Carousel = ({ urlList }: { urlList: string[] }) => {
  return (
    <Box>
      <Swiper
        centeredSlides={false}
        slidesPerView={6}
        spaceBetween={0}
        loop={true}
        speed={2000}
        autoplay={{
          disableOnInteraction: false,
        }}
        modules={[Autoplay]}>
        {urlList.map((url, index) => (
          <SwiperSlide key={`img-${url}-${index}`}>
            <FullWithImage src={reduceUrlImage({ originUrl: url, width: 180, quality: 10 })} />
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
};

export default Carousel;
