import { HomePageResponse } from "src/shared/types/services";
import { getData } from "src/shared/utils/service";

import { useQuery } from "@tanstack/react-query";

const useFetchHomePageInfo = () => {
  const { data, isLoading, isFetching } = useQuery<HomePageResponse>({
    cacheTime: 5 * 60 * 1000,
    refetchOnWindowFocus: false,
    queryKey: ["home-page"],
    queryFn: () =>
      getData({
        path: "/home-page",
      }),
  });

  return {
    banners: data?.image_banner,
    carousels: data?.image_carousels || [],
    brandingContent: data?.scroll_branding_content || "",
    isLoading,
    isFetching,
  };
};

export default useFetchHomePageInfo;
