import { useContext } from "react";

import { Backdrop, CircularProgress } from "@mui/material";

import { GlobalContext } from "src/shared/Providers/GlobalProvider";

const OverlayLoading = () => {
  const { show } = useContext(GlobalContext);

  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={show["loading"]}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default OverlayLoading;
