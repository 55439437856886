import { PropsWithChildren } from "react";

import { Stack, StackProps } from "@mui/material";

export const PaperCustom = ({ children, ...boxProps }: PropsWithChildren<StackProps>) => {
  return (
    <Stack
      p={1}
      bgcolor={"white"}
      boxShadow={"0 2px 4px rgb(0 0 0 / 10%)"}
      borderRadius={"4px"}
      {...boxProps}>
      {children}
    </Stack>
  );
};
