import { useMemo } from "react";

import { Link } from "react-router-dom";
import { RoutePage } from "src/shared/constants/route";
import { Pagination } from "src/shared/types/paging";
import { VoucherResponse } from "src/shared/types/services";
import { getFormattedCoupon } from "src/shared/utils/common";

import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import VoucherTypeChip from "src/components/common/chips/VoucherTypeChip";
import CustomDataGrid from "src/components/common/CustomDataGrid";

const columns: GridColDef[] = [
  {
    field: "name",
    headerName: "Tên voucher",
    renderCell: (params) => {
      const { id, name } = params.row;

      return (
        <Typography variant="body2">
          <Link
            to={`/${RoutePage.Voucher}/${id}`}
            style={{
              color: "#2962ff",
              textDecoration: "none",
            }}>
            {name}
          </Link>
        </Typography>
      );
    },
    flex: 0.4,
  },
  {
    field: "code",
    headerName: "Mã voucher",
    renderCell: ({ value: code }) => {
      return (
        <Stack direction="row" alignItems="center">
          <Box>{code}</Box>
          <IconButton
            onClick={async () => {
              if (!navigator?.clipboard) {
                // console.warn("Clipboard not supported");
                return false;
              }

              try {
                await navigator.clipboard.writeText(code);
              } catch (error) {
                // console.error(error);
              }
            }}>
            <ContentCopyOutlinedIcon fontSize="small" />
          </IconButton>
        </Stack>
      );
    },
    flex: 0.2,
  },
  {
    field: "voucher_amount_type",
    headerName: "Loại voucher",
    renderCell: (params) => {
      const { voucher_amount_type, voucher_amount_value } = params.row;
      const { typeLabel } = getFormattedCoupon({
        amountType: voucher_amount_type,
        amountValue: voucher_amount_value,
      });

      return <VoucherTypeChip voucherLabel={typeLabel} />;
    },
    flex: 0.2,
  },
  {
    field: "voucher_amount_value",
    headerName: "Giá trị voucher",
    valueGetter: (_value, row) => {
      const { expectedValue } = getFormattedCoupon({
        amountType: row.voucher_amount_type,
        amountValue: row.voucher_amount_value,
      });
      return expectedValue;
    },
    flex: 0.2,
  },
];

interface VoucherTableProps {
  vouchers?: Array<VoucherResponse>;
  isLoading?: boolean;
  pagination: Pagination;
}

const VoucherTable = ({ vouchers, isLoading, pagination }: VoucherTableProps) => {
  const voucherRows = useMemo(
    () =>
      (vouchers ?? []).map(({ id, name, code, voucher_amount_type, voucher_amount_value }) => ({
        id,
        name,
        code,
        voucher_amount_type,
        voucher_amount_value,
      })),
    [vouchers]
  );

  return (
    <CustomDataGrid
      rows={voucherRows}
      columns={columns}
      loading={isLoading}
      customPaginationProps={pagination}
    />
  );
};

export default VoucherTable;
