import { OrderResponse } from "src/shared/types/services";
import { getData } from "src/shared/utils/service";

import { useQuery } from "@tanstack/react-query";

type UseOrderDetailProps = {
  orderId: string;
};
const useOrderDetail = ({ orderId }: UseOrderDetailProps) => {
  return useQuery<unknown, unknown, OrderResponse>({
    queryKey: ["order-detail", { orderId }],
    queryFn: () => getData({ path: "/orders/with-admin", slug: orderId, requiredAuth: true }),
    enabled: Boolean(orderId),
    staleTime: Infinity,
    cacheTime: 0,
  });
};

export default useOrderDetail;
