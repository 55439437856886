import { MappingVariantItem } from "src/shared/types/forms";
import { AttributeResponse } from "src/shared/types/services";
import { AttributeValuesField } from "src/shared/types/views";

export const generateVariants = (
  data: AttributeValuesField["fields"],
  attributes: AttributeResponse[]
): MappingVariantItem[] => {
  const transformedData: MappingVariantItem[] = [];

  function generateCombinationsHelper(
    currentCombination: MappingVariantItem,
    currentIndex: number
  ) {
    if (currentIndex === attributes.length) {
      transformedData.push({
        ...currentCombination,
        attributeMappingId: currentCombination.attribute_values
          .map((obj) => `${obj.attribute_id}_${obj.value}`)
          .join("+"),
        attribute_values: currentCombination.attribute_values.map((obj) => ({
          ...obj,
        })),
      });
      return;
    }

    const currentAttributeId = attributes[currentIndex].id;
    const attributeData = data.find((item) => item.attributeId === currentAttributeId);

    if (attributeData) {
      for (const value of attributeData.values) {
        currentCombination.attribute_values.push({
          attribute_id: currentAttributeId,
          value: value,
        });
        generateCombinationsHelper(currentCombination, currentIndex + 1);
        currentCombination.attribute_values.pop();
      }
    } else {
      // If the attribute is not found in the data, skip it
      generateCombinationsHelper(currentCombination, currentIndex + 1);
    }
  }

  generateCombinationsHelper(
    {
      attribute_values: [],
      isChecked: false,
      retail_price: 0,
      attributeMappingId: "",
      stock_quantity: 1,
    },
    0
  );
  return transformedData;
};
