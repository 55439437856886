import { useState } from "react";

import { useFormContext, useWatch } from "react-hook-form";
import { OrderForm } from "src/shared/types/forms";
import { QueryDataResponse, VoucherResponse } from "src/shared/types/services";
import { getData } from "src/shared/utils/service";

import { Box } from "@mui/material";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";

import { useQuery } from "@tanstack/react-query";
import useShowSnackbar from "src/hooks/useShowSnackbar";

const VoucherSection = () => {
  const [voucherInput, setVoucherInput] = useState("");

  const { setValue } = useFormContext<OrderForm>();

  const showSnackBar = useShowSnackbar();

  const params: {
    limit: number;
    offset: number;
    code: string;
  } = {
    limit: 0,
    offset: 0,
    code: voucherInput,
  };

  const { isFetching, refetch } = useQuery<QueryDataResponse<VoucherResponse>>({
    queryKey: [
      "vouchers-order",
      {
        ...params,
      },
    ],
    refetchOnWindowFocus: false,
    enabled: false,
    queryFn: () =>
      getData({
        path: "/vouchers/with-admin",
        config: {
          params,
        },
        requiredAuth: true,
      }),

    onSuccess: (data) => {
      const voucher = (data.items ?? []).at(0);

      if (!voucher) return showSnackBar("Voucher không tồn tại!", "error");

      if (voucher.max_uses === 0) return showSnackBar("Voucher đã hết lượt sử dụng!", "error");

      setValue("voucherField", voucher);
      setVoucherInput("");
    },
    onError: () => {
      showSnackBar("Áp dụng mã voucher thất bại!", "error");
    },
  });

  const voucherField = useWatch<OrderForm, "voucherField">({
    name: "voucherField",
  });

  return (
    <Box display="flex" alignItems="center">
      <TextField
        id="voucher-textfield-controlled"
        size="small"
        placeholder="Nhập mã voucher"
        value={voucherInput}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setVoucherInput(event.target.value);
        }}
        sx={{ flex: 1, marginRight: 1 }}
      />
      <Tooltip title="Bạn cần chọn ít nhất một sản phẩm để áp dụng voucher">
        <Box>
          <Button
            variant="contained"
            size="medium"
            disabled={!voucherInput || Boolean(voucherField) || isFetching}
            endIcon={isFetching ? <CircularProgress color="inherit" size={20} /> : null}
            onClick={() => refetch()}>
            Áp dụng
          </Button>
        </Box>
      </Tooltip>
    </Box>
  );
};

export default VoucherSection;
