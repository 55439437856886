import { Controller, FieldValues, UseControllerProps } from "react-hook-form";

import {
  Autocomplete,
  AutocompleteProps,
  MenuItem,
  TextField,
  TextFieldProps,
} from "@mui/material";

import InputLabel from "../InputLabel";

interface AutocompleteOption {
  label: string;
  value: string | number;
}

const AutocompleteHF = <
  TFieldValues extends FieldValues,
  Multiple extends boolean | undefined = false
>({
  options,
  formControlProps,
  required,
  labelOverride,
  onChangeAdditional,
  multiple,
  getOptionDisabled,
  disabled,
  disableClearable,
  fullWidth = true,
  noOptionsText,
  ...restAutocompleteProps
}: {
  formControlProps: UseControllerProps<TFieldValues>;
  labelOverride?: string;
  onChangeAdditional?: () => void;
  options: AutocompleteOption[];
} & Omit<TextFieldProps, "onChange"> &
  Pick<
    AutocompleteProps<AutocompleteOption, Multiple, true, false>,
    "multiple" | "getOptionDisabled" | "disableClearable" | "fullWidth" | "noOptionsText"
  >) => {
  return (
    <>
      {labelOverride ? (
        <InputLabel>
          {labelOverride}
          {required ? "*" : null}
        </InputLabel>
      ) : null}
      <Controller
        {...formControlProps}
        render={({
          field: { onChange, name, value, ...restControllerField },
          fieldState: { error },
        }) => {
          return (
            <Autocomplete<AutocompleteOption, Multiple, true, false>
              {...restControllerField}
              size="small"
              disableClearable={disableClearable}
              disablePortal
              fullWidth={fullWidth}
              multiple={multiple}
              options={options}
              disabled={disabled}
              // trick for not show warning when value === "", TODO: Should refactor this
              value={value || (null as any)}
              disableCloseOnSelect={multiple}
              getOptionDisabled={getOptionDisabled}
              noOptionsText={noOptionsText}
              //TODO: Should type correct this any
              isOptionEqualToValue={(option, value: any) => {
                if (typeof value === "string" && value) {
                  return option.value === value;
                }
                return value ? option.value === value.value : false;
              }}
              getOptionLabel={(option) => {
                if (typeof option === "string" && option) {
                  const optionById = options.find((o) => o.value === option);
                  if (optionById) {
                    return optionById.label;
                  } else {
                    return "";
                  }
                }

                return option ? option.label : option;
              }}
              renderOption={(props, option) => (
                <MenuItem {...props} key={option.value}>
                  {option.label}
                </MenuItem>
              )}
              onChange={(_, selectedOption) => {
                if (selectedOption) {
                  if (Array.isArray(selectedOption)) {
                    const mappingSelectionOption: AutocompleteOption[] = selectedOption.map(
                      (option) => {
                        if (typeof option === "string") {
                          const optionObj = options.find((o) => o.value === option);
                          if (optionObj) {
                            return optionObj;
                          }
                        }
                        return option;
                      }
                    );
                    onChange(mappingSelectionOption.map((mo) => mo.value));
                  } else {
                    onChange(selectedOption.value);
                  }
                } else {
                  onChange(selectedOption);
                }

                onChangeAdditional?.();
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  {...restAutocompleteProps}
                  required={required ? true : undefined}
                  error={Boolean(error?.message)}
                  helperText={error?.message}
                />
              )}
            />
          );
        }}
      />
    </>
  );
};

export default AutocompleteHF;
