import { CollectionTagEnum } from "src/shared/constants/common";

export enum CollectionVisibility {
  VISIBLE = "1",
  INVISIBLE = "0",
  ALL = "-1",
}

export enum CollectionSortBy {
  TITLE_ASC = "title-asc",
  TITLE_DESC = "title-desc",
  LATEST = "created_at-desc",
  OLDEST = "created_at-asc",
}

export enum CollectionSearchKey {
  SORT_BY = "sortBy",
  SEARCH = "search",
  VISIBLE = "visible",
  TAG = "tag",
}

export interface CollectionSearch {
  [CollectionSearchKey.SORT_BY]: CollectionSortBy;
  [CollectionSearchKey.SEARCH]: string;
  [CollectionSearchKey.VISIBLE]: CollectionVisibility;
  [CollectionSearchKey.TAG]: CollectionTagEnum;
}
