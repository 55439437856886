import { deleteData } from "src/shared/utils/service";

import { useMutation } from "@tanstack/react-query";
import useShowSnackbar from "src/hooks/useShowSnackbar";

const useDeleteCollection = ({ onSuccess }: { onSuccess: () => void }) => {
  const showSnackbar = useShowSnackbar();

  return useMutation({
    mutationKey: ["collection-delete"],
    mutationFn: (id: string) => deleteData("/collections/with-admin", id),
    onSuccess() {
      showSnackbar("Xóa nhóm sản phẩm thành công!", "success");
      onSuccess();
    },
    onError() {
      showSnackbar("Xóa nhóm sản phẩm thất bại!", "error");
    },
  });
};

export default useDeleteCollection;
