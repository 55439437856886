import { createContext, PropsWithChildren, useState } from "react";

import { AlertProps, SnackbarCloseReason } from "@mui/material";

type SnackbarSeverity = AlertProps["severity"];

interface GlobalContextValue {
  show: ShowStatus;
  message: string;
  severity: SnackbarSeverity;
  showSnackbar: (message: string, severity: SnackbarSeverity) => void;
  hideSnackbar: (event: any, reason: SnackbarCloseReason) => void;
  showLoading: () => void;
  hideLoading: () => void;
}

export const GlobalContext = createContext({} as GlobalContextValue);

interface ShowStatus {
  snackbar: boolean;
  loading: boolean;
}

const GlobalProvider = ({ children }: PropsWithChildren<{}>) => {
  const [show, setShow] = useState<ShowStatus>({
    snackbar: false,
    loading: false,
  });
  const [message, setMessage] = useState<string>("");
  const [severity, setSeverity] = useState<SnackbarSeverity>("success");

  const handleShowSnackbar: GlobalContextValue["showSnackbar"] = (messageValue, severityValue) => {
    setShow((pre) => ({ ...pre, snackbar: false }));
    setMessage(messageValue);
    setSeverity(severityValue);
    setShow((pre) => ({ ...pre, snackbar: true }));
  };

  const handleCloseSnackbar: GlobalContextValue["hideSnackbar"] = (_, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setShow((pre) => ({ ...pre, snackbar: false }));
  };

  const handleHideLoading = () => {
    setShow((pre) => ({ ...pre, loading: false }));
  };

  const handleShowLoading = () => {
    setShow((pre) => ({ ...pre, loading: true }));
  };

  const showSnackbarProviderValue: GlobalContextValue = {
    show,
    message,
    severity,
    showSnackbar: handleShowSnackbar,
    hideSnackbar: handleCloseSnackbar,
    hideLoading: handleHideLoading,
    showLoading: handleShowLoading,
  };

  return (
    <GlobalContext.Provider value={showSnackbarProviderValue}>{children}</GlobalContext.Provider>
  );
};

export default GlobalProvider;
