import { Fragment, ReactNode, useEffect, useState } from "react";

import { useFormContext, useWatch } from "react-hook-form";
import { getPaymentMethod, getTotalProductsPrice, getVoucherPrice } from "src/shared/helpers/order";
import { OrderForm } from "src/shared/types/forms";
import { getFormattedCoupon, numberToCurrency } from "src/shared/utils/common";

import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import DiscountOutlinedIcon from "@mui/icons-material/DiscountOutlined";
import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  InputAdornment,
  Typography,
  TypographyProps,
} from "@mui/material";
import Chip, { ChipProps } from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import NumericFormatInput from "src/components/common/NumericFormatInput";
import SlideDialog from "src/components/dialogs/SlideDialog";
import VoucherSection from "src/components/Orders/VoucherSection";
import { PaperContentSection } from "src/components/PaperContentSection";

import { useShipmentPrices } from "src/hooks/useShipmentPrices";

const renderVoucherChipActionItem = ({
  voucher,
  onDelete,
  totalVoucherPrice,
}: {
  voucher: OrderForm["voucherField"];
  onDelete: ChipProps["onDelete"];
  totalVoucherPrice: string;
}) => {
  return (
    <>
      <Grid item xs={6}>
        <Box display="flex" alignItems="center">
          <Typography variant="body2">Khuyến mãi:</Typography>
          {voucher ? (
            <Tooltip
              title={
                getFormattedCoupon({
                  amountType: voucher.voucher_amount_type,
                  amountValue: voucher.voucher_amount_value,
                }).expectedValue
              }>
              <Chip
                size="small"
                variant="outlined"
                label={voucher.code}
                icon={<DiscountOutlinedIcon fontSize="inherit" />}
                onDelete={onDelete}
                sx={{ marginLeft: 1 }}
              />
            </Tooltip>
          ) : null}
        </Box>
      </Grid>
      <Grid item xs={6} textAlign={"end"}>
        <Typography variant="body2">{totalVoucherPrice}</Typography>
      </Grid>
    </>
  );
};

const ExtraFeeItem = ({ totalExtraFee }: { totalExtraFee: string }) => {
  const [openFeeModal, setOpenFeeModal] = useState<boolean>(false);
  const [extraFeeInput, setExtraFeeInput] = useState("");

  const { setValue } = useFormContext<OrderForm>();

  return (
    <>
      <Grid item xs={6}>
        <Box display="flex" alignItems="center">
          <Typography variant="body2">Phụ phí:</Typography>
          <Tooltip title={"Các loại phí liên quan phát sinh"} placement="top">
            <IconButton
              size="small"
              color="primary"
              onClick={() => setOpenFeeModal(true)}
              sx={{ padding: 0, marginLeft: 1 }}>
              <AddCircleOutlinedIcon fontSize="inherit" />
            </IconButton>
          </Tooltip>
        </Box>
      </Grid>
      <Grid item xs={6} textAlign={"end"}>
        <Typography variant="body2">{totalExtraFee}</Typography>
      </Grid>

      <SlideDialog
        open={openFeeModal}
        dialogTitle="Phụ phí"
        onClose={() => {
          setOpenFeeModal(false);
          setExtraFeeInput("");
        }}
        onApply={() => {
          setValue("extraFeeField", Number(extraFeeInput));
          setOpenFeeModal(false);
        }}>
        <TextField
          size="small"
          placeholder="Nhập phụ phí"
          id="extra-fee-textfield-controlled"
          InputProps={{
            inputComponent: NumericFormatInput as any,
            endAdornment: <InputAdornment position="end">{<span>&#8363;</span>}</InputAdornment>,
          }}
          value={extraFeeInput}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setExtraFeeInput(event.target.value);
          }}
        />
      </SlideDialog>
    </>
  );
};

const renderBillItem = ({
  defaultComponent = { title: "", value: "", isFetching: false },
  customComponent,
}: {
  defaultComponent?: {
    title: ReactNode;
    value: ReactNode;
    props?: TypographyProps;
    tooltipTitle?: string;
    isFetching?: boolean;
  };
  customComponent?: ReactNode;
}) => {
  const { title, value, props, tooltipTitle, isFetching } = defaultComponent;

  if (!customComponent)
    return (
      <>
        <Grid item xs={6}>
          <Tooltip title={tooltipTitle} placement="left">
            <Typography variant="body2" {...props}>
              {title}
            </Typography>
          </Tooltip>
        </Grid>
        <Grid item xs={6} textAlign={"end"}>
          {isFetching ? (
            <CircularProgress color="inherit" size={20} />
          ) : (
            <Typography variant="body2" {...props}>
              {value}
            </Typography>
          )}
        </Grid>
      </>
    );

  return customComponent;
};
const BillItemSection = () => {
  const { setValue } = useFormContext<OrderForm>();

  const [orderItems, voucherField, shipment, extraFeeField, payment_method] = useWatch<
    OrderForm,
    ["orderItems", "voucherField", "shipment", "extraFeeField", "payment_method"]
  >({
    name: ["orderItems", "voucherField", "shipment", "extraFeeField", "payment_method"],
  });

  const productQuantities: number = orderItems.reduce(
    (totalQuantities, orderItem) => (totalQuantities += orderItem.quantity),
    0
  );

  const tempTotalProductPrice: number = getTotalProductsPrice(orderItems);

  const voucherPrice = getVoucherPrice({
    totalProductsPrice: tempTotalProductPrice,
    voucher: voucherField,
  });

  const handleDeleteVoucher = () => setValue("voucherField", null);

  //TODO: handle ShippingPrices
  const { shipmentPrices, isFetching } = useShipmentPrices({
    productPrice: tempTotalProductPrice,
    shipment,
  });

  const totalOrderPrice: number =
    tempTotalProductPrice + extraFeeField - voucherPrice + shipmentPrices;

  const billItemList: Array<Parameters<typeof renderBillItem>[0]> = [
    {
      defaultComponent: {
        title: "Số lượng sản phẩm:",
        value: productQuantities,
      },
    },
    {
      defaultComponent: {
        title: "Tạm tính:",
        value: numberToCurrency(tempTotalProductPrice),
      },
    },
    {
      customComponent: renderVoucherChipActionItem({
        voucher: voucherField,
        onDelete: handleDeleteVoucher,
        totalVoucherPrice: `- ${numberToCurrency(voucherPrice)}`,
      }),
    },
    {
      defaultComponent: {
        title: "Vận chuyển:",
        value: numberToCurrency(shipmentPrices),
        tooltipTitle: "Đơn hàng với giá trị lớn hơn 500.000đ sẽ được free ship",
        isFetching,
      },
    },
    {
      customComponent: <ExtraFeeItem totalExtraFee={numberToCurrency(extraFeeField)} />,
    },
    {
      defaultComponent: {
        title: "Loại hình thanh toán:",
        value: getPaymentMethod(payment_method),
      },
    },
    {
      customComponent: (
        <Grid item xs={12}>
          <Divider />
        </Grid>
      ),
    },
    {
      defaultComponent: {
        title: "Tổng giá trị đơn hàng:",
        value: numberToCurrency(totalOrderPrice),
        props: { variant: "body1", fontWeight: "bold" },
        isFetching,
      },
    },
  ];

  useEffect(() => {
    if (!shipment) return;
    setValue("shipment.shipmentPrices", shipmentPrices);
  }, [shipmentPrices, shipment, setValue]);

  return (
    <PaperContentSection title="Thanh toán" topSection>
      <Grid container spacing={2} p={1}>
        <Grid item xs={12}>
          <VoucherSection />
        </Grid>
        {billItemList.map(({ defaultComponent, customComponent }, index) => {
          return (
            <Fragment key={index}>{renderBillItem({ defaultComponent, customComponent })}</Fragment>
          );
        })}
      </Grid>
    </PaperContentSection>
  );
};

export default BillItemSection;
