import { ChangeEvent } from "react";

import { useFormContext, UseFormReturn, useWatch } from "react-hook-form";
import { shipmentDefaultValues } from "src/shared/helpers/order";
import { OrderForm } from "src/shared/types/forms";

import { Checkbox, Grid, Typography } from "@mui/material";
import { PaperContentSection } from "src/components/PaperContentSection";
import AutocompleteHF from "src/components/rhf/AutocompleteHF";
import TextFieldHF from "src/components/rhf/TextFieldHF";

import useDistricts from "src/hooks/useDistricts";
import useProvinces from "src/hooks/useProvinces";
import useWards from "src/hooks/useWards";

interface ShipmentInfo extends UseFormReturn<OrderForm> {
  shipment: OrderForm["shipment"];
}

const ProvincesAutoComplete = ({ shipment, setValue }: ShipmentInfo) => {
  const { provinces } = useProvinces();

  return (
    <AutocompleteHF<OrderForm>
      formControlProps={{
        name: "shipment.province_id",
        rules: {
          required: "Tỉnh thành không được để trống",
        },
      }}
      options={provinces.map((province) => ({
        label: province.name,
        value: province.id,
      }))}
      getOptionDisabled={(option) => option.value === shipment?.province_id}
      onChangeAdditional={() => {
        setValue("shipment.district_id", null);
        setValue("shipment.ward_id", null);
      }}
      labelOverride="Tỉnh thành"
      placeholder="Chọn tỉnh thành"
      noOptionsText="Không có tỉnh thành"
      required
    />
  );
};

const DistrictsAutocomplete = ({ shipment, setValue }: ShipmentInfo) => {
  const { districts } = useDistricts({ province_id: shipment?.province_id });

  return (
    <AutocompleteHF<OrderForm>
      formControlProps={{
        name: "shipment.district_id",
        rules: {
          required: "Quận, Huyện không được để trống",
        },
      }}
      options={districts.map((district) => ({
        label: district.name,
        value: district.id,
      }))}
      getOptionDisabled={(option) => option.value === shipment?.district_id}
      onChangeAdditional={() => {
        setValue("shipment.ward_id", null);
      }}
      labelOverride="Quận, Huyện"
      placeholder="Chọn Quận, Huyện"
      noOptionsText="Không có Quận, Huyện"
      required
    />
  );
};

const WardsAutocomplete = ({ shipment }: ShipmentInfo) => {
  const { wards } = useWards({ district_id: shipment?.district_id });

  return (
    <AutocompleteHF<OrderForm>
      formControlProps={{
        name: "shipment.ward_id",
        rules: {
          required: "Phường, xã không được để trống",
        },
      }}
      options={wards.map((ward) => ({
        label: ward.name,
        value: ward.id,
      }))}
      getOptionDisabled={(option) => option.value === shipment?.ward_id}
      labelOverride="Phường, xã"
      placeholder="Chọn Phường, xã"
      noOptionsText="Không có Phường, xã"
      required
    />
  );
};

const ShippingMethodSection = () => {
  const orderFormContext = useFormContext<OrderForm>();

  const handleCheckedShipment = (_event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    orderFormContext.setValue("shipment", checked ? shipmentDefaultValues : undefined);
    orderFormContext.clearErrors("shipment");
  };

  const [shipment, paymentMethod] = useWatch<OrderForm, ["shipment", "payment_method"]>({
    name: ["shipment", "payment_method"],
  });
  const isCODMethod = paymentMethod === "CASH_ON_DELIVERY";

  const showShipment = Boolean(shipment) || isCODMethod;

  return (
    <PaperContentSection
      title="Thông tin vận chuyển"
      customElement={
        <Checkbox
          checked={showShipment}
          onChange={handleCheckedShipment}
          disabled={isCODMethod}
          inputProps={{ "aria-label": "controlled" }}
        />
      }>
      {showShipment ? (
        <Grid container spacing={1.5} p={1}>
          <Grid item xs={4} md={12}>
            <ProvincesAutoComplete shipment={shipment} {...orderFormContext} />
          </Grid>
          <Grid item xs={4} md={12}>
            <DistrictsAutocomplete shipment={shipment} {...orderFormContext} />
          </Grid>
          <Grid item xs={4} md={12}>
            <WardsAutocomplete shipment={shipment} {...orderFormContext} />
          </Grid>
          <Grid item xs={12}>
            <TextFieldHF<OrderForm>
              name="shipment.address"
              rules={{
                required: {
                  value: true,
                  message: "Địa chỉ giao hàng không được để trống",
                },
              }}
              labelOverride="Địa chỉ giao hàng"
              placeholder="Địa chỉ giao hàng"
              required
            />
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={2} p={2}>
          <Grid item xs={12}>
            <Typography variant="body2" fontStyle={"italic"} py={2}>
              {isCODMethod ? "Implement" : "N/a"}
            </Typography>
          </Grid>
        </Grid>
      )}
    </PaperContentSection>
  );
};

export default ShippingMethodSection;
