import { Image } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { ProductDetailResponse } from "src/shared/types/services";
import { arrayHasItem } from "src/shared/utils/array";
import { numberToCurrency } from "src/shared/utils/common";

import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import { PaperContentSection } from "./PaperContentSection";

const ProductVariantEditableSection = ({
  productId,
  variants,
}: {
  productId?: ProductDetailResponse["id"];
  variants: ProductDetailResponse["variants"];
}) => {
  const navigate = useNavigate();

  return (
    <PaperContentSection
      title="Biến thể"
      action={{
        icon: <AddCircleOutlinedIcon />,
        onAction: () => navigate(`/products/${productId}/variants/create`),
        title: "Thêm mới biến thể",
      }}>
      <Grid container spacing={1} p={2} alignItems={"center"}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell width={"40%"}>
                  <Box display={"flex"}>
                    <Typography variant="body2" fontWeight={"bold"}>
                      {variants.length} Biến thể
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell width={"10%"} align="center">
                  SKU
                </TableCell>
                <TableCell width={"20%"} align="center">
                  Barcode
                </TableCell>
                <TableCell width={"15%"} align="center">
                  Số lượng
                </TableCell>
                <TableCell width={"20%"} align="right">
                  <Typography variant="body2" fontWeight={"bold"}>
                    Giá
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {variants && arrayHasItem(variants)
                ? variants.map(
                    ({ attributes, id, retail_price, sku, barcode, image, stock_quantity }) => {
                      const variantMapping = attributes.reduce(
                        (pre, cur) => [...pre, cur.value],
                        [] as string[]
                      );
                      return (
                        <TableRow key={id}>
                          <TableCell component="th" scope="row">
                            <Image
                              width={50}
                              wrapperStyle={{ marginRight: 10 }}
                              src={image?.url || "/images/nothing-image.png"}
                            />
                            <Link
                              to={`/products/${productId}/variants/${id}/edit`}
                              style={{
                                color: "#2962ff",
                                textDecoration: "none",
                              }}>
                              {variantMapping.join(" / ")}
                            </Link>
                          </TableCell>
                          <TableCell component="th" scope="row" align="center">
                            {sku}
                          </TableCell>
                          <TableCell component="th" scope="row" align="center">
                            {barcode}
                          </TableCell>
                          <TableCell component="th" scope="row" align="center">
                            {stock_quantity}
                          </TableCell>
                          <TableCell component="th" scope="row" align="right">
                            <Typography variant="body2" fontWeight={"bold"}>
                              {numberToCurrency(retail_price ?? 0)}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      );
                    }
                  )
                : null}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </PaperContentSection>
  );
};

export default ProductVariantEditableSection;
