import { OrderForm } from "src/shared/types/forms";
import { OrderStatus, ShipmentStatus } from "src/shared/types/helpers";
import { AttributeValuesResponse, OrderResponse, VoucherResponse } from "src/shared/types/services";
import { numberToCurrency } from "src/shared/utils/common";
import { ArrayElement } from "src/shared/utils/types";

export const getOrderStatusLabel = (orderStatus: OrderStatus) => {
  switch (orderStatus) {
    case "PENDING":
      return "Chờ xử lý";
    case "SUBMITTED":
      return "Đã xác nhận";
    case "INVOICED":
      return "Đã thanh toán";
    case "CANCELLED":
      return "Đã hủy";
    default:
      return "--";
  }
};

export const getOrderStatusOptions = (orderStatus?: OrderStatus) => {
  const mapOrderOptions = (arr: Array<OrderStatus>) =>
    arr.map<Record<"label" | "value", string>>((item) => ({
      label: getOrderStatusLabel(item),
      value: item,
    }));
  switch (orderStatus) {
    case "PENDING":
      return mapOrderOptions(["SUBMITTED", "INVOICED", "CANCELLED"]);
    case "SUBMITTED":
      return mapOrderOptions(["INVOICED", "CANCELLED"]);
    case "INVOICED":
    case "CANCELLED":
      return [];
    default:
      return mapOrderOptions(["PENDING", "SUBMITTED", "INVOICED", "CANCELLED"]);
  }
};

export const shipmentDefaultValues: OrderForm["shipment"] = {
  province_id: null,
  district_id: null,
  ward_id: null,
  address: "",
  shipmentPrices: 0,
};

export const getShipmentStatusLabel = (shipmentStatus: ShipmentStatus) => {
  switch (shipmentStatus) {
    case "PENDING":
      return "Chờ xử lý";
    case "DELIVERING":
      return "Đang vận chuyển";
    case "DELIVERED":
      return "Đã giao";
    case "RETURNED":
      return "Đã trả hàng";
    case "NONE":
    default:
      return "--";
  }
};

export const getShipmentStatusOptions = (shipmentStatus?: ShipmentStatus) => {
  const mapShipmentOptions = (arr: Array<ShipmentStatus>) =>
    arr.map<Record<"label" | "value", string>>((item) => ({
      label: getShipmentStatusLabel(item),
      value: item,
    }));
  switch (shipmentStatus) {
    case "PENDING":
      return mapShipmentOptions(["DELIVERING"]);
    case "DELIVERING":
      return mapShipmentOptions(["DELIVERED", "RETURNED"]);
    case "DELIVERED":
    case "RETURNED":
    case "NONE":
    default:
      return mapShipmentOptions(["PENDING", "DELIVERING", "DELIVERED", "RETURNED"]);
  }
};

export const isFreeShipping = (finalPrice: number) => {
  const freeShippingPrice = 500000;
  return finalPrice > freeShippingPrice;
};

export const getPaymentMethod = (
  paymentMethod?: ArrayElement<OrderResponse["bill_items"]>["payment_method"]
) => {
  switch (paymentMethod) {
    case "CASH":
      return "Tiền mặt";
    case "MOBILE_WALLET":
      return "Ví điện tử";
    case "CREDIT_AND_DEBIT":
      return "POS - Thẻ ghi nợ hoặc tín dụng";
    case "BANK_TRANSFER":
      return "Chuyển khoản ngân hàng";
    case "CASH_ON_DELIVERY":
      return "COD";
    default:
      return "";
  }
};

export const getOrderTotalPrice = (billItem: OrderResponse["bill_items"]) => {
  const totalPrice: number = (billItem || [])
    .filter(({ type }) => type !== "VOUCHER_BILL")
    .reduce((totalPrice, billItem) => (totalPrice += billItem.final_price), 0);
  const voucherPrice = billItem
    .filter(({ type }) => type === "VOUCHER_BILL")
    .reduce((totalVoucher, billItem) => (totalVoucher += billItem.final_price), 0);

  return numberToCurrency(totalPrice - voucherPrice);
};

export const getAttributeValues = (attributeValues: Array<AttributeValuesResponse>) => {
  const variantValueTitle: string = attributeValues
    .map(({ attribute_value }) => attribute_value)
    .join(" / ");
  return variantValueTitle;
};

export const getVoucherPrice = ({
  totalProductsPrice,
  voucher,
}: {
  totalProductsPrice: number;
  voucher: VoucherResponse | null;
}) => {
  if (!totalProductsPrice || !voucher) return 0;

  if (voucher.voucher_amount_type === "PERCENTAGE") {
    return totalProductsPrice * (voucher.voucher_amount_value / 100);
  } else {
    return voucher.voucher_amount_value;
  }
};

export const getTotalProductsPrice = (orderItems: OrderForm["orderItems"]) => {
  const tempTotalProductPrice: number = orderItems.reduce(
    (totalPrices, orderItem) => (totalPrices += orderItem.quantity * orderItem.product_price),
    0
  );
  return tempTotalProductPrice;
};

export const getOrderPrefix = (sequenceNumber: OrderResponse["sequence_number"]) =>
  `OD-${sequenceNumber}`;
