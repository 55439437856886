import { Key, useState } from "react";

import Tree, { DataNode, TreeProps } from "antd/es/tree";
import { CollectionTagEnum } from "src/shared/constants/common";
import { DUMB_LEFT_MENU, KEY_MENU_TREE_NODE_SEPARATOR } from "src/shared/constants/menu";
import { CollectionResponse } from "src/shared/types/services";
import { SubMenuItem } from "src/shared/types/views";
import {
  removeNodeFromTree,
  transformFlatToNestedData,
  transformMenuItemsToTreeData,
  transformTreeDataToMutateData,
} from "src/shared/utils/sub-menu";

import { Box, Button, Divider } from "@mui/material";
import CollectionSelectionDialog from "src/components/dialogs/CollectionSelectionDialog";
import PageWrapper from "src/components/PageWrapper";
import { PaperCustom } from "src/components/PaperCustom";

import useCollections from "src/hooks/useCollections";
import useGetQueryUtil from "src/hooks/useGetQueryUtil";
import useMenus from "src/hooks/useMenus";
import useUpdateMenu from "src/hooks/useUpdateMenus";

const MenuPage = () => {
  const {
    nameDebounce,
    onReset: onResetCollections,
    onSearch: onSearchCollections,
    ...paging
  } = useGetQueryUtil();
  const { collections, pagination } = useCollections({
    paging,
    search: nameDebounce,
    tag: CollectionTagEnum.ALL,
  });
  const [rootMenu, setRootMenu] = useState<SubMenuItem>();
  const [menuTreeNodes, setMenuTreeNodes] = useState<DataNode[]>([]);
  const [selectedCollections, setSelectedCollections] = useState<string[]>([]);
  const [keyNodeSelected, setKeyNodeSelected] = useState<Key[]>([]);
  const [openSelectedCollectionDialog, setOpenSelectedCollectionDialog] = useState<boolean>(false);
  const [isDirtyTree, setIsDirtyTree] = useState<boolean>(false);

  const { refetch: refetchMenus } = useMenus({
    onSuccess: (data) => {
      setRootMenu(data);
      if (data && data.menu_items.length) {
        setSelectedCollections(data.menu_items.map((item: SubMenuItem) => item.collection_id));
        setMenuTreeNodes(
          transformMenuItemsToTreeData([transformFlatToNestedData(data.menu_items)])
        );
      } else if (data && data.menu_items.length === 0) {
        setMenuTreeNodes(
          transformMenuItemsToTreeData([transformFlatToNestedData([DUMB_LEFT_MENU])])
        );
      }
    },
  });

  const { mutate: updateMenu } = useUpdateMenu({
    onSuccess() {
      setRootMenu({} as SubMenuItem);
      refetchMenus();
      setIsDirtyTree(false);
    },
  });

  const handleAddCollectionToMenu = (selectedCollection: CollectionResponse) => {
    setIsDirtyTree(true);
    const dumbMenuTreeNode: DataNode = {
      key: `${new Date().toISOString()}${KEY_MENU_TREE_NODE_SEPARATOR}${selectedCollection.id}`,
      title: selectedCollection.title,
    };

    setSelectedCollections((pre) => [...pre, selectedCollection.id]);

    setMenuTreeNodes([
      {
        ...menuTreeNodes[0],
        children: [...((menuTreeNodes[0].children ?? []).concat(dumbMenuTreeNode) as DataNode[])],
      },
    ]);
  };

  const handleDeleteMenu = () => {
    setMenuTreeNodes(removeNodeFromTree(menuTreeNodes, keyNodeSelected[0]));
    setKeyNodeSelected([]);
    setIsDirtyTree(true);
  };

  const handleSearchCollectionToMenu = (keyword: string) => {
    onSearchCollections(keyword);
  };

  const handleSave = () => {
    updateMenu({
      menu_items: transformTreeDataToMutateData(menuTreeNodes),
    });
  };
  const onDrop: TreeProps["onDrop"] = (info) => {
    if (info.node.key === "left-menu__left-menu") return;
    setIsDirtyTree(true);

    const dropKey = info.node.key;
    const dragKey = info.dragNode.key;
    const dropPos = info.node.pos;
    const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);

    const loop = (
      data: DataNode[],
      key: React.Key,
      callback: (node: DataNode, i: number, data: DataNode[]) => void
    ) => {
      for (let i = 0; i < data.length; i++) {
        if (data[i].key === key) {
          return callback(data[i], i, data);
        }
        if (data[i].children) {
          loop(data[i].children!, key, callback);
        }
      }
    };

    const data = [...menuTreeNodes];

    // Find dragObject
    let dragObj: DataNode;
    loop(data, dragKey, (item, index, arr) => {
      arr.splice(index, 1);
      dragObj = item;
    });

    if (!info.dropToGap) {
      // Drop on the content
      loop(data, dropKey, (item) => {
        item.children = item.children || [];
        // where to insert. New item was inserted to the start of the array in this example, but can be anywhere
        item.children.unshift(dragObj);
      });
    } else if (
      ((info.node as any).props.children || []).length > 0 && // Has children
      (info.node as any).props.expanded && // Is expanded
      dropPosition === 1 // On the bottom gap
    ) {
      loop(data, dropKey, (item) => {
        item.children = item.children || [];
        // where to insert. New item was inserted to the start of the array in this example, but can be anywhere
        item.children.unshift(dragObj);
        // in previous version, we use item.children.push(dragObj) to insert the
        // item to the tail of the children
      });
    } else {
      let ar: DataNode[] = [];
      let i: number;
      loop(data, dropKey, (_item, index, arr) => {
        ar = arr;
        i = index;
      });
      if (dropPosition === -1) {
        ar.splice(i!, 0, dragObj!);
      } else {
        ar.splice(i! + 1, 0, dragObj!);
      }
    }

    setMenuTreeNodes(data);
  };

  return (
    <PageWrapper
      title="Danh sách danh mục"
      actionArea={
        <Button variant="contained" disabled={!isDirtyTree} onClick={handleSave}>
          Lưu
        </Button>
      }>
      <PaperCustom sx={{ padding: 4, minHeight: 500 }}>
        <Box display={"flex"} flexDirection={"row"} justifyContent={"space-between"} mb={2}>
          <Button
            variant="contained"
            onClick={() => {
              setSelectedCollections(
                transformTreeDataToMutateData(menuTreeNodes).map((data) => data.collection_id)
              );
              setOpenSelectedCollectionDialog(true);
            }}>
            + Thêm
          </Button>
          <Button
            variant="contained"
            color="error"
            disabled={!keyNodeSelected.length}
            onClick={handleDeleteMenu}>
            Xóa
          </Button>
        </Box>
        <Divider />
        {rootMenu ? (
          <Box pt={2}>
            <Tree
              draggable
              defaultExpandAll
              blockNode
              onDrop={onDrop}
              onSelect={(selectedKey) => {
                setKeyNodeSelected(selectedKey);
              }}
              treeData={menuTreeNodes}
            />
          </Box>
        ) : null}
      </PaperCustom>
      <CollectionSelectionDialog
        open={openSelectedCollectionDialog}
        collections={collections}
        onSearch={handleSearchCollectionToMenu}
        pagination={pagination}
        onSelect={handleAddCollectionToMenu}
        selectedCollection={selectedCollections}
        onClose={() => {
          setOpenSelectedCollectionDialog(false);
          onResetCollections();
        }}
      />
    </PageWrapper>
  );
};

export default MenuPage;
