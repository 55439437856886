import VoucherUpsertForm from "src/components/VoucherUpsertForm";

import { useCreateVoucher } from "src/hooks/useCreateVoucher";

export const CreateVoucherPage = () => {
  const { mutate: onCreateVoucher } = useCreateVoucher();

  return <VoucherUpsertForm onSave={onCreateVoucher} />;
};

export default CreateVoucherPage;
