import { NavLink } from "react-router-dom";
import { SideBarMenuItemProps } from "src/shared/types/views";

import { ListItemIcon, ListItemText } from "@mui/material";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";

const SidebarItem = ({
  label,
  icon,
  path,
  handleItemButtonClick,
}: Pick<SideBarMenuItemProps, "label" | "icon" | "path"> & {
  handleItemButtonClick: () => void;
}) => (
  <NavLink
    to={path}
    style={{ textDecoration: "none" }}
    className={({ isActive }) => (isActive ? "nav-menu-link active" : "nav-menu-link")}>
    <ListItem
      sx={{
        color: "#7790b6",
      }}
      disablePadding>
      <ListItemButton onClick={handleItemButtonClick}>
        <ListItemIcon
          sx={{
            color: "#7790b6",
          }}>
          {icon}
        </ListItemIcon>
        <ListItemText primary={label} />
      </ListItemButton>
    </ListItem>
  </NavLink>
);

export default SidebarItem;
