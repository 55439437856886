import { useRef } from "react";

import { useNavigate } from "react-router-dom";
import { RoutePage } from "src/shared/constants/route";
import { PostForm } from "src/shared/types/forms";
import { navigateToWithParams } from "src/shared/utils/common";
import { postData } from "src/shared/utils/service";

import { useMutation } from "@tanstack/react-query";
import useShowOverlayLoading from "src/hooks/useShowOverlayLoading";
import useShowSnackbar from "src/hooks/useShowSnackbar";

const useCreatePost = () => {
  const typeRef = useRef<string | null>(null);

  const navigate = useNavigate();
  const showLoading = useShowOverlayLoading();
  const showSnackbar = useShowSnackbar();

  return useMutation<unknown, unknown, PostForm>({
    mutationKey: ["create-post"],
    mutationFn: (data) => {
      typeRef.current = data.type;
      return postData("/posts/with-admin", data);
    },
    onSuccess: () => {
      showSnackbar("Tạo bài viết mới thành công!", "success");
      showLoading(false);
      navigate(navigateToWithParams(`/${RoutePage.Post}`, { type: typeRef.current ?? "" }));
    },
    onError: () => {
      showSnackbar("Tạo bài viết mới thất bại!", "error");
      showLoading(false);
    },
  });
};

export default useCreatePost;
