import { PostType } from "src/shared/constants/common";
import { PostListResponse, QueryDataResponse } from "src/shared/types/services";
import { getData } from "src/shared/utils/service";

import { useQuery } from "@tanstack/react-query";
import useGetQueryPagination from "src/hooks/useGetQueryPagination";
import useGetQueryUtil from "src/hooks/useGetQueryUtil";

const usePosts = (type: PostType) => {
  const { enabled, page, pageCount, onPageChange, onRowsPerPageChange } = useGetQueryUtil();

  const params: {
    limit: number;
    offset: number;
    type: PostType;
    sort?: string;
    sort_dir?: string;
    is_visible?: boolean;
  } = {
    limit: pageCount,
    offset: page * pageCount,
    type,
    sort: "created_at",
    sort_dir: "desc",
  };

  const { data: dataPosts, ...rest } = useQuery<QueryDataResponse<PostListResponse>>({
    enabled,
    staleTime: Infinity,
    cacheTime: 0,
    queryKey: [
      "posts",
      {
        offset: page * pageCount,
        pageCount,
      },
    ],
    queryFn: () =>
      getData({
        path: "/posts/with-admin",
        config: {
          params,
        },
        requiredAuth: true,
      }),
  });

  const pagination = useGetQueryPagination({
    total: dataPosts?.paging.total,
    page,
    pageCount,
    onRowsPerPageChange,
    onPageChange,
  });

  return {
    posts: dataPosts?.items,
    pagination,
    ...rest,
  };
};

export default usePosts;
