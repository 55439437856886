import { MenusMutateList } from "src/shared/types/views";
import { putData } from "src/shared/utils/service";

import { useMutation } from "@tanstack/react-query";
import useShowSnackbar from "src/hooks/useShowSnackbar";

const useUpdateMenu = ({ onSuccess }: { onSuccess: () => void }) => {
  const showSnackbar = useShowSnackbar();

  return useMutation({
    mutationKey: ["menus-mutate"],
    mutationFn: (data: MenusMutateList) => putData<MenusMutateList>("/menus/with-admin", data),
    onSuccess() {
      onSuccess();
      showSnackbar("Cập nhật danh mục thành công!", "success");
    },
    onError() {
      showSnackbar("Cập nhật danh mục thất bại!", "error");
    },
  });
};

export default useUpdateMenu;
