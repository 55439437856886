import { useNavigate } from "react-router-dom";

import CollectionFormSection from "src/components/CollectionFormSection";

import useCreateCollection from "src/hooks/useCreateCollection";
import useShowOverlayLoading from "src/hooks/useShowOverlayLoading";

const CreateCollectionPage = () => {
  const navigate = useNavigate();
  const showLoading = useShowOverlayLoading();

  const { mutate: onCreate } = useCreateCollection({
    onSuccess: () => {
      showLoading(false);

      navigate("/collections");
    },
  });

  return <CollectionFormSection onSave={onCreate} />;
};

export default CreateCollectionPage;
