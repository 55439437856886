import { ProductStockStatusEnum } from "src/shared/constants/common";
import { ProductSearch, ProductVisibility } from "src/shared/types/product-filter";
import { ProductsServiceResponse } from "src/shared/types/services";
import { getData } from "src/shared/utils/service";

import { useQuery } from "@tanstack/react-query";

export interface UseProductsProps extends Partial<ProductSearch> {
  idCollection?: string;
  onSuccess?: ((data: ProductsServiceResponse) => void) | undefined;
  page: number;
  pageCount: number;
}

const useProducts = ({
  idCollection,
  priceSort,
  search,
  status,
  visible,
  page,
  pageCount,
  onSuccess,
}: UseProductsProps) => {
  const { data: dataProducts, ...rest } = useQuery<ProductsServiceResponse>({
    staleTime: Infinity,
    cacheTime: 0,
    queryKey: [
      "products",
      {
        page,
        pageCount,
        idCollection,
        search,
        visible,
        status,
        priceSort,
      },
    ],
    queryFn: () =>
      getData({
        path: "/products",
        config: {
          params: {
            limit: pageCount,
            offset: page * pageCount,
            collection_ids: idCollection ? `["${idCollection}"]` : [],
            name: search,
            stock_status: status || ProductStockStatusEnum.IN_STOCK,
            ...(visible !== ProductVisibility.ALL ? { is_visible: visible } : undefined),
            ...(priceSort ? { price_sort_dir: priceSort } : undefined),
          },
        },
      }),
    onSuccess,
  });

  return {
    products: dataProducts?.items || [],
    total: dataProducts?.paging.total || 0,
    ...rest,
  };
};

export default useProducts;
