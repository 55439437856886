import { Button } from "@mui/material";

import ButtonLink, { ButtonLinkProps } from "./ButtonLink";

type ActionButtonSectionProps = {
  actionOnClick: () => void;
  backTo: ButtonLinkProps["to"];
  state?: ButtonLinkProps["state"];
};
const ActionButtonSection = ({ actionOnClick, backTo, state }: ActionButtonSectionProps) => (
  <>
    <ButtonLink to={backTo} state={state} color="secondary">
      Hủy
    </ButtonLink>
    <Button variant="contained" sx={{ marginLeft: "10px" }} onClick={actionOnClick}>
      Lưu
    </Button>
  </>
);

export default ActionButtonSection;
