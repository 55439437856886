import { OrderForm } from "src/shared/types/forms";

import { Grid } from "@mui/material";
import NumericFormatInput from "src/components/common/NumericFormatInput";
import { PaperContentSection } from "src/components/PaperContentSection";
import TextFieldHF from "src/components/rhf/TextFieldHF";

const CustomerSection = () => {
  return (
    <PaperContentSection title="Chi tiết khách hàng">
      <Grid container spacing={1.5} p={1}>
        <Grid item xs={6}>
          <TextFieldHF<OrderForm>
            name="customer.last_name"
            rules={{
              required: {
                value: true,
                message: "Họ không được để trống",
              },
            }}
            labelOverride="Họ"
            placeholder="Họ"
            required
          />
        </Grid>
        <Grid item xs={6}>
          <TextFieldHF<OrderForm>
            name="customer.first_name"
            rules={{
              required: {
                value: true,
                message: "Tên không được để trống",
              },
            }}
            labelOverride="Tên"
            placeholder="Tên"
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextFieldHF<OrderForm>
            name="customer.phone_number"
            rules={{
              required: {
                value: true,
                message: "Số điện thoại không được để trống",
              },
              pattern: {
                value: /([\\+84|84|0]+(3|5|7|8|9|1[2|6|8|9]))+([0-9]{8})\b/,
                message: "Số điện thoại không hợp lệ",
              },
            }}
            InputProps={{
              inputComponent: NumericFormatInput as any,
            }}
            inputProps={{
              thousandSeparator: false,
              allowLeadingZeros: true,
              maxLength: 10,
              size: 10,
            }}
            labelOverride="Số điện thoại"
            placeholder="Số điện thoại"
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextFieldHF<OrderForm>
            name="customer.address"
            rules={{
              required: {
                value: true,
                message: "Địa chỉ không được để trống",
              },
            }}
            labelOverride="Địa chỉ"
            placeholder="Địa chỉ"
            required
          />
        </Grid>
      </Grid>
    </PaperContentSection>
  );
};

export default CustomerSection;
