import { getOrderStatusLabel } from "src/shared/helpers/order";
import { OrderStatus } from "src/shared/types/helpers";

import { ChipProps } from "@mui/material";
import ChipBase from "src/components/common/chips/ChipBase";

interface OrderStatusChipProps extends ChipProps {
  orderStatus: OrderStatus;
}

type OrderStatusChipColor<T extends OrderStatus = OrderStatus> = {
  [Key in T]: ChipProps["color"];
};

const orderStatusChipColor: OrderStatusChipColor = {
  PENDING: "warning",
  SUBMITTED: "primary",
  INVOICED: "success",
  CANCELLED: "error",
};

const OrderStatusChip = ({ orderStatus, ...rest }: OrderStatusChipProps) => {
  return (
    <ChipBase
      label={getOrderStatusLabel(orderStatus)}
      color={orderStatusChipColor[orderStatus]}
      size="small"
      variant="outlined"
      {...rest}
    />
  );
};

export default OrderStatusChip;
