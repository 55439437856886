import { Fragment, useEffect, useState } from "react";

import { useFieldArray, UseFieldArrayReturn, useFormContext, useWatch } from "react-hook-form";
import { NumberFormatValues } from "react-number-format";
import { UIMode } from "src/shared/constants/common";
import { getAttributeValues } from "src/shared/helpers/order";
import { OrderForm, OrderItems } from "src/shared/types/forms";
import { AttributeValuesResponse } from "src/shared/types/services";
import { arrayHasItem } from "src/shared/utils/array";
import { ArrayElement } from "src/shared/utils/types";

import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import RemoveOutlinedIcon from "@mui/icons-material/RemoveOutlined";
import { Box, Divider, Stack, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import IconButton from "@mui/material/IconButton";
import Pagination from "@mui/material/Pagination/Pagination";
import ClearableInputFilter from "src/components/ClearableInputFilter";
import NumericFormatInput from "src/components/common/NumericFormatInput";
import { PaperContentSection } from "src/components/PaperContentSection";
import TextFieldHF from "src/components/rhf/TextFieldHF";
import { OrderItemsTable } from "src/components/tables/OrderItemsTable";

import { useVariantsByProductList } from "src/hooks/useVariantsByProductList";

interface VariantSelectionListProps {
  productIndex: number;
  productVariants: ArrayElement<ReturnType<typeof useVariantsByProductList>["productVariantList"]>;
  selectedVariants: OrderItems;
  handleSelectedVariant: (variant: ArrayElement<OrderItems>, checked: boolean) => void;
}
export const VariantSelectionList = ({
  productVariants,
  selectedVariants,
  handleSelectedVariant,
}: VariantSelectionListProps) => {
  const { variants, ...productInfo } = productVariants;

  const renderProductLabel = () => {
    //TODO: handle image rendering performance
    // const image = productInfo.images?.at(0);

    return (
      <Box display={"flex"} alignItems={"center"}>
        {/* <Box className="table-cell--image">
                      {image ? (
                          <img className="box-image" src={image.url} alt={image.alt} />
                      ) : (
                          <img className="box-image" src="/images/nothing-image.png" alt="" />
                      )}
                  </Box> */}
        <Typography variant="body1" fontWeight={"bold"} flexGrow={1} ml={1}>
          {productInfo.name}
        </Typography>
      </Box>
    );
  };

  return (
    <>
      <Box sx={{ padding: 2 }}>
        <FormControlLabel label={renderProductLabel()} disableTypography control={<></>} />

        <Box sx={{ display: "flex", flexDirection: "column", ml: 2 }}>
          {variants.map((variant) => {
            const attributeValues: Array<AttributeValuesResponse> = variant.attributes.map(
              (item) => ({
                attribute_name: item.name,
                attribute_value: item.value,
              })
            );
            return (
              <FormControlLabel
                key={variant.id}
                label={
                  <Stack direction="row" spacing={1}>
                    <Box>{getAttributeValues(attributeValues)}</Box>
                    <Box>{`(Số lượng: ${variant.stock_quantity})`}</Box>
                  </Stack>
                }
                control={
                  <Checkbox
                    id={variant.id}
                    disabled={!variant.stock_quantity}
                    checked={Boolean(
                      selectedVariants.find(
                        (selectedVariant) => selectedVariant.variant_id === variant.id
                      )
                    )}
                    onChange={(_e, checked) => {
                      const orderItem: ArrayElement<OrderItems> = {
                        variant_id: variant.id,
                        product_name: productInfo.name,
                        attribute_values: attributeValues,
                        image: variant.image,
                        quantity: 1,
                        stock_quantity: variant.stock_quantity,
                        product_price: variant.retail_price,
                        discounts: null,
                      };

                      handleSelectedVariant(orderItem, checked);
                    }}
                  />
                }
              />
            );
          })}
        </Box>
      </Box>
    </>
  );
};
interface VariantSelectionDialogProps extends DialogProps {
  orderItemsFieldArray: UseFieldArrayReturn<OrderForm, "orderItems">;
  onClose: () => void;
}
const VariantSelectionDialog = ({
  orderItemsFieldArray,
  open,
  onClose,
}: VariantSelectionDialogProps) => {
  const [searchValue, setSearchValue] = useState("");

  const { clearErrors } = useFormContext<OrderForm>();
  const { fields, replace } = orderItemsFieldArray;
  const [selectedVariants, setSelectedVariants] = useState<OrderItems>([]);

  const handleSelectedVariant = (variant: ArrayElement<OrderItems>, checked: boolean) => {
    if (checked) {
      setSelectedVariants((prevVariants) => {
        const newSelectedVariants = [...prevVariants, variant];
        return [...new Set(newSelectedVariants)];
      });
    } else {
      setSelectedVariants((prevVariants) =>
        prevVariants.filter((v) => v.variant_id !== variant.variant_id)
      );
    }
  };

  const { productVariantList, paging, isFetching } = useVariantsByProductList({
    name: searchValue,
  });

  const handleCloseDialog = () => {
    onClose();
    setSearchValue("");
  };

  const onApply = () => {
    onClose();
    replace(selectedVariants);
    clearErrors("orderItems");
  };

  useEffect(() => {
    setSelectedVariants(fields);
  }, [fields, open]);

  return (
    <Dialog
      open={open}
      onClose={handleCloseDialog}
      maxWidth="lg"
      aria-labelledby="variants-dialog-title"
      aria-describedby="variants-dialog-description">
      <DialogTitle>Danh sách sản phẩm</DialogTitle>
      <DialogContent dividers>
        <Stack spacing={1} sx={{ minWidth: 650 }}>
          <ClearableInputFilter onEnter={setSearchValue} fullWidth sx={{ margin: 0 }} />

          {isFetching ? (
            <CircularProgress />
          ) : (
            <Fragment>
              {productVariantList && arrayHasItem(productVariantList) ? (
                productVariantList.map((variantItem, index) => {
                  const isLastindex = productVariantList.length - 1 === index;
                  return (
                    <Fragment key={variantItem.id}>
                      <VariantSelectionList
                        productIndex={index}
                        productVariants={variantItem}
                        selectedVariants={selectedVariants}
                        handleSelectedVariant={handleSelectedVariant}
                      />
                      {!isLastindex ? <Divider /> : null}
                    </Fragment>
                  );
                })
              ) : (
                <Typography
                  variant="body2"
                  fontStyle={"italic"}
                  py={2}
                  sx={{ alignSelf: "center" }}>
                  Không có sản phẩm nào khả dụng!
                </Typography>
              )}
            </Fragment>
          )}

          <Pagination {...paging} sx={{ alignSelf: "end" }} />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleCloseDialog}>
          Huỷ
        </Button>
        <Button variant="contained" onClick={onApply}>
          Áp dụng
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const QuantityOrder = ({
  orderItemsFieldArray,
  orderItemIndex,
}: {
  orderItemsFieldArray: UseFieldArrayReturn<OrderForm, "orderItems">;
  orderItemIndex: number;
}) => {
  const orderItem = orderItemsFieldArray.fields[orderItemIndex];
  const { setValue } = useFormContext<OrderForm>();

  return (
    <Stack direction="row" alignItems="center">
      <IconButton
        aria-label="minus"
        size="small"
        disabled={orderItem.quantity === 1}
        onClick={() => setValue(`orderItems.${orderItemIndex}.quantity`, orderItem.quantity - 1)}>
        <RemoveOutlinedIcon fontSize="inherit" />
      </IconButton>
      <TextFieldHF<OrderForm>
        name={`orderItems.${orderItemIndex}.quantity` as const}
        InputProps={{
          inputComponent: NumericFormatInput as any,
        }}
        inputProps={{
          maxLength: 3,
          size: 3,
          isAllowed: (values: NumberFormatValues) => {
            const maxQuantity = orderItem.stock_quantity;
            return Number(values.floatValue) <= Number(maxQuantity);
          },
        }}
        sx={{
          [`& .MuiInputBase-input`]: {
            textAlign: "center",
            padding: "4px",
          },
        }}
        onChangeOverride={(e, onChange) => {
          onChange(Math.max(Number(e.target.value), 1));
        }}
      />
      <IconButton
        aria-label="add"
        size="small"
        disabled={orderItem.quantity === orderItem.stock_quantity}
        onClick={() => {
          setValue(`orderItems.${orderItemIndex}.quantity`, orderItem.quantity + 1);
        }}>
        <AddOutlinedIcon fontSize="inherit" />
      </IconButton>
    </Stack>
  );
};

const useControlledFieldArray: typeof useFieldArray = (props) => {
  const fieldArray = useFieldArray(props);
  const watchedFieldArray = useWatch({ ...props });

  if (!watchedFieldArray) return fieldArray;

  const { fields, ...rest } = fieldArray;
  return {
    fields: fields.map((field, index) => ({
      ...field,
      ...watchedFieldArray[index],
    })),
    ...rest,
  };
};

const ProductListSection = () => {
  const [isOpenVariantsSelection, setIsOpenVariantsSelection] = useState<boolean>(false);

  const {
    control,
    formState: { errors },
  } = useFormContext<OrderForm>();

  const orderItemsFieldArray = useControlledFieldArray({
    name: "orderItems",
    control,
    rules: {
      required: {
        value: true,
        message: "Vui lòng chọn ít nhất một sản phẩm để tạo đơn hàng",
      },
    },
  });
  const { fields: orderItems, remove } = orderItemsFieldArray;

  return (
    <>
      <PaperContentSection
        topSection
        title="Sản phẩm"
        action={{
          onAction: () => setIsOpenVariantsSelection(true),
          title: "Thêm sản phẩm",
          icon: <AddCircleOutlinedIcon />,
        }}>
        <OrderItemsTable
          mode={UIMode.EDIT}
          orderItems={orderItems}
          fallbackTitle="Chưa có sản phẩm"
          handleDelete={remove}
          renderQuantity={(index) => (
            <QuantityOrder orderItemsFieldArray={orderItemsFieldArray} orderItemIndex={index} />
          )}
        />
        {errors.orderItems?.root?.message ? (
          <FormControl error={Boolean(errors.orderItems)} variant="outlined" sx={{ marginLeft: 2 }}>
            <FormHelperText id="payment-method-error-text">
              {errors.orderItems.root.message}
            </FormHelperText>
          </FormControl>
        ) : null}
      </PaperContentSection>

      <VariantSelectionDialog
        open={isOpenVariantsSelection}
        onClose={() => setIsOpenVariantsSelection(false)}
        orderItemsFieldArray={orderItemsFieldArray}
      />
    </>
  );
};

export default ProductListSection;
