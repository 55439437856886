import { QueryDataResponse } from "src/shared/types/services";
import { getData } from "src/shared/utils/service";

import { useQuery } from "@tanstack/react-query";

export interface OrderNotesHistoryResponse {
  id: string;
  order_id: string;
  note: string;
  created_at: string;
  updated_at: string;
}

const useOrderNotesHistory = ({ order_id }: Pick<OrderNotesHistoryResponse, "order_id">) => {
  const { data: orderNotesHistory, ...rest } = useQuery<
    unknown,
    unknown,
    QueryDataResponse<OrderNotesHistoryResponse>
  >({
    queryKey: ["orderNoteHistory"],
    queryFn: () =>
      getData({
        path: "/order-note-history/with-admin",
        config: {
          params: {
            limit: -1,
            offset: 0,
            sort: "created_at",
            sort_dir: "desc",
            order_id,
          },
        },
        requiredAuth: true,
      }),
    staleTime: Infinity,
    cacheTime: 0,
  });

  return {
    orderNotesHistory: orderNotesHistory?.items || [],
    ...rest,
  };
};

export default useOrderNotesHistory;
