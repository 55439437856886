import { isFreeShipping } from "src/shared/helpers/order";
import { OrderForm } from "src/shared/types/forms";
import { getData } from "src/shared/utils/service";

import { useQuery } from "@tanstack/react-query";

interface ShipmentPricesResponse {
  shipment_price: number;
}

type UseShipmentPricesProps = {
  productPrice: number;
  shipment: OrderForm["shipment"];
};
export const useShipmentPrices = ({ productPrice, shipment }: UseShipmentPricesProps) => {
  const defaultProductWeight = 1000;
  const isFreeShip = isFreeShipping(productPrice);
  const freeShipPrice = 0;

  const { data, ...rest } = useQuery<ShipmentPricesResponse>({
    queryKey: [
      "shipmentPrices",
      productPrice,
      shipment?.province_id,
      shipment?.district_id,
      shipment?.ward_id,
    ],
    queryFn: () =>
      getData({
        path: "/shipments/shipping-prices",
        config: {
          params: {
            product_price: productPrice,
            delivery_province_id: shipment?.province_id ?? 0,
            delivery_district_id: shipment?.district_id ?? 0,
            delivery_ward_id: shipment?.ward_id ?? 0,
            delivery_address: shipment?.address ?? "",
            product_weight: defaultProductWeight,
          },
        },
      }),
    enabled:
      Boolean(productPrice) &&
      Boolean(shipment?.province_id) &&
      Boolean(shipment?.district_id) &&
      Boolean(shipment?.ward_id) &&
      !isFreeShip,
  });

  return {
    shipmentPrices: isFreeShip ? freeShipPrice : data?.shipment_price || 0,
    ...rest,
  };
};
