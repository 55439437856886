import { useMemo } from "react";

import { Link } from "react-router-dom";
import { Pagination } from "src/shared/types/paging";
import { ProductResponse } from "src/shared/types/services";
import { reduceUrlImage } from "src/shared/utils/common";

import { Box, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import CustomDataGrid from "src/components/common/CustomDataGrid";

interface ProductTableProps {
  products?: ProductResponse[];
  isLoading: boolean;
  pagination?: Pagination;
  isLink?: boolean;
}

const ProductTable = ({ products, isLoading, pagination, isLink = true }: ProductTableProps) => {
  const productRows = useMemo(
    () =>
      (products ?? []).map(({ id, images, name, variants, description, is_visible }) => ({
        id,
        images,
        name,
        variants,
        description,
        is_visible,
      })),
    [products]
  );

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Tên sản phẩm",
      renderCell: (params) => {
        const { images, id, name } = params.row;
        return (
          <Box display={"flex"} alignItems={"center"}>
            <Box className="table-cell--image">
              {images && images[0] ? (
                <img
                  className="box-image"
                  src={reduceUrlImage({ originUrl: images[0].url, quality: 10, width: 100 })}
                  alt={images[0].alt}
                />
              ) : (
                <img className="box-image" src="/images/nothing-image.png" alt="" />
              )}
            </Box>
            <Typography variant="body2" flexGrow={1} ml={1}>
              {isLink ? (
                <Link
                  to={`/products/${id}/edit`}
                  style={{
                    color: "#2962ff",
                    textDecoration: "none",
                  }}>
                  {name}
                </Link>
              ) : (
                name
              )}
            </Typography>
          </Box>
        );
      },
      flex: 2,
    },
    {
      field: "variants",
      headerName: "Số lượng biến thể",
      valueGetter: (variants: ProductResponse["variants"]) => {
        return variants.length || 0;
      },
      flex: 1,
    },
    {
      field: "description",
      headerName: "Miêu tả",
      renderCell: ({ value: description }) => (
        <Typography
          variant="body2"
          dangerouslySetInnerHTML={{
            __html: description,
          }}></Typography>
      ),
      flex: 2,
    },
    {
      field: "is_visible",
      headerName: "Trạng thái",
      valueGetter: (value) => {
        const is_visible = value;
        return is_visible ? "Hiển thị" : "Ẩn";
      },
      flex: 1,
    },
  ];

  return (
    <CustomDataGrid
      rows={productRows}
      columns={columns}
      loading={isLoading}
      customPaginationProps={pagination}
    />
  );
};

export default ProductTable;
