import {
  Box,
  FormControlLabel,
  FormControlLabelProps,
  FormHelperText,
  Radio,
  RadioProps,
} from "@mui/material";

export interface RadioHFProps
  extends Pick<FormControlLabelProps, "label" | "disabled" | "name">,
    Pick<RadioProps, "sx" | "size"> {
  value: string | number;
  description?: string | JSX.Element;
}

const RadioHF = ({ sx, size, value, label, name, disabled, description }: RadioHFProps) => {
  return (
    <Box data-value={`${value}`}>
      <FormControlLabel
        control={<Radio sx={sx} size={size} color="primary" />}
        label={label}
        name={name}
        value={`${value}`}
        disabled={disabled}
      />
      {description ? <FormHelperText variant="standard">{description}</FormHelperText> : null}
    </Box>
  );
};

export default RadioHF;
