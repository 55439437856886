import { styled } from "styled-components";

import { Box, Typography } from "@mui/material";

const ScrollContainer = styled(Box)`
  color: white;
  overflow: hidden;
  background-color: black;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const ScrollText = styled(Typography)`
  /* animation properties */
  -moz-transform: translateX(100%);
  -webkit-transform: translateX(100%);
  transform: translateX(100%);

  -moz-animation: my-animation 40s linear infinite;
  -webkit-animation: my-animation 40s linear infinite;
  animation: my-animation 40s linear infinite;

  /* for Firefox */
  @-moz-keyframes my-animation {
    from {
      -moz-transform: translateX(100%);
    }
    to {
      -moz-transform: translateX(-100%);
    }
  }

  /* for Chrome */
  @-webkit-keyframes my-animation {
    from {
      -webkit-transform: translateX(100%);
    }
    to {
      -webkit-transform: translateX(-100%);
    }
  }

  @keyframes my-animation {
    from {
      -moz-transform: translateX(100%);
      -webkit-transform: translateX(100%);
      transform: translateX(100%);
    }
    to {
      -moz-transform: translateX(-100%);
      -webkit-transform: translateX(-100%);
      transform: translateX(-100%);
    }
  }
`;

interface ScrollBrandingContentProps {
  brandingContent: string;
}
const ScrollBrandingContent = ({ brandingContent }: ScrollBrandingContentProps) => {
  return (
    <ScrollContainer>
      <ScrollText>{brandingContent}</ScrollText>
    </ScrollContainer>
  );
};

export default ScrollBrandingContent;
