import { PropsWithChildren } from "react";

import { Link, LinkProps } from "react-router-dom";

import { Button, ButtonProps } from "@mui/material";

export interface ButtonLinkProps
  extends Partial<Pick<ButtonProps, "startIcon" | "color" | "sx">>,
    Pick<LinkProps, "to" | "state"> {}

const ButtonLink = ({
  to,
  state,
  startIcon,
  children,
  color = "primary",
  sx,
}: PropsWithChildren<ButtonLinkProps>) => {
  return (
    <Link
      to={to}
      state={state}
      style={{
        textDecoration: "none",
      }}>
      <Button startIcon={startIcon} variant="contained" color={color} sx={sx}>
        {children}
      </Button>
    </Link>
  );
};

export default ButtonLink;
