import { PAGE_COUNT } from "src/shared/constants/pagination";
import { Pagination } from "src/shared/types/paging";

import { Divider, TablePagination } from "@mui/material";

const TablePaginationWrapper = ({ pagination }: { pagination: Pagination }) => {
  return (
    <>
      <Divider />
      <TablePagination rowsPerPageOptions={PAGE_COUNT} component="div" {...pagination} />
    </>
  );
};

export default TablePaginationWrapper;
