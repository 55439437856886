import { useNavigate } from "react-router-dom";

import { Box, Button, Stack, Typography } from "@mui/material";

const NotFoundPage = () => {
  const navigate = useNavigate();

  return (
    <Box sx={{ position: "relative", height: "100%", minHeight: "480px" }}>
      <Stack
        spacing={2}
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        sx={{ position: "absolute", inset: 0, margin: "auto" }}>
        <Typography variant="h1">Oops!</Typography>
        <Typography variant="h6">The page you’re looking for doesn’t exist.</Typography>
        <Button variant="contained" onClick={() => navigate("/")}>
          Back To Home
        </Button>
      </Stack>
    </Box>
  );
};

export default NotFoundPage;
