import MenuIcon from "@mui/icons-material/Menu";
import { Box, IconButton } from "@mui/material";
import ProfileAvatar from "src/components/ProfileAvatar";

import { HEADER_WIDTH } from "src/shared/constants/UI";

type MHeaderProps = {
  handleDrawerToggle: () => void;
};

const MHeader = ({ handleDrawerToggle }: MHeaderProps) => (
  <Box display={"flex"} justifyContent="space-between" height={HEADER_WIDTH} component="nav">
    <Box display={"flex"} alignItems={"center"} justifyContent={"center"} pl={2}>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={handleDrawerToggle}
        sx={{ display: { sm: "block", md: "none" } }}>
        <MenuIcon />
      </IconButton>
    </Box>
    <Box></Box>
    <ProfileAvatar />
  </Box>
);

export default MHeader;
