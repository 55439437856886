import { QueryDataResponse, VoucherResponse } from "src/shared/types/services";
import { getData } from "src/shared/utils/service";

import { useQuery } from "@tanstack/react-query";
import useGetQueryPagination from "src/hooks/useGetQueryPagination";
import useGetQueryUtil from "src/hooks/useGetQueryUtil";

const useVouchers = () => {
  const { enabled, page, pageCount, onPageChange, onRowsPerPageChange } = useGetQueryUtil();

  const params: {
    limit: number;
    offset: number;
    sort?: string;
    sort_dir?: string;
  } = {
    limit: pageCount,
    offset: page * pageCount,
  };

  const { data: dataVouchers, ...rest } = useQuery<QueryDataResponse<VoucherResponse>>({
    enabled,
    staleTime: Infinity,
    cacheTime: 0,
    queryKey: [
      "vouchers",
      {
        offset: page * pageCount,
        pageCount,
      },
    ],
    queryFn: () =>
      getData({
        path: "/vouchers/with-admin",
        config: {
          params,
        },
        requiredAuth: true,
      }),
  });

  const pagination = useGetQueryPagination({
    total: dataVouchers?.paging.total,
    page,
    pageCount,
    onPageChange,
    onRowsPerPageChange,
  });

  return {
    vouchers: dataVouchers?.items,
    pagination,
    ...rest,
  };
};

export default useVouchers;
