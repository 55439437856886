import { OrderForm } from "src/shared/types/forms";
import { QueryDataResponse } from "src/shared/types/services";
import { getData } from "src/shared/utils/service";

import { useQuery } from "@tanstack/react-query";

interface WardsResponse {
  id: number;
  name: string;
  type: string;
  district_id: number;
}

interface UseWardsProps extends Partial<Pick<NonNullable<OrderForm["shipment"]>, "district_id">> {}

const useWards = ({ district_id }: UseWardsProps) => {
  const { data: wards, ...rest } = useQuery<QueryDataResponse<WardsResponse>>({
    queryKey: ["wards", district_id],
    queryFn: () =>
      getData({
        path: "/public/wards",
        config: {
          params: {
            limit: -1,
            offset: 0,
            sort_by: "created_at",
            sort_dir: "desc",
            district_id,
          },
        },
      }),
    enabled: Boolean(district_id),
  });

  return {
    wards: wards?.items || [],
    ...rest,
  };
};

export default useWards;
