import { Fragment, PropsWithChildren } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
} from "@mui/material";

export interface FormDialogProps extends DialogProps {
  dialogTitle: string;
  hasDialogActions?: boolean;
  onClose: () => void;
  disabledApply?: boolean;
  onApply?: () => void;
}
const FormDialog = ({
  dialogTitle,
  hasDialogActions = false,
  onClose,
  onApply,
  disabledApply = false,
  children,
  ...props
}: PropsWithChildren<FormDialogProps>) => {
  return (
    <Fragment>
      <Dialog fullWidth maxWidth="md" onClose={onClose} {...props}>
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent id="dialog-form-description">{children}</DialogContent>
        {hasDialogActions ? (
          <DialogActions>
            <Button variant="outlined" onClick={onClose}>
              Huỷ
            </Button>
            <Button variant="contained" onClick={onApply} disabled={disabledApply}>
              Áp dụng
            </Button>
          </DialogActions>
        ) : null}
      </Dialog>
    </Fragment>
  );
};

export default FormDialog;
