import { MouseEvent, useEffect, useState } from "react";

import { ProductStockStatusEnum } from "src/shared/constants/common";
import { ProductPriceSort, ProductVisibility } from "src/shared/types/product-filter";

import FilterListOutlined from "@mui/icons-material/FilterListOutlined";
import {
  Box,
  Button,
  FormControl,
  Grid,
  MenuItem,
  Popover,
  Select,
  Typography,
} from "@mui/material";
import { PaperCustom } from "src/components/PaperCustom";

import { UseSearchURLProductsReturn } from "src/hooks/useSearchURLProducts";

interface ProductFilterPopoverProps extends Omit<UseSearchURLProductsReturn, "paging"> {}

const ProductFilterPopover = ({ filter, searchProducts }: ProductFilterPopoverProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const [status, setStatus] = useState<ProductStockStatusEnum>(filter.status);
  const [priceSort, setPriceSort] = useState<ProductPriceSort>(filter.priceSort);
  const [visibility, setVisibility] = useState<ProductVisibility>(filter.visible);

  const handleApplyFilter = () => {
    searchProducts({
      priceSort,
      status,
      visible: visibility,
    });
    handleClose();
  };

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (anchorEl) {
      setStatus(filter.status);
      setPriceSort(filter.priceSort);
      setVisibility(filter.visible);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [anchorEl]);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <Button
        aria-describedby={id}
        variant="outlined"
        onClick={handleClick}
        startIcon={<FilterListOutlined />}>
        Điều kiện lọc
      </Button>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{
          width: "100%",
        }}>
        <PaperCustom
          sx={{
            width: "650px",
          }}>
          <Typography variant="body1">Hiển thị sản phẩm theo:</Typography>
          <Grid container rowSpacing={1} px={1.5} py={1}>
            <Grid container item spacing={1}>
              <Grid item lg={4}>
                <Box display={"flex"} alignItems={"center"} height={"100%"}>
                  <Typography variant="body2">Tình trạng:</Typography>
                </Box>
              </Grid>
              <Grid item lg={8}>
                <FormControl size="small" fullWidth>
                  <Select
                    value={status}
                    onChange={(event) => setStatus(event.target.value as ProductStockStatusEnum)}>
                    <MenuItem value={ProductStockStatusEnum.IN_STOCK}>Còn hàng</MenuItem>
                    <MenuItem value={ProductStockStatusEnum.OUT_OF_STOCK}>Hết hàng</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container item spacing={1}>
              <Grid item lg={4}>
                <Box display={"flex"} alignItems={"center"} height={"100%"}>
                  <Typography variant="body2">Sắp xếp:</Typography>
                </Box>
              </Grid>
              <Grid item lg={8}>
                <FormControl size="small" fullWidth>
                  <Select
                    value={priceSort}
                    onChange={(event) => setPriceSort(event.target.value as ProductPriceSort)}>
                    <MenuItem value={ProductPriceSort.ASC}>Giá tăng dần</MenuItem>
                    <MenuItem value={ProductPriceSort.DESC}>Giá giảm dần</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container item spacing={1}>
              <Grid item lg={6}>
                <Box display={"flex"} alignItems={"center"} height={"100%"}>
                  <Typography variant="body2">Hiển thị sản phẩm:</Typography>
                </Box>
              </Grid>
              <Grid item lg={6}>
                <FormControl size="small" fullWidth>
                  <Select
                    value={visibility}
                    onChange={(event) => setVisibility(event.target.value as ProductVisibility)}>
                    <MenuItem value={ProductVisibility.INVISIBLE}>Ẩn</MenuItem>
                    <MenuItem value={ProductVisibility.VISIBLE}>Hiện</MenuItem>
                    <MenuItem value={ProductVisibility.ALL}>Tất cả</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Box display={"flex"} justifyContent={"space-between"}>
            <Button variant="contained" color="secondary" onClick={handleClose}>
              Hủy
            </Button>
            <Button variant="contained" onClick={handleApplyFilter}>
              Cập nhật
            </Button>
          </Box>
        </PaperCustom>
      </Popover>
    </>
  );
};

export default ProductFilterPopover;
