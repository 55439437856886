import { VoucherResponse } from "src/shared/types/services";
import { getData } from "src/shared/utils/service";

import { useQuery } from "@tanstack/react-query";

type UseVoucherDetailProps = {
  voucherId: string;
};

const useVoucherDetail = ({ voucherId }: UseVoucherDetailProps) => {
  return useQuery<unknown, unknown, VoucherResponse>({
    queryKey: ["voucher-detail", { voucherId }],
    queryFn: () => getData({ path: "/vouchers/with-admin", slug: voucherId, requiredAuth: true }),
    enabled: Boolean(voucherId),
    staleTime: Infinity,
    cacheTime: 0,
  });
};

export default useVoucherDetail;
