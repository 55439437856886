import { UpdateProductVariantForm } from "src/shared/types/forms";
import { putData } from "src/shared/utils/service";

import { useMutation } from "@tanstack/react-query";
import useShowSnackbar from "src/hooks/useShowSnackbar";

const useUpdateProductVariant = ({
  productId,
  onSuccess,
  variantId,
}: {
  productId?: string;
  variantId?: string;
  onSuccess: (data: any) => void;
}) => {
  const showSnackbar = useShowSnackbar();

  return useMutation({
    mutationKey: ["update-product-variant"],
    mutationFn: (data: UpdateProductVariantForm) =>
      putData<UpdateProductVariantForm>(
        `/products/${productId}/variants/with-admin`,
        data,
        variantId
      ),
    onSuccess(data) {
      showSnackbar("Cập nhật biến thể cho sản phẩm thành công!", "success");
      onSuccess(data.data);
    },
    onError() {
      showSnackbar("Cập nhật biến thể cho sản phẩm thất bại!", "error");
    },
  });
};

export default useUpdateProductVariant;
