import { useNavigate } from "react-router-dom";
import { RoutePage } from "src/shared/constants/route";
import { deleteData } from "src/shared/utils/service";

import { useMutation } from "@tanstack/react-query";
import useShowSnackbar from "src/hooks/useShowSnackbar";

export const useDeletePost = () => {
  const navigate = useNavigate();
  const showSnackbar = useShowSnackbar();

  return useMutation({
    mutationKey: ["post-delete"],
    mutationFn: (postId: string) => deleteData("/posts/with-admin", postId),
    onSuccess: () => {
      showSnackbar("Xóa mã bài viết thành công!", "success");
      navigate(`/${RoutePage.Post}`);
    },
    onError: () => {
      showSnackbar("Xóa mã bài viết thất bại!", "error");
    },
  });
};
