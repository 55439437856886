import { Controller, ControllerProps, FieldPath, FieldValues } from "react-hook-form";

import {
  Checkbox,
  CheckboxProps,
  FormControlLabel,
  FormControlLabelProps,
  Typography,
} from "@mui/material";
import InputLabel from "src/components/InputLabel";

export interface CheckboxHFProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends Pick<ControllerProps<TFieldValues, TName>, "control" | "name" | "rules">,
    Omit<FormControlLabelProps, "control" | "name" | "defaultChecked" | "defaultValue"> {
  checkBoxProps?: Omit<CheckboxProps, "onChange">;
  labelOverride?: string;
}

const CheckBoxHF = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  name,
  control,
  rules,
  checkBoxProps,
  labelOverride,
  onChange: handleOnChange,
  ...rest
}: CheckboxHFProps<TFieldValues, TName>) => {
  return (
    <>
      {labelOverride ? <InputLabel>{labelOverride}</InputLabel> : null}

      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field: { onChange, value, onBlur, ref } }) => (
          <FormControlLabel
            checked={value}
            {...rest}
            control={
              <Checkbox
                color="primary"
                onBlur={onBlur}
                inputRef={ref}
                defaultChecked={value}
                onChange={(event, selected) => {
                  onChange(event);
                  handleOnChange?.(event, selected);
                }}
                {...checkBoxProps}
              />
            }
            label={<Typography variant="body2">{rest.label}</Typography>}
          />
        )}
      />
    </>
  );
};

export default CheckBoxHF;
