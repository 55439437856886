import { ReactNode, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";
import { RoutePage } from "src/shared/constants/route";
import { convertToDate, getFormattedCoupon } from "src/shared/utils/common";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import { Button, Grid, Stack, Typography } from "@mui/material";
import ConfirmedDialog from "src/components/dialogs/ConfirmedDialog";
import PageWrapper from "src/components/PageWrapper";
import { PaperContentSection } from "src/components/PaperContentSection";

import { useDeleteVoucher } from "src/hooks/useDeleteVoucher";
import useVoucherDetail from "src/hooks/useVoucherDetail";

const VoucherDetailPage = () => {
  const [isShowConfirmedDelete, setIsShowConfirmedDelete] = useState<boolean>(false);

  const voucherId = useParams<string>().id ?? "";
  const { data: voucher, isFetching } = useVoucherDetail({ voucherId });

  const { mutate: onDeleteVoucher } = useDeleteVoucher();

  const navigate = useNavigate();

  if (isFetching || !voucher) return <></>;

  const { created_at, updated_at, ...voucherInfo } = voucher;
  const { name, code, voucher_amount_type, voucher_amount_value, start_date, end_date, max_uses } =
    voucherInfo;

  const { typeLabel, expectedValue } = getFormattedCoupon({
    amountType: voucher_amount_type,
    amountValue: voucher_amount_value,
  });

  const generalInfoVoucher: Array<{ label: string; value: string | number | ReactNode }> = [
    {
      label: "Tên voucher",
      value: name,
    },
    {
      label: "Loại voucher",
      value: typeLabel,
    },
    {
      label: "Mã voucher",
      value: code,
    },
    {
      label: "Giá trị voucher",
      value: expectedValue,
    },
    {
      label: "Ngày áp dụng voucher",
      value: start_date ? convertToDate(start_date) : null,
    },
    {
      label: "Ngày kết thúc voucher",
      value: end_date ? convertToDate(end_date) : null,
    },
    {
      label: "Số lần sử dụng voucher",
      value: max_uses,
    },
    {
      label: "Ngày tạo",
      value: convertToDate(created_at),
    },
  ];

  return (
    <PageWrapper
      title="Chi tiết voucher"
      actionArea={
        <>
          <Button
            variant="contained"
            color="error"
            startIcon={<DeleteIcon />}
            onClick={() => setIsShowConfirmedDelete(true)}>
            Xoá voucher
          </Button>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddCircleOutlineIcon />}
            onClick={() =>
              navigate(`/${RoutePage.Voucher}/${voucherId}/edit`, {
                state: voucherInfo,
              })
            }>
            Chỉnh sửa voucher
          </Button>
        </>
      }>
      <Grid container spacing={3}>
        <Grid item md={12}>
          <PaperContentSection title="Thông tin chung" topSection>
            <Grid container item xs={12} p={1} spacing={1}>
              {generalInfoVoucher.map(({ label, value }, index) => (
                <Grid key={index} container item xs={6}>
                  <Grid item>
                    <Stack>
                      <Typography variant="body1" fontWeight={500}>
                        {label}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        {value ?? "--"}
                      </Typography>
                    </Stack>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </PaperContentSection>
        </Grid>
      </Grid>

      <ConfirmedDialog
        title="Xoá voucher"
        message="Bạn có chắc chắn xoá voucher này ? Xoá voucher sẽ loại bỏ áp dụng cho các sản phẩm liên quan."
        show={isShowConfirmedDelete}
        onClose={(isAccept) => {
          setIsShowConfirmedDelete(false);

          if (!isAccept) return;

          onDeleteVoucher(voucherId);
        }}
      />
    </PageWrapper>
  );
};

export default VoucherDetailPage;
