import { Fragment, ReactNode, useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import { getCustomerName } from "src/shared/helpers/customer";
import { getOrderPrefix, getOrderStatusOptions, getPaymentMethod } from "src/shared/helpers/order";
import { OrderStatus } from "src/shared/types/helpers";
import { OrderResponse } from "src/shared/types/services";
import { convertToDate } from "src/shared/utils/common";

import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import List from "@mui/material/List/List";
import ListItem from "@mui/material/ListItem/ListItem";
import { BillItemsSection } from "src/components/BillItemsSection";
import OrderStatusChip from "src/components/common/chips/OrderStatusChip";
import ConfirmedDialog from "src/components/dialogs/ConfirmedDialog";
import FormDialog from "src/components/dialogs/FormDialog";
import PageWrapper from "src/components/PageWrapper";
import { PaperContentSection } from "src/components/PaperContentSection";
import { OrderItemsTable } from "src/components/tables/OrderItemsTable";

import { QueryObserverBaseResult } from "@tanstack/react-query";
import useOrderDetail from "src/hooks/useOrderDetail";
import useOrderNotesHistory from "src/hooks/useOrderNotesHistory";
import usePatchOrderStatus from "src/hooks/usePatchOrderStatus";
import useUpdateOrderNoteHistory from "src/hooks/useUpdateOrderNoteHistory";

const renderCustomerInfo = (customer: OrderResponse["customer"], note: OrderResponse["note"]) => {
  return (
    <PaperContentSection title="Thông tin khách hàng" topSection>
      <List
        disablePadding
        sx={{
          mx: 1,
          width: "100%",
        }}>
        <ListItem disableGutters>
          <Typography variant="body2" fontWeight={500}>
            Họ tên:
          </Typography>
          <Typography variant="body2" color="textSecondary" ml={1}>
            {getCustomerName(customer)}
          </Typography>
        </ListItem>
        <ListItem disableGutters>
          <Typography variant="body2" fontWeight={500}>
            Số điện thoại:
          </Typography>
          <Typography variant="body2" color="textSecondary" ml={1}>
            {customer.phone_number}
          </Typography>
        </ListItem>
        <ListItem disableGutters>
          <Typography variant="body2" fontWeight={500}>
            Ghi chú của khách:
          </Typography>
          <Typography variant="body2" color="textSecondary" ml={1}>
            {note || "Không có ghi chú"}
          </Typography>
        </ListItem>
      </List>
    </PaperContentSection>
  );
};

const renderShipmentInfo = (shipment: OrderResponse["shipment"]) => {
  if (!shipment) return null;

  const { address, ward_name, district_name, province_name } = shipment;
  const shipmentInfoOrder: Array<{ label: string; value: string | number | ReactNode }> = [
    { label: "Địa chỉ:", value: address },
    { label: "Phường, xã:", value: ward_name },
    { label: "Quận, Huyện:", value: district_name },
    { label: "Tỉnh thành:", value: province_name },
  ];
  return (
    <PaperContentSection title="Thông tin giao hàng">
      <List
        disablePadding
        sx={{
          mx: 1,
          width: "100%",
        }}>
        {shipmentInfoOrder.map(({ label, value }, index) => {
          return (
            <Fragment key={index}>
              <ListItem disableGutters>
                <Typography variant="body2" fontWeight={500}>
                  {label}
                </Typography>
                <Typography variant="body2" color="textSecondary" ml={1}>
                  {value}
                </Typography>
              </ListItem>
            </Fragment>
          );
        })}
      </List>
    </PaperContentSection>
  );
};

const OrderNotesHistorySection = ({ orderId }: { orderId: string }) => {
  const [openNoteHistoryModal, setOpenNoteHistoryModal] = useState<boolean>(false);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);

  const {
    orderNotesHistory,
    isFetching,
    refetch: refetchOrderNotesHistory,
  } = useOrderNotesHistory({
    order_id: orderId,
  });

  const defaultOrderNote = orderNotesHistory.at(0)?.note ?? "";
  const [orderNote, setOrderNote] = useState<string>("");
  const validateError =
    defaultOrderNote !== "" ? orderNote.trim() === defaultOrderNote.trim() : false;

  const { mutate: onUpdateOrderNoteHistory } = useUpdateOrderNoteHistory({
    onSuccess: refetchOrderNotesHistory,
  });

  useEffect(() => {
    if (defaultOrderNote) {
      setOrderNote(defaultOrderNote);
    }
  }, [defaultOrderNote]);

  return (
    <>
      <PaperContentSection
        title="Ghi chú của admin"
        customElement={
          <Stack direction="row" spacing={1.5}>
            <Tooltip title="Chỉnh sửa" placement="top">
              <IconButton
                color="primary"
                sx={{ padding: 0 }}
                onClick={() => setIsEditMode((prev) => !prev)}>
                <DriveFileRenameOutlineIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Xem thêm" placement="top">
              <span>
                <IconButton
                  color="primary"
                  sx={{ padding: 0 }}
                  disabled={orderNotesHistory.length <= 1}
                  onClick={() => setOpenNoteHistoryModal(true)}>
                  <VisibilityIcon />
                </IconButton>
              </span>
            </Tooltip>
          </Stack>
        }>
        {isFetching ? null : isEditMode ? (
          <Stack spacing={2}>
            <Button
              variant="contained"
              color="primary"
              disabled={validateError}
              onClick={() => {
                onUpdateOrderNoteHistory({ order_id: orderId, note: orderNote });
                setIsEditMode(false);
              }}>
              Xác nhận
            </Button>
            <TextField
              id="note-controlled"
              label="Ghi chú"
              fullWidth
              multiline
              minRows={3}
              value={orderNote}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setOrderNote(event.target.value);
              }}
              error={validateError}
              helperText={validateError && "Bạn không thể cập nhật ghi chú bị trùng lặp (gần nhất)"}
            />
          </Stack>
        ) : (
          <Typography variant="body2" fontWeight={500}>
            {orderNotesHistory.at(0)?.note || "Chưa có ghi chú!"}
          </Typography>
        )}
      </PaperContentSection>

      <FormDialog
        open={openNoteHistoryModal}
        dialogTitle={"Lịch sử ghi chú"}
        onClose={() => setOpenNoteHistoryModal(false)}>
        <List disablePadding>
          {orderNotesHistory.map(({ created_at, note }, index) => {
            return (
              <Fragment key={index}>
                <ListItem disableGutters sx={{ display: "flex" }}>
                  <Typography variant="body2" fontWeight={500} sx={{ alignSelf: "self-start" }}>
                    {`${new Date(created_at).toLocaleString()}:`}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" ml={1} sx={{ flex: 1 }}>
                    {note}
                  </Typography>
                </ListItem>
              </Fragment>
            );
          })}
        </List>
      </FormDialog>
    </>
  );
};

const OrderStatusSection = ({
  order: { order_id: orderId, status },
  refetchOrderDetail,
}: {
  order: OrderResponse;
  refetchOrderDetail: QueryObserverBaseResult<OrderResponse>["refetch"];
}) => {
  const [orderStatus, setOrderStatus] = useState<string>("");
  const [isShowDialog, setIsShowDialog] = useState<boolean>(false);

  const options = getOrderStatusOptions(status);
  const disabled = !options.length;

  const { mutate: onUpdateOrderStatus } = usePatchOrderStatus({
    orderId,
    onSuccess: refetchOrderDetail,
  });

  return (
    <>
      <PaperContentSection
        title="Cập nhật trạng thái đơn hàng"
        action={{
          title: "Chỉnh sửa",
          icon: <DriveFileRenameOutlineIcon />,
          onAction: () => setIsShowDialog(true),
          disabled,
        }}
        topSection
        sx={{ height: "100%" }}>
        <FormControl sx={{ m: 1 }} size="small">
          <InputLabel htmlFor={"status-order"}>Trạng thái</InputLabel>
          <Select
            value={orderStatus}
            onChange={(event: SelectChangeEvent<string>) => {
              setOrderStatus(event.target.value);
            }}
            label="Trạng thái"
            inputProps={{
              id: "status-order",
              name: "status",
            }}
            disabled={disabled}>
            {options.map(({ label, value }, index) => (
              <MenuItem key={index} value={value}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </PaperContentSection>
      <ConfirmedDialog
        title="Cập nhật trạng thái đơn hàng"
        message="Bạn có chắc chắn cập nhật trạng thái của đơn hàng này?"
        show={isShowDialog}
        onClose={(isAccept) => {
          setIsShowDialog(false);

          if (!isAccept) return;

          onUpdateOrderStatus({ order_status: orderStatus as OrderStatus });
        }}
      />
    </>
  );
};

const OrderDetailPage = () => {
  const orderId: string = useParams().id ?? "";
  const {
    data: order,
    isFetching: isFetchingOrderDetail,
    refetch: refetchOrderDetail,
  } = useOrderDetail({ orderId });

  if (!order || isFetchingOrderDetail) {
    return null;
  }

  const { customer, note, order_items, bill_items, updated_at, ...orderInfo } = order;
  const { sequence_number, status, shipment, created_at } = orderInfo;
  const generalInfoOrder: Array<{ label: string; value: string | number | ReactNode }> = [
    { label: "Mã đơn hàng", value: getOrderPrefix(sequence_number) },
    { label: "Ngày tạo", value: convertToDate(created_at) },
    {
      label: "Trạng thái đơn hàng",
      value: (
        <Box textAlign="center">
          <OrderStatusChip orderStatus={status} />
        </Box>
      ),
    },
    // { label: "Trạng thái giao hàng", value: getShipmentStatusLabel(shipment?.status ?? "NONE") },
  ];

  return (
    <PageWrapper
      title="Chi tiết đơn hàng"
      // actionArea={
      //   <MenuActions
      //     order={order}
      //     orderNotesHistory={orderNotesHistory}
      //     refetchOrderDetail={refetchOrderDetail}
      //     refetchOrderNotesHistory={refetchOrderNotesHistory}
      //   />
      // }
    >
      <Grid container spacing={3}>
        <Grid container item xs={12} spacing={3}>
          <Grid item xs={8}>
            <PaperContentSection title="Thông tin chung" topSection>
              <Grid container item xs={12} p={1} spacing={1}>
                {generalInfoOrder.map(({ label, value }, index) => (
                  <Grid key={index} container item xs={4}>
                    <Grid item>
                      <Stack>
                        <Typography variant="body1" fontWeight={500}>
                          {label}
                        </Typography>
                        {typeof value !== "string" || typeof value !== "number" ? (
                          value
                        ) : (
                          <Typography variant="body2" color="textSecondary">
                            {value ?? "--"}
                          </Typography>
                        )}
                      </Stack>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </PaperContentSection>
          </Grid>
          <Grid item xs={4} height="inherit">
            <OrderStatusSection order={order} refetchOrderDetail={refetchOrderDetail} />
          </Grid>
        </Grid>

        <Grid item md={12} lg={8}>
          <PaperContentSection title="Tất cả sản phẩm trong đơn hàng" topSection>
            <OrderItemsTable orderItems={order_items} />
          </PaperContentSection>

          <BillItemsSection billItems={bill_items} status={status} />
        </Grid>

        <Grid item md={12} lg={4} width={"100%"}>
          {renderCustomerInfo(customer, note)}

          {renderShipmentInfo(shipment)}

          <PaperContentSection title="Loại hình thanh toán">
            <List
              disablePadding
              sx={{
                mx: 1,
                width: "100%",
              }}>
              <ListItem disableGutters>
                <Typography variant="body2" fontWeight={500}>
                  {getPaymentMethod(bill_items.at(0)?.payment_method)}
                </Typography>
              </ListItem>
            </List>
          </PaperContentSection>

          <OrderNotesHistorySection orderId={orderId} />
        </Grid>
      </Grid>
    </PageWrapper>
  );
};

export default OrderDetailPage;
