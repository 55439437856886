import { useNavigate } from "react-router-dom";
import { RoutePage } from "src/shared/constants/route";
import { CreateOrderRequest } from "src/shared/types/services";
import { postData } from "src/shared/utils/service";

import useShowOverlayLoading from "./useShowOverlayLoading";

import { useMutation } from "@tanstack/react-query";
import useShowSnackbar from "src/hooks/useShowSnackbar";

const useCreateOrder = () => {
  const navigate = useNavigate();
  const showLoading = useShowOverlayLoading();
  const showSnackbar = useShowSnackbar();

  return useMutation({
    mutationKey: ["create-order"],
    mutationFn: (data: CreateOrderRequest) =>
      postData<CreateOrderRequest>("/orders/with-admin", data),
    onSuccess() {
      showSnackbar("Tạo đơn hàng mới thành công!", "success");
      showLoading(false);
      navigate(`/${RoutePage.Order}`);
    },
    onError() {
      showSnackbar("Tạo đơn hàng mới thất bại!", "error");
      showLoading(false);
    },
  });
};

export default useCreateOrder;
