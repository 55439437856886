import { RoutePage } from "src/shared/constants/route";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ButtonLink from "src/components/ButtonLink";
import PageWrapper from "src/components/PageWrapper";
import { PaperCustom } from "src/components/PaperCustom";
import VoucherTable from "src/components/tables/VoucherTable";

import useVouchers from "src/hooks/useVouchers";

const VouchersPage = () => {
  const { vouchers, isLoading: isLoadingVouchers, pagination } = useVouchers();

  return (
    <PageWrapper
      title="Danh sách voucher"
      actionArea={
        <ButtonLink to={`/${RoutePage.Voucher}/create`} startIcon={<AddCircleOutlineIcon />}>
          Tạo voucher
        </ButtonLink>
      }>
      <PaperCustom>
        <VoucherTable vouchers={vouchers} isLoading={isLoadingVouchers} pagination={pagination} />
      </PaperCustom>
    </PageWrapper>
  );
};

export default VouchersPage;
