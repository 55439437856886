import { QueryDataResponse } from "src/shared/types/services";
import { getData } from "src/shared/utils/service";

import { useQuery } from "@tanstack/react-query";

interface ProvincesResponse {
  id: number;
  name: string;
  type: string;
}

const useProvinces = () => {
  const { data: provinces, ...rest } = useQuery<QueryDataResponse<ProvincesResponse>>({
    queryKey: ["provinces"],
    queryFn: () =>
      getData({
        path: "/public/provinces",
        config: {
          params: {
            limit: -1,
            offset: 0,
            sort_by: "created_at",
            sort_dir: "desc",
          },
        },
      }),
  });

  return {
    provinces: provinces?.items || [],
    ...rest,
  };
};

export default useProvinces;
