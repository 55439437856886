import { useNavigate } from "react-router-dom";
import { RoutePage } from "src/shared/constants/route";
import { deleteData } from "src/shared/utils/service";

import { useMutation } from "@tanstack/react-query";
import useShowSnackbar from "src/hooks/useShowSnackbar";

export const useDeleteVoucher = () => {
  const navigate = useNavigate();
  const showSnackbar = useShowSnackbar();

  return useMutation({
    mutationKey: ["voucher-delete"],
    mutationFn: (voucherId: string) => deleteData("/vouchers/with-admin", voucherId),
    onSuccess: () => {
      showSnackbar("Xóa mã voucher thành công!", "success");
      navigate(`/${RoutePage.Voucher}`);
    },
    onError: () => {
      showSnackbar("Xóa mã voucher thất bại!", "error");
    },
  });
};
