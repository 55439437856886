import { useEffect, useState } from "react";

import { TablePaginationProps } from "@mui/material";

const useGetQueryPagination = ({
  total,
  page,
  pageCount,
  onRowsPerPageChange,
  onPageChange,
}: {
  total?: number;
  page: number;
  pageCount: number;
  onPageChange: TablePaginationProps["onPageChange"];
  onRowsPerPageChange: TablePaginationProps["onRowsPerPageChange"];
}) => {
  const [totalDisplay, setTotalDisplay] = useState<number>(total || 0);
  const [pageDisplay, setPageDisplay] = useState<number>(page || 0);

  useEffect(() => {
    if ((total || total === 0) && total !== totalDisplay) {
      setTotalDisplay(total);
    }

    if (page !== undefined && page !== null && page !== pageDisplay) {
      setPageDisplay(page);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [total, page]);

  return {
    page: pageDisplay,
    count: totalDisplay,
    rowsPerPage: pageCount,
    onPageChange,
    onRowsPerPageChange,
  };
};

export default useGetQueryPagination;
