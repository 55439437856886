import { forwardRef, Fragment, PropsWithChildren } from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import { DialogProps } from "@mui/material/Dialog/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface SlideDialogProps extends DialogProps {
  dialogTitle: string;
  onClose: () => void;
  onApply: () => void;
}
const SlideDialog = ({
  dialogTitle,
  onClose,
  onApply,
  children,
  ...props
}: PropsWithChildren<SlideDialogProps>) => {
  return (
    <Fragment>
      <Dialog
        keepMounted
        onClose={onClose}
        TransitionComponent={Transition}
        aria-describedby="dialog-slide-description"
        {...props}>
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent id="dialog-slide-description">{children}</DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={onClose}>
            Huỷ
          </Button>
          <Button variant="contained" onClick={onApply}>
            Áp dụng
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default SlideDialog;
