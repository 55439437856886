import { SubMenuItem } from "src/shared/types/views";

export const DUMB_LEFT_MENU: SubMenuItem = {
  id: "left-menu",
  collection_id: "left-menu",
  collection: null,
  name: "Left Menu",
  path: ["left-menu"],
  sub_menus: null,
};

export const KEY_MENU_TREE_NODE_SEPARATOR = "__";
