import { ReactNode } from "react";

import { useController, useFormContext, useWatch } from "react-hook-form";
import { OrderForm } from "src/shared/types/forms";
import { PaymentMethod } from "src/shared/types/helpers";

import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import CreditCardOutlinedIcon from "@mui/icons-material/CreditCardOutlined";
import LocalAtmOutlinedIcon from "@mui/icons-material/LocalAtmOutlined";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import TapAndPlayOutlinedIcon from "@mui/icons-material/TapAndPlayOutlined";
import { Grid } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import List from "@mui/material/List";
import ListItemButton, { ListItemButtonProps } from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { PaperContentSection } from "src/components/PaperContentSection";

const PaymentMethodSection = () => {
  const orderFormContext = useFormContext<OrderForm>();
  const { errors } = orderFormContext.formState;
  const paymentMethod = useWatch<OrderForm, "payment_method">({
    name: "payment_method",
  });

  const {
    field: { onChange: onChangePaymentMethod },
  } = useController({
    name: "payment_method",
    rules: { required: { value: true, message: "Vui lòng chọn loại hình thanh toán" } },
  });

  const itemList: Array<{
    title: string;
    value: PaymentMethod;
    icon: ReactNode;
    props?: ListItemButtonProps;
  }> = [
    {
      title: "Thanh toán bằng tiền mặt",
      value: "CASH",
      icon: <LocalAtmOutlinedIcon />,
    },
    {
      title: "Thanh toán bằng thẻ ghi nợ hoặc thẻ tín dụng",
      value: "CREDIT_AND_DEBIT",
      icon: <CreditCardOutlinedIcon />,
    },
    {
      title: "Thanh toán bằng ví điện tử",
      value: "MOBILE_WALLET",
      icon: <TapAndPlayOutlinedIcon />,
    },
    {
      title: "Thanh toán bằng chuyển khoản ngân hàng",
      value: "BANK_TRANSFER",
      icon: <AccountBalanceOutlinedIcon />,
    },
    {
      title: "Thanh toán khi nhận hàng - COD",
      value: "CASH_ON_DELIVERY",
      icon: <LocalShippingOutlinedIcon />,
    },
  ];

  return (
    <PaperContentSection title="Loại hình thanh toán">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <List component="nav" aria-label="payment method section">
            {itemList.map(({ title, value, icon, props }, index) => {
              return (
                <ListItemButton
                  key={index}
                  selected={paymentMethod === value}
                  onClick={() => onChangePaymentMethod(value)}
                  {...props}>
                  <ListItemIcon>{icon}</ListItemIcon>
                  <ListItemText primary={title} />
                </ListItemButton>
              );
            })}
          </List>
          {errors.payment_method?.message ? (
            <FormControl
              error={Boolean(errors.payment_method)}
              variant="outlined"
              sx={{ marginLeft: 2 }}>
              <FormHelperText id="payment-method-error-text">
                {errors.payment_method.message}
              </FormHelperText>
            </FormControl>
          ) : null}
        </Grid>
      </Grid>
    </PaperContentSection>
  );
};

export default PaymentMethodSection;
