import { useState } from "react";

import { UseFieldArrayReturn } from "react-hook-form";
import { MappingVariantItem, VariantItemsForm } from "src/shared/types/forms";

import {
  Box,
  BoxProps,
  Checkbox,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";

import { PaperContentSection } from "./PaperContentSection";

const VariantSelections = ({
  fields,
  update,
  ref,
}: Pick<UseFieldArrayReturn<VariantItemsForm, "variantItems">, "update" | "fields"> &
  Pick<BoxProps, "ref">) => {
  const [orderPriceEdited, setOrderPriceEdited] = useState<number | null>();

  const handleVariantItemValueChange = <TValue extends string | number | boolean = string>(
    key: keyof Pick<
      MappingVariantItem,
      "barcode" | "sku" | "retail_price" | "isChecked" | "stock_quantity"
    >,
    value: TValue,
    index: number,
    updateAll?: boolean
  ) => {
    const updatedValue = { [key]: value };
    if (updateAll) {
      fields.forEach((field, indexField) =>
        update(indexField, {
          ...field,
          ...updatedValue,
        })
      );
    } else {
      update(index, {
        ...fields[index],
        ...updatedValue,
      });
    }
    setOrderPriceEdited(null);
  };

  const totalCheckedVariant = fields.reduce((pre, cur) => (pre += cur.isChecked ? 1 : 0), 0);
  return (
    <>
      {fields.map((g) => g.attribute_values).length &&
      fields.map((g) => g.attribute_values)[0].length ? (
        <PaperContentSection title="Biến thể" ref={ref}>
          <Grid container spacing={1} p={2} alignItems={"center"}>
            <TableContainer>
              <Table padding="none">
                <TableHead>
                  <TableRow>
                    <TableCell width={"40%"}>
                      <Box display={"flex"}>
                        <Typography variant="body2" fontWeight={"bold"}>
                          <Checkbox
                            checked={totalCheckedVariant === fields.length}
                            onChange={(_, checked) =>
                              handleVariantItemValueChange<boolean>("isChecked", checked, 0, true)
                            }
                          />
                          {fields.length} Biến thể (Đã chọn: {totalCheckedVariant})
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell width={"10%"} align="center">
                      SKU
                    </TableCell>
                    <TableCell width={"20%"} align="center">
                      Barcode
                    </TableCell>
                    <TableCell width={"15%"} align="center">
                      Số lượng
                    </TableCell>
                    <TableCell width={"20%"} align="right">
                      {orderPriceEdited === -1 ? (
                        <TextField
                          type="number"
                          variant="standard"
                          inputProps={{
                            style: { textAlign: "end" },
                          }}
                          defaultValue={0}
                          onBlur={() => setOrderPriceEdited(null)}
                          onKeyDown={(e: any) => {
                            if (e.code === "Enter") {
                              handleVariantItemValueChange(
                                "retail_price",
                                parseInt(e.target.value),
                                0,
                                true
                              );
                            }
                          }}
                        />
                      ) : (
                        <Typography
                          variant="body2"
                          fontWeight={"bold"}
                          pr={1}
                          onClick={() => setOrderPriceEdited(-1)}>
                          Giá
                        </Typography>
                      )}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {fields.map(
                    (
                      {
                        id,
                        isChecked,
                        retail_price: price,
                        barcode,
                        sku,
                        attribute_values,
                        stock_quantity,
                      },
                      indexField
                    ) => {
                      const variantMapping = attribute_values.map((e) => e.value).join(" / ");
                      return (
                        <TableRow
                          key={id}
                          sx={{
                            background: isChecked ? "#eeeff660" : null,
                          }}>
                          <TableCell component="th" scope="row">
                            <Checkbox
                              checked={isChecked}
                              onChange={(_, checked) =>
                                handleVariantItemValueChange<boolean>(
                                  "isChecked",
                                  checked,
                                  indexField
                                )
                              }
                            />
                            {variantMapping}
                          </TableCell>
                          <TableCell component="th" scope="row" align="right" padding="checkbox">
                            <TextField
                              defaultValue={sku}
                              placeholder="SKU"
                              onBlur={(event) =>
                                handleVariantItemValueChange("sku", event.target.value, indexField)
                              }
                            />
                          </TableCell>
                          <TableCell component="th" scope="row" align="right" padding="checkbox">
                            <TextField
                              placeholder="Barcode"
                              defaultValue={barcode}
                              onBlur={(event) =>
                                handleVariantItemValueChange(
                                  "barcode",
                                  event.target.value,
                                  indexField
                                )
                              }
                            />
                          </TableCell>
                          <TableCell component="th" scope="row" align="right" padding="checkbox">
                            <TextField
                              placeholder="Số lượng"
                              type="number"
                              inputProps={{
                                style: { textAlign: "end" },
                              }}
                              defaultValue={stock_quantity}
                              onBlur={(event) =>
                                handleVariantItemValueChange<number>(
                                  "stock_quantity",
                                  parseInt(event.target.value),
                                  indexField
                                )
                              }
                            />
                          </TableCell>
                          <TableCell component="th" scope="row" align="right" padding="checkbox">
                            {orderPriceEdited === indexField ? (
                              <TextField
                                type="number"
                                inputProps={{
                                  style: { textAlign: "end" },
                                }}
                                defaultValue={price}
                                placeholder="Giá"
                                onBlur={() => setOrderPriceEdited(null)}
                                onKeyDown={(e: any) => {
                                  if (e.code === "Enter") {
                                    handleVariantItemValueChange<number>(
                                      "retail_price",
                                      parseInt(e.target.value),
                                      indexField
                                    );
                                  }
                                }}
                              />
                            ) : (
                              <Typography
                                variant="body2"
                                fontWeight={"bold"}
                                pr={1}
                                onClick={() => setOrderPriceEdited(indexField)}>
                                {price.toLocaleString("it-IT", {
                                  style: "currency",
                                  currency: "VND",
                                })}
                              </Typography>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    }
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </PaperContentSection>
      ) : null}
    </>
  );
};

export default VariantSelections;
