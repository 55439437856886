import { useState } from "react";

import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { IconButton, InputAdornment, TextField, TextFieldProps } from "@mui/material";

interface ClearableInputFilterProps {
  initialValue?: string;
  onEnter: (keyword: string) => void;
}
const ClearableInputFilter = ({
  initialValue,
  onEnter,
  ...textFieldProps
}: ClearableInputFilterProps & TextFieldProps) => {
  const [searchValue, setSearchValue] = useState(initialValue ?? "");

  return (
    <TextField
      value={searchValue}
      variant="outlined"
      placeholder="Tìm kiếm"
      size="small"
      sx={{ margin: "0 0 0 10px", flexGrow: 1 }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchOutlinedIcon fontSize="small" />
          </InputAdornment>
        ),
        endAdornment: searchValue && (
          <IconButton
            onClick={() => {
              setSearchValue("");
              onEnter("");
            }}>
            <ClearOutlinedIcon fontSize="small" />
          </IconButton>
        ),
      }}
      onChange={(event) => setSearchValue(event.target.value)}
      onKeyDown={(e) => {
        if (e.code === "Enter") {
          onEnter(searchValue);
        }
      }}
      {...textFieldProps}
    />
  );
};

export default ClearableInputFilter;
