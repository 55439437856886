import { deleteData } from "src/shared/utils/service";

import { useMutation } from "@tanstack/react-query";
import useShowSnackbar from "src/hooks/useShowSnackbar";

const useDeleteProductVariant = ({
  productId,
  onSuccess,
}: {
  productId?: string;
  onSuccess: () => void;
}) => {
  const showSnackbar = useShowSnackbar();

  return useMutation({
    mutationKey: ["product-variant-delete"],
    mutationFn: (variantId: string) =>
      deleteData(`/products/${productId}/variants/with-admin`, variantId),
    onSuccess() {
      showSnackbar("Xóa biến thể sản phảm thành công!", "success");
      onSuccess();
    },
    onError() {
      showSnackbar("Xóa biến thể sản phẩm thất bại!", "error");
    },
  });
};

export default useDeleteProductVariant;
