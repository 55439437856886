import dayjs from "dayjs";
import { useLocation } from "react-router-dom";
import { FormMode } from "src/shared/constants/common";
import { VoucherResponse } from "src/shared/types/services";

import VoucherUpsertForm from "src/components/VoucherUpsertForm";

import useUpdateVoucher from "src/hooks/useUpdateVoucher";

const EditVoucherPage = () => {
  const { state } = useLocation();
  const { id: voucherId, ...voucher } = state as Omit<VoucherResponse, "created_at" | "updated_at">;

  const { mutate: onUpdateVoucher } = useUpdateVoucher({ voucherId });

  return (
    <VoucherUpsertForm
      mode={FormMode.UPDATE}
      defaultValues={{
        ...voucher,
        start_date: voucher.start_date ? dayjs(voucher.start_date) : null,
        end_date: voucher.end_date ? dayjs(voucher.end_date) : null,
      }}
      onSave={onUpdateVoucher}
    />
  );
};

export default EditVoucherPage;
