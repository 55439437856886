import { ChipProps } from "@mui/material";
import ChipBase from "src/components/common/chips/ChipBase";

interface VoucherTypeChipProps extends ChipProps {
  voucherLabel: string;
}
const VoucherTypeChip = ({ voucherLabel, ...rest }: VoucherTypeChipProps) => {
  return <ChipBase label={voucherLabel} size="small" {...rest} />;
};

export default VoucherTypeChip;
