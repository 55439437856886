import { useRef } from "react";

import { useNavigate } from "react-router-dom";
import { RoutePage } from "src/shared/constants/route";
import { PostForm } from "src/shared/types/forms";
import { navigateToWithParams } from "src/shared/utils/common";
import { putData } from "src/shared/utils/service";

import { useMutation } from "@tanstack/react-query";
import useShowOverlayLoading from "src/hooks/useShowOverlayLoading";
import useShowSnackbar from "src/hooks/useShowSnackbar";

const useUpdatePost = ({ postId }: { postId: string }) => {
  const typeRef = useRef<string | null>(null);

  const navigate = useNavigate();
  const showLoading = useShowOverlayLoading();
  const showSnackbar = useShowSnackbar();

  return useMutation<unknown, unknown, PostForm>({
    mutationKey: ["update-post"],
    mutationFn: (data) => {
      typeRef.current = data.type;
      return putData("/posts/with-admin", data, postId);
    },
    onSuccess: () => {
      showSnackbar("Cập nhật bài viết thành công!", "success");
      showLoading(false);
      navigate(navigateToWithParams(`/${RoutePage.Post}`, { type: typeRef.current ?? "" }));
    },
    onError: () => {
      showLoading(false);
      showSnackbar("Cập nhật bài viết thất bại!", "error");
    },
  });
};

export default useUpdatePost;
