import { CollectionResponse } from "src/shared/types/services";
import { getData } from "src/shared/utils/service";

import { useQuery } from "@tanstack/react-query";

const useCollectionDetail = ({
  id,
  onSuccess,
}: {
  id?: string;
  onSuccess?: ((data: CollectionResponse) => void) | undefined;
}) => {
  const { data: collectionDetail } = useQuery<CollectionResponse>({
    queryKey: ["collection-detail", { id }],
    queryFn: () => getData({ path: "/collections", slug: id }),
    enabled: Boolean(id),
    onSuccess,
  });

  return {
    collectionDetail,
  };
};

export default useCollectionDetail;
