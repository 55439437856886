import Joi from "joi";
import {
  CollectionForm,
  DiscountForm,
  HomePageForm,
  LoginForm,
  PostForm,
  ProductForm,
  SignUpForm,
  VoucherForm,
} from "src/shared/types/forms";

export const loginValidateSchema = Joi.object<LoginForm>({
  email: Joi.string()
    .required()
    .email({ minDomainSegments: 2, tlds: { allow: ["com", "net", "vn"] } })
    .messages({
      "any.required": "Email không được để trống",
      "string.empty": "Email không được để trống",
      "string.base": "Email không được để trống",
      "string.email": "Email chưa đúng định dạng (.com, .net, .vn)",
    }),
  password: Joi.string().required().min(6).messages({
    "any.required": "Mật khẩu không được để trống",
    "string.empty": "Mật khẩu không được để trống",
    "string.base": "Mật khẩu không được để trống",
    "string.min": "Mật khẩu nên được đặt từ 6 kí tự trở lên",
  }),
});

export const signUpValidateSchema = Joi.object<SignUpForm>({
  name: Joi.string().required().messages({
    "any.required": "Tên không được để trống",
    "string.empty": "Tên không được để trống",
    "string.base": "Tên không được để trống",
  }),
  email: Joi.string()
    .required()
    .email({ minDomainSegments: 2, tlds: { allow: ["com", "net", "vn"] } })
    .messages({
      "any.required": "Email không được để trống",
      "string.empty": "Email không được để trống",
      "string.base": "Email không được để trống",
      "string.email": "Email chưa đúng định dạng (.com, .net, .vn)",
    }),
  password: Joi.string().required().min(6).messages({
    "any.required": "Mật khẩu không được để trống",
    "string.empty": "Mật khẩu không được để trống",
    "string.base": "Mật khẩu không được để trống",
    "string.min": "Mật khẩu nên được đặt từ 6 kí tự trở lên",
  }),
  confirm_password: Joi.string().required().valid(Joi.ref("password")).messages({
    "any.required": "Mật khẩu nhập lại không được để trống",
    "string.empty": "Mật khẩu nhập lại không được để trống",
    "string.base": "Mật khẩu nhập lại không được để trống",
    "string.min": "Mật khẩu nhập lại nên được đặt từ 6 kí tự trở lên",
    "any.only": "Mật khẩu nhập lại chưa trùng khớp",
  }),
});

export const mutateCollectionValidateSchema = Joi.object<CollectionForm>({
  title: Joi.string().required().messages({
    "any.required": "Tên nhóm sản phẩm không được để trống",
    "string.empty": "Tên nhóm sản phẩm không được để trống",
    "string.base": "Tên nhóm sản phẩm không được để trống",
  }),
  product_ids: Joi.allow(),
  description: Joi.allow(),
  image_url: Joi.allow(),
  is_visible: Joi.allow(),
  tag: Joi.allow(),
});

export const mutateProductValidateSchema = Joi.object<ProductForm>({
  name: Joi.string().required().messages({
    "any.required": "Tên sản phẩm không được để trống",
    "string.empty": "Tên sản phẩm không được để trống",
    "string.base": "Tên sản phẩm không được để trống",
  }),
  category_id: Joi.string().required().messages({
    "any.required": "Loại sản phẩm không được để trống",
    "string.empty": "Loại sản phẩm không được để trống",
    "string.base": "Loại sản phẩm không được để trống",
  }),
  vendor_id: Joi.allow(),
  // vendor_id: Joi.string().required().messages({
  //     "any.required": "Nhà cung cấp sản phẩm không được để trống",
  //     "string.empty": "Nhà cung cấp sản phẩm không được để trống",
  //     "string.base": "Nhà cung cấp sản phẩm không được để trống",
  // }),
  description: Joi.allow(),
  collection_ids: Joi.allow(),
  images: Joi.allow(),
  is_visible: Joi.allow(),
  quote: Joi.allow(),
  type: Joi.allow(),
  variants: Joi.allow(),
});

export const mutateDiscountValidateSchema = Joi.object<DiscountForm>({
  name: Joi.string().required().messages({
    "any.required": "Tên mã khuyến mãi không được để trống",
    "string.empty": "Tên mã khuyến mãi không được để trống",
    "string.base": "Tên mã khuyến mãi không được để trống",
  }),
  discount_amount_type: Joi.string().required().messages({
    "any.required": "Loại mã khuyến mãi không được để trống",
    "string.empty": "Loại mã khuyến mãi không được để trống",
    "string.base": "Loại mã khuyến mãi không được để trống",
  }),
  discount_amount_value: [
    Joi.number(),
    Joi.string().required().regex(/^\d+$/).messages({
      "any.required": "Giá trị không được để trống",
      "string.empty": "Giá trị không được để trống",
      "string.base": "Giá trị không được để trống",
      "string.pattern.base": "Giá trị không hợp lệ",
    }),
  ],
  start_date: Joi.allow(),
  end_date: Joi.allow(),
});

export const mutateVoucherValidateSchema = Joi.object<VoucherForm>({
  name: Joi.string().required().messages({
    "any.required": "Tên voucher không được để trống",
    "string.empty": "Tên voucher không được để trống",
    "string.base": "Tên voucher không được để trống",
  }),
  code: Joi.string().required().messages({
    "any.required": "Mã voucher không được để trống",
    "string.empty": "Mã voucher không được để trống",
    "string.base": "Mã voucher không được để trống",
  }),
  voucher_amount_type: Joi.string().required().messages({
    "any.required": "Loại voucher không được để trống",
    "string.empty": "Loại voucher không được để trống",
    "string.base": "Loại voucher không được để trống",
  }),
  voucher_amount_value: [
    Joi.number(),
    Joi.string().required().regex(/^\d+$/).messages({
      "any.required": "Giá trị voucher không được để trống",
      "string.empty": "Giá trị voucher không được để trống",
      "string.base": "Giá trị voucher không được để trống",
      "string.pattern.base": "Giá trị voucher không hợp lệ",
    }),
  ],
  description: Joi.allow(),
  is_active: Joi.allow(),
  start_date: Joi.allow(),
  end_date: Joi.allow(),
  min_order_final_price: Joi.allow(),
  max_uses: Joi.allow(),
  max_uses_per_user: Joi.allow(),
});

export const mutatePostValidateSchema = Joi.object<PostForm>({
  title: Joi.string().required().messages({
    "any.required": "Tiêu đề không được để trống",
    "string.empty": "Tiêu đề không được để trống",
    "string.base": "Tiêu đề không được để trống",
  }),
  topic_image_url: Joi.alternatives().conditional("topic_image_url", {
    is: "",
    then: Joi.string().required().messages({
      "string.empty": "Ảnh chủ đề không được để trống",
      "string.base": "Ảnh chủ đề không được để trống",
    }),
    otherwise: Joi.allow(),
  }),
  description: Joi.allow(),
  content: Joi.alternatives().conditional("content", {
    is: "",
    then: Joi.string().required().messages({
      "any.required": "Nội dung không được để trống",
      "string.empty": "Nội dung không được để trống",
      "string.base": "Nội dung không được để trống",
    }),
    otherwise: Joi.allow(),
  }),
  author: Joi.allow(),
  seq_number: Joi.allow(),
  is_visible: Joi.allow(),
  type: Joi.allow(),
});

export const mutateHomePageValidateSchema = Joi.object<HomePageForm>({
  scroll_branding_content: Joi.string().required().messages({
    "any.required": "Tiêu đề không được để trống",
    "string.empty": "Tiêu đề không được để trống",
    "string.base": "Tiêu đề không được để trống",
  }),
  image_banner: Joi.alternatives().conditional("image_banner", {
    is: "",
    then: Joi.string().required().messages({
      "string.empty": "Ảnh chủ đề không được để trống",
      "string.base": "Ảnh chủ đề không được để trống",
    }),
    otherwise: Joi.allow(),
  }),
  image_carousels: Joi.allow(),
});
