import { ProductSearchKey } from "src/shared/types/product-filter";

import { Box } from "@mui/material";
import ClearableInputFilter from "src/components/ClearableInputFilter";
import ProductFilterPopover from "src/components/ProductFilterPopover";

import { UseSearchURLProductsReturn } from "src/hooks/useSearchURLProducts";

interface ProductFilterContainerProps extends Omit<UseSearchURLProductsReturn, "paging"> {}

const ProductFilterContainer = ({ filter, searchProducts }: ProductFilterContainerProps) => {
  return (
    <Box display={"flex"} width={"100%"} mb={3} pt={1}>
      <ProductFilterPopover filter={filter} searchProducts={searchProducts} />
      <ClearableInputFilter
        initialValue={filter[ProductSearchKey.SEARCH]}
        onEnter={(keyword: string) => searchProducts({ search: keyword.trim() })}
      />
    </Box>
  );
};

export default ProductFilterContainer;
