import { useMemo } from "react";

import { CategoryResponse, QueryDataResponse } from "src/shared/types/services";
import { getData } from "src/shared/utils/service";

import { useQuery } from "@tanstack/react-query";

const useCategories = () => {
  const { data: dataCategories } = useQuery<QueryDataResponse<CategoryResponse, false>>({
    staleTime: Infinity,
    cacheTime: 0,
    queryKey: ["categories"],
    queryFn: () => getData({ path: "/categories" }),
  });

  return useMemo(() => dataCategories?.items || [], [dataCategories?.items]);
};

export default useCategories;
