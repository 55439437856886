import { useMemo } from "react";

import { CollectionTagEnum } from "src/shared/constants/common";
import { CollectionSearch, CollectionVisibility } from "src/shared/types/collection-filter";
import { CollectionResponse, QueryDataResponse } from "src/shared/types/services";
import { getData } from "src/shared/utils/service";

import { useQuery } from "@tanstack/react-query";
import { UseSearchURLCollectionsReturn } from "src/hooks/useSearchURLCollections";

export interface UseCollectionsProps
  extends Pick<UseSearchURLCollectionsReturn, "paging">,
    Partial<CollectionSearch> {}
const useFetchCollections = ({ paging, search, sortBy, visible, tag }: UseCollectionsProps) => {
  const { page, pageCount, ...restPaging } = paging;

  const [sortKey, sortDirection] = useMemo(
    () => (sortBy ? sortBy?.split("-") : ["created_at", "desc"]),
    [sortBy]
  );

  const { data: dataCollections, ...rest } = useQuery<QueryDataResponse<CollectionResponse>>({
    staleTime: Infinity,
    cacheTime: 0,
    queryKey: ["collections", { page, pageCount, search, sortBy, visible, tag }],
    queryFn: () =>
      getData({
        path: "/collections",
        config: {
          params: {
            limit: pageCount,
            offset: page * pageCount,
            name: search,
            sort: sortKey,
            sort_dir: sortDirection,
            ...(tag !== CollectionTagEnum.ALL ? { tag: tag || "NORMAL" } : undefined),
            ...(visible !== CollectionVisibility.ALL ? { is_visible: visible } : undefined),
          },
        },
      }),
  });

  return {
    collections: dataCollections?.items || [],
    pagination: {
      count: dataCollections?.paging.total ?? 0,
      rowsPerPage: pageCount,
      page,
      ...restPaging,
    },
    ...rest,
  };
};

export default useFetchCollections;
