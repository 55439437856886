import { MouseEvent, useEffect, useState } from "react";

import { getOrderStatusLabel, getOrderStatusOptions } from "src/shared/helpers/order";
import { OrderStatus, ShipmentStatus } from "src/shared/types/helpers";

import FilterListOutlined from "@mui/icons-material/FilterListOutlined";
import {
  Box,
  Button,
  FormControl,
  Grid,
  MenuItem,
  OutlinedInput,
  Popover,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import ClearableInputFilter from "src/components/ClearableInputFilter";
import { PaperCustom } from "src/components/PaperCustom";

import { SearchKeyOrder, UseSearchURLOrdersReturn } from "src/hooks/useSearchURLOrders";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface OrderFilterPopoverProps extends OrderFilterProps {}

const OrderFilterPopover = ({ filter, searchOrders, resetFilter }: OrderFilterPopoverProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const [orderStatusFilter, setOrderStatusFilter] = useState<Array<OrderStatus>>([]);
  const [_shipmentStatusesFilter, _setShipmentStatusesFilter] = useState<Array<ShipmentStatus>>([]); // eslint-disable-line @typescript-eslint/no-unused-vars

  const handleApplyFilter = () => {
    searchOrders({
      order_statuses: JSON.stringify(orderStatusFilter),
      // shipment_statuses: JSON.stringify(shipmentStatusesFilter),
    });
    handleClose();
  };

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (anchorEl) {
      if (!filter.order_statuses) return;

      setOrderStatusFilter(JSON.parse(filter.order_statuses));
      // setShipmentStatusesFilter(JSON.parse(filter.shipment_statuses));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [anchorEl]);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <Button
        aria-describedby={id}
        variant="outlined"
        onClick={handleClick}
        startIcon={<FilterListOutlined />}>
        Điều kiện lọc
      </Button>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{
          width: "100%",
        }}>
        <PaperCustom
          sx={{
            width: "650px",
          }}>
          <Typography variant="body1">Hiển thị theo:</Typography>
          <Grid container rowSpacing={1} px={1.5} py={1}>
            <Grid container item spacing={1}>
              <Grid item lg={2}>
                <Box display={"flex"} alignItems={"center"} height={"100%"}>
                  <Typography variant="body2">Đơn hàng:</Typography>
                </Box>
              </Grid>
              <Grid item lg={10}>
                <FormControl size="small" fullWidth>
                  <Select
                    multiple
                    displayEmpty
                    value={orderStatusFilter}
                    onChange={(event: SelectChangeEvent<typeof orderStatusFilter>) => {
                      const {
                        target: { value },
                      } = event;
                      setOrderStatusFilter(
                        // On autofill we get a stringified value.
                        typeof value === "string" ? (value.split(",") as OrderStatus[]) : value
                      );
                    }}
                    input={<OutlinedInput />}
                    renderValue={(selected) => {
                      if (selected.length === 0) {
                        return "Trạng thái đơn hàng";
                      }

                      return selected.map((item) => getOrderStatusLabel(item)).join(", ");
                    }}
                    MenuProps={MenuProps}
                    inputProps={{ "aria-label": "Without label" }}>
                    <MenuItem disabled value="">
                      Trạng thái đơn hàng
                    </MenuItem>
                    {getOrderStatusOptions().map(({ label, value }, index) => (
                      <MenuItem key={index} value={value}>
                        {label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            {/* <Grid container item spacing={1}>
              <Grid item lg={2}>
                <Box display={"flex"} alignItems={"center"} height={"100%"}>
                  <Typography variant="body2">Vận chuyển:</Typography>
                </Box>
              </Grid>
              <Grid item lg={10}>
                <FormControl size="small" fullWidth>
                  <Select
                    multiple
                    displayEmpty
                    value={shipmentStatusesFilter}
                    onChange={(event: SelectChangeEvent<typeof shipmentStatusesFilter>) => {
                      const {
                        target: { value },
                      } = event;
                      setShipmentStatusesFilter(
                        // On autofill we get a stringified value.
                        typeof value === "string" ? (value.split(",") as ShipmentStatus[]) : value
                      );
                    }}
                    input={<OutlinedInput />}
                    renderValue={(selected) => {
                      if (selected.length === 0) {
                        return "Trạng thái vận chuyển";
                      }

                      return selected.map((item) => getShipmentStatusLabel(item)).join(", ");
                    }}
                    MenuProps={MenuProps}
                    inputProps={{ "aria-label": "Without label" }}>
                    <MenuItem disabled value="">
                      Trạng thái vận chuyển
                    </MenuItem>
                    {getShipmentStatusOptions().map(({ label, value }, index) => (
                      <MenuItem key={index} value={value}>
                        {label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid> */}
          </Grid>
          <Box display={"flex"} justifyContent={"space-between"}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                handleClose();
                resetFilter();
              }}>
              Hủy
            </Button>
            <Button variant="contained" onClick={handleApplyFilter}>
              Cập nhật
            </Button>
          </Box>
        </PaperCustom>
      </Popover>
    </>
  );
};

interface OrderFilterProps extends Omit<UseSearchURLOrdersReturn, "paging"> {}
const OrderFilter = ({ filter, searchOrders, resetFilter }: OrderFilterProps) => {
  return (
    <Box display={"flex"} width={"100%"} mb={3} pt={1}>
      <OrderFilterPopover filter={filter} searchOrders={searchOrders} resetFilter={resetFilter} />
      <ClearableInputFilter
        initialValue={filter[SearchKeyOrder.KEYWORD]}
        onEnter={(keyword: string) => searchOrders({ keyword: keyword })}
        placeholder="Tìm kiếm đơn hàng theo tên khách hàng hoặc SĐT"
      />
    </Box>
  );
};

export default OrderFilter;
