import { PropsWithChildren } from "react";

import { Link, Navigate } from "react-router-dom";

import { Box, Button, Stack, Typography } from "@mui/material";

import useAdminProfile from "src/hooks/useAdminProfile";

interface AuthWrapper {
  title: string;
  actionButton: {
    onClick: () => void;
    label: string;
  };
  link: {
    to: string;
    textDisplay: string;
  };
}
const AuthSection = ({
  title,
  actionButton: { label, onClick },
  link: { textDisplay, to },
  children,
}: PropsWithChildren<AuthWrapper>) => {
  const { isLogin } = useAdminProfile();

  if (isLogin) {
    return <Navigate to="/" replace />;
  }

  return (
    <Stack width={"100vw"} mt={"20vh"} alignItems={"center"}>
      <Stack alignItems={"center"} width={500} component="form">
        <Typography variant="h4">{title}</Typography>
        {children}
        <Button
          fullWidth
          variant="contained"
          sx={{ mt: 1, mb: 2 }}
          onClick={onClick}
          type={"submit"}>
          {label}
        </Button>
        <Box width={"100%"}>
          <Link to={to}>{textDisplay}</Link>
        </Box>
      </Stack>
    </Stack>
  );
};

export default AuthSection;
