import { Dayjs } from "dayjs";
import { Controller, ControllerProps, FieldPath, FieldValues } from "react-hook-form";

import DateRangeOutlinedIcon from "@mui/icons-material/DateRangeOutlined";
import { DatePicker, DatePickerProps } from "@mui/x-date-pickers";
import InputLabel from "src/components/InputLabel";

interface DatePickerHFProps<TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>>
  extends Pick<ControllerProps<TFieldValues, TName>, "control" | "name" | "rules">,
    Omit<DatePickerProps<Dayjs>, "name"> {
  labelOverride?: string;
  required?: boolean;
  helperText?: string;
  shouldShowHelperText?: boolean;
  onChangeAdditional?: () => void;
}

const DatePickerHF = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  control,
  name,
  rules,
  labelOverride,
  required,
  helperText,
  shouldShowHelperText = true,
  onChangeAdditional,
  ...props
}: DatePickerHFProps<TFieldValues, TName>) => {
  //TODO: implement MUI error
  // const [muiError, setMUIError] = useState<DateValidationError>(null);

  return (
    <>
      {labelOverride ? (
        <InputLabel>
          {labelOverride}
          {required ? "*" : null}
        </InputLabel>
      ) : null}
      <Controller
        control={control}
        name={name}
        rules={{
          ...rules,
          validate: {
            ...rules?.validate,
            validateMui: () => true,
          },
        }}
        render={({ field: { name, onChange, value, ref }, fieldState: { error } }) => {
          return (
            <DatePicker<Dayjs>
              {...props}
              value={value}
              inputRef={ref}
              format="DD - MM - YYYY"
              slotProps={{
                textField: {
                  name,
                  size: "small",
                  fullWidth: true,
                  error: Boolean(error),
                  placeholder: "DD - MM - YYYY",
                  helperText: shouldShowHelperText ? helperText ?? error?.message : undefined,
                },
              }}
              slots={{ openPickerIcon: DateRangeOutlinedIcon }}
              onChange={(value) => {
                onChange(value);
                onChangeAdditional?.();
              }}
              // onError={(newError) => setMUIError(newError)}
            />
          );
        }}
      />
    </>
  );
};

export default DatePickerHF;
