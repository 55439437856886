export enum RoutePage {
  Order = "orders",
  Product = "products",
  Collection = "collections",
  Menu = "menus",
  Discount = "discounts",
  Voucher = "vouchers",
  Post = "posts",
  HomePage = "homepage",
  Login = "login",
  SignUp = "sign-up",
}
