import { OrderForm } from "src/shared/types/forms";
import { QueryDataResponse } from "src/shared/types/services";
import { getData } from "src/shared/utils/service";

import { useQuery } from "@tanstack/react-query";

interface DistrictsResponse {
  id: number;
  name: string;
  type: string;
  province_id: number;
}

interface UseDistrictsProps
  extends Partial<Pick<NonNullable<OrderForm["shipment"]>, "province_id">> {}
const useDistricts = ({ province_id }: UseDistrictsProps) => {
  const { data: districts, ...rest } = useQuery<QueryDataResponse<DistrictsResponse>>({
    queryKey: ["districts", province_id],
    queryFn: () =>
      getData({
        path: "/public/districts",
        config: {
          params: {
            limit: -1,
            offset: 0,
            sort_by: "created_at",
            sort_dir: "desc",
            province_id,
          },
        },
      }),
    enabled: Boolean(province_id),
  });

  return {
    districts: districts?.items || [],
    ...rest,
  };
};

export default useDistricts;
